@keyframes ldio-9ohg9q9skfh {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-9ohg9q9skfh div {
  position: absolute;
  width: 52px;
  height: 52px;
  border: 10px solid #219653;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-9ohg9q9skfh div {
  animation: ldio-9ohg9q9skfh 1.2195121951219512s linear infinite;
  top: 50px;
  left: 50px;
}
.loadingio-spinner-rolling-4cqbtlamiq8 {
  width: 17px;
  height: 17px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-9ohg9q9skfh {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.17);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-9ohg9q9skfh div {
  box-sizing: content-box;
}
