/* Home page responsive css */



@media (max-width:1679px) {
    .overview-text::before {
        right: -79px;
    }

    .talk-adviser::before {
        right: -75px;
        width: 549px;
        height: 660px;
    }

    .pet-burial-right img {
        height: auto;
    }
}

@media screen and (max-width:1499px) {

    .overview-text::before {
        right: -65px;
    }

    #header-main {
        padding: 15px;
    }

    nav ul li {
        padding: 0 10px;
    }
}

@media screen and (max-width:1365px) {
    .overview-text::before {
        right: 0;
    }

    .talk-adviser::before {
        right: -20px;
        width: 500px;
        height: 615px;
    }

    .pet-burial-right img {
        height: 345px;
    }
}

@media screen and (max-width:1279px) {
    .talk-adviser::before {
        right: 0;
        width: 400px;
        top: -450px;
    }

    .contect-details ul li {
        display: inline-block;
    }

    .contect-details ul li.cart {
        padding: 0 0 0 15px;
    }

    .contect-details ul li.profile-icon {
        padding: 0 0 0 20px;
    }

    nav ul li {
        padding: 0 6px;
    }

    .contect-details ul li a img {
        width: 40px;
        height: 40px;
    }

    .overview-text h2 {
        max-width: none;
    }

    .overview-img img {
        height: 400px;
        object-fit: cover;
    }

    /* about page */

    .mission-head {
        margin: 0 0 35px;
    }

    .mission-details .p-spacing {
        margin: 0 0 10px;
    }

    .mission-details h2 {
        margin: 0 0 15px;
    }

    .custom-banner-text h1 {
        font-size: 35px;
    }

    .products .burials-plots .product-head {
        flex-wrap: wrap;
    }

    /* .products .burials-plots .product-head .product-type {
        margin: 15px 0 0;
    } */

    /* burials page */

    .cart-checkout .checkout-field {
        padding: 16px 16px;
    }

    .cart-checkout .checkout-total {
        padding: 30px 16px 39px;
    }

    .burials {
        padding: 15px;
    }

    /* plot confirmation popup */

    .plot-confirmation {
        padding: 35px 25px;
    }

    .cart-checkout .checkout-head {
        padding: 16px;
    }

    .Checkout-btn button {
        height: 45px;
    }

    /* order complete popup */

    .order-complete {
        padding: 30px 25px;
    }

    .get-info .get-head {
        gap: 18px;
    }

    .get-info .get-contact ul li {
        padding: 0 35px 10px 0;
    }

    .schedule-row {
        margin: 0 -15px;
    }

    .Schedule-head-col,
    .select-col {
        padding: 0 15px;
    }

    .back-btn {
        margin: 0 0 40px;
    }

    #pet-live-main,
    #Honouring-main {
        margin: 0 0 85px;
    }

    .Honouring h2 {
        margin: 0 0 35px;
    }

    .serene-text {
        padding: 0 0 0 49px;
    }

    .team-profile ul {
        gap: 15px;
    }

    .team-profile ul li img {
        width: 130px;
        height: 145px;
    }

    .team-content p {
        margin: 7px 0 20px;
    }

    .documents-dropdown {
        left: 50%;
        transform: translateX(-50%);
    }

    .footer-links {
        padding: 0 0 0 85px;
    }
}

@media screen and (max-width:1099px) {
    .overlay.menu-active {
        opacity: 1;
        visibility: visible;
        background-color: rgb(0 0 0 / 70%);
        transition: all 0.5s;
    }

    .for-overlay.menu-active {
        position: fixed;
    }

    .faq ul li .faq-content .faq-video {
        padding-right: 35px;
    }

    body.menu-active {
        position: fixed;
    }

    nav ul {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: -200px;
        background-color: #fff;
        z-index: 111;
        width: 200px;
        height: 100vh;
        transition: all 0.5s;
    }

    nav ul.menu-active {
        opacity: 1;
        visibility: visible;
        transition: all 0.5s;
        left: 0;
        overflow-y: auto;
    }

    nav ul span {
        display: block;
        padding: 20px;
        text-align: right;
    }

    .contect-details ul li .bar-btn {
        display: inline-block;
    }

    .contect-details ul li .bar-btn i {
        font-size: 20px;
    }

    .contect-details ul li .for-mobile-hide {
        display: none;
    }

    nav ul li a.active {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: flex;
        /* Safari */
        -webkit-align-items: center;

        /* Microsoft Edge */
        -ms-align-items: center;

        /* Opera */
        -o-align-items: center;

        /* Standard */
        align-items: center;


        width: max-content;
    }

    nav ul li .for-mobile-show {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: flex;
    }

    nav ul li a.active img {
        margin: 0 10px 0 0;
        filter: brightness(0) saturate(100%) invert(26%) sepia(95%) saturate(7499%) hue-rotate(109deg) brightness(101%) contrast(109%);
    }

    nav ul li {
        display: block;
        margin: 25px 0;
        padding: 0 25px;
    }

    nav ul li:nth-child(1) {
        padding-left: 6px;
    }

    nav ul li a.active {
        padding: 0 0 4px;
    }

    .banner-text h1 {
        font-size: 55px;
    }

    .overview-text {
        padding: 0;
    }

    .inspired-content .meadow-img {
        margin: 0 0 10px;
    }

    .inspired-content .p-spacing {
        margin: 0 0 15px;
    }

    .inspired-content h2 {
        margin: 25px 0;
    }

    .inspired-content a {
        margin: 20px 0 0;
    }

    .talk-adviser {
        margin: 0 0 40px;
    }

    .adviser-plans .adviser-content h2 {
        font-size: 22px;
        margin: 25px 0 13px;
    }

    .team-details p {
        font-size: 33px;
        padding: 0 25px;
    }

    /* contact page */

    .custom-banner-img img {
        height: 265px;
        object-fit: cover;
    }

    /* about page */

    .core-content {
        top: 20px;
    }

    .commit-img img {
        height: 425px;
        object-fit: cover;
    }

    .commit-footer p {
        margin: 15px 0 0;
    }

    .mission-details img {
        height: 330px;
    }

    .cart-item-main .cart-item {
        padding: 0 15px 25px;
    }

    .cart-checkout .checkout-total {
        padding: 30px 10px 30px;
    }

    .cart-checkout .checkout-field {
        padding: 10px;
    }

    .cart-checkout .checkout-field label,
    .cart-checkout .checkout-field span {
        font-size: 15px;
    }

    .cart-item-main {
        padding: 18px 0;
    }

    .cart-item-main .cart-itme-head {
        padding: 0 0 0 16px;
    }

    .cart-checkout .checkout-head {
        padding: 16px 10px;
    }

    .purchase-content .purchase-links {
        padding: 0 15px;
    }

    .purchase-content .purchase-links span {
        font-size: 19px;
    }

    .get-info {
        flex-wrap: wrap;
        row-gap: 35px;
    }

    .get-info .get-head {
        width: 100%;
        gap: 45px;
    }

    .get-info .get-contact {
        width: 100%;
    }

    .get-info .get-contact ul li {
        padding: 0 35px 0 0;
    }

    .agents-col {
        width: 50%;
    }

    .celebration-detail-main .celebration-detail {
        height: auto;
    }

    #petburial-paragraph {
        margin: 60px 0 107px;
    }

    .paragraph p {
        font-size: 32px !important;
    }

    .details-img-view p {
        margin: 50px 0;
        font-size: 32px !important;
        line-height: 40px !important;
    }

    .harmony-cta-main {
        flex-wrap: wrap;
        gap: 20px;
    }

    .harmony-cta-main .cta {
        width: 100%;
        padding: 30px 20px;
    }

    .celebration-detail-main {
        gap: 15px;
    }

    .kendal-unique .overview-img img {
        height: 470px;
    }

    .peace-main .overview-img img {
        height: 540px;
    }

    .get-info .get-head .get-left {
        width: auto;
    }

    .get-info .get-contact {
        padding: 0;
        border: none;
    }

    .footer-links {
        padding: 0 0 0 60px;
    }

    .products .burials-plots .product-head .product-type {
        margin: 15px 0 0;
    }

    .products .burials-plots .product-head {
        justify-content: unset;
        column-gap: 30px;
    }

    .products .category {
        margin: 0 0 8px;
    }
}

@media screen and (max-width:1023px) {
    .team-member-img .pd {
        padding: 65px 0 0 35px;
    }

    .banner-text h1 {
        font-size: 45px;
    }

    .banner-text a {
        width: 155px;
        height: 40px;
        line-height: 40px;
    }

    .inspired-content a {
        height: 40px;
        line-height: 40px;
    }

    .talk-adviser .adviser-contact a {
        height: 40px;
    }

    .adviser-plans .adviser-content a {
        height: 40px;
    }

    .team-content a {
        width: 180px;
        height: 40px;
    }

    .overview-img img {
        height: 320px;
    }

    .overview-text h2 {
        margin: 0 0 5px;
    }

    .overview-text a {
        margin: 10px 0 0;
        height: 40px;
        line-height: 40px;
    }

    .overview-text p {
        font-size: 15px;
    }

    .overview-text .p-spacing {
        margin: 0 0 5px;
    }

    .overview-text::before,
    .talk-adviser::before,
    #why-inspired-main::before {
        display: none;
    }

    .inspired-content p {
        font-size: 15px;
    }

    .talk-adviser {
        padding: 24px 25px 33px;
    }

    .team-profile ul {
        gap: 15px;
    }

    /* about page */

    .core-content {
        top: 15px;
    }

    .product-plant {
        margin: 0 0 15px;
    }

    #product-main {
        margin: 70px 0;
    }

    /* product page  */

    /* blog page */

    #Blog-details {
        margin: 27px 0 70px;
    }

    /* cart product page */

    .cart-checkout .checkout-field label,
    .cart-checkout .checkout-field span {
        font-size: 14px;
    }

    .cart-checkout .checkout-total label,
    .cart-checkout .checkout-total span {
        font-size: 17px;
    }

    /* profile page */

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main .order-details p {
        margin: 7px 0 8px;
    }

    .account-main .profile-details-main .profile-btn button {
        height: 45px;
    }

}

@media screen and (max-width:991px) {
    .credit-img-col  {
        width: 100%;
    }
    .credit-section {
        margin: 55px 0 0;
        padding: 50px 0;
    }
    .credit-img img  {
        height: 380px;
    }
    .credit-content-col {
        width: 100%;
    }
    .credit-content  {
        margin: 20px 0;
        height: 350px;
    }
    .kendal-link {
        margin: 0 0 30px;
    }
    .Burials-head {
        margin: 0 0 20px;
    }
    .memorial-garden-btn {
        margin: 10px 0 0;
    }
    .new-type-family .family-types .family-types-box .types-details.first {
        padding: 20px;
    }
    .family-burials-plot  .family-detail h2 {
        margin: 15px 0;
    }
    .green-eco-burials .family-detail {
        padding-top: 0;
    }
    .family-types-col a {
        width: 175px;
    }
    .green-eco-burials .family-detail h2 {
        margin: 0 0 15px !important;
    }
    #Burials-main {
        margin: 50px 0  55px;
    }
    .burials-new-main .burials-full-col .burials {
        height: auto;
        margin: 0 0 0;
    }
    .why-green-cols {
        width: 100%;
    }
    
    .home-about-naturals {
        margin: 55px 0  !important;
    } 
    .home-about-naturals .family-detail h2 {
        margin: 15px 0;
    }
    .kendal-perfect-main .family-burial-img {
        margin: 20px 0 0 ;
    }
    .kendal-perfect-main  .family-burial-img img {
        height: 400px;
    }
    .kendal-perfect-main {
        margin: 55px 0  0  !important;
    }
    .cta_new {
        margin: 55px 0 0;
    }
    .home-place-main {
        margin: 55px 0 0 !important;
    }
    .home-place-main .family-detail {
        margin: 0 0 25px;
    }
    .Cta_new_main .talk-adviser {
        padding: 24px 15px;
        border-radius: initial;
    }
    .Cta_new_main .talk-adviser .msg-img {
        width: 7%;
    }
    .Cta_new_main .talk-adviser .adviser-contact a {
        width: 240px;
    }
    .Cta_new_main .talk-adviser .adviser-contact {
        margin: 15px 0 0;
    }
    .Cta_new_main .talk-adviser .msg-img img {
        width: 28px;
    }
    #banner-img-main .banner-overlay {
        display: unset;
    }

    .home-banner-main .banner-img {
        height: 310px;
    }
    .home-banner-main .banner-text-main {
        top: 72%;
    } 
    .family-contact-main .contact-social ul li .social-left p {
        max-width: 100%  !important;
      } 
    .family-img-col {
        width: 100%;
    }
    .family-contact-img {
        margin: 0 0 25px;
    }
    .contact-form form {
        height: auto;
    }
    .family-contact-main {
        margin: 0 0 50px !important;
    }
    .home-types-main .family-types .family-types-box  .home-second-types {
        padding: 20px;
    } 
    .home-types-main .family-types .family-types-box  .home-third-types {
        padding: 20px;
    }
    .home-types-main .family-types .family-types-box  .home-five-types {
        padding: 20px;
    }
    .home-plans-main .burials-process-col {
        width: 100%;
    }
    .home-plans-main .burials-process {
        height: auto;
        margin: 0 0 15px;
    }
    .phome-payment-plan {
        padding: 50px 0;
    }
    .phome-payment-plan .family-detail h2 {
        margin: 0 0 15px;
    }
    .what-we-do .family-detail p {
        margin: 0;
    }
    .phome-payment-plan  .family-detail p {
        margin: 0 0 20px;
    }
    .home-place-main {
        padding: 50px 0;
    }
    .home-plans-main::before {
        display: none;
    }
    .home-plans-main::after {
        display: none;
    }
    #about-family-main {
        margin: 55px 0 0;
    }
    .home-place-main .family-detail h2 {
        margin: 0 0 13px;
    }
    .tribute-head h2 {
        font-size: 34px;
    }
    .about-memorial .family-detail h2 {
        margin: 15px 0 !important;
    }
    .memorial-option-main {
        margin: 40px 0 0 !important;
    }
    #memorial-tribute-service {
        padding: 50px 0;
    }
    .memorial-cta-new {
        margin: 55px 0 60px !important;
    }
    .family-types .family-types-box {
        flex-wrap: wrap;
    }
    .family-types .family-types-box .types-details {
        width: 100%;
        order: 2;
        padding: 20px;
    }
    .family-types .family-types-box.third .types-details {
        padding: 20px;
    }
    .family-why-head h2 {
        font-size: 34px;
    }
    .family-why-head {
        text-align: left;
        margin: 0 0 30px;
    }
    .family-full {
        padding: 20px;
    }
    .family-half {
        padding: 15px;
        height: 320px;
        margin: 0 0 30px;
    }
    .new-family-half {
        height: 215px;
    }
    #family-why-choose-main {
        margin: 45px 0 40px;
    }

    .family-types .family-types-box .family-types-img img {
        width: 100%;
        height: 350px;
        object-position: bottom;
    }
    .family-types .family-types-box .family-types-img {
        width: 100%;
        order: 1;
    }
    .family-burial-img-col {
        width: 100%;
    }
    .family-detail-col {
        width: 100%;
    }
    .family-detail h2 {
        font-size: 34px;
        margin: 15px 0;
    }
    .family-burials-main .burials-desk {
        display: unset;
    }
    .family-burials-main .burials {
        height:auto;
    } 
    .family-burials-main {
        margin: 40px 0 55px !important;
    }
    .family-burials-main .Burials-head {
        margin: 0 0 30px;
    }
    .type-family-head {
        text-align: left;
    }
    .type-family-head h2 {
        font-size: 34px;
        margin: 0 0 15px;
    }
    #types-family-main {
        padding: 50px 0;
    }
    .burials-new-main .burials-desk {
        display: unset;
    }
    .burials-new-main .burials-half {
        height: auto;
        margin: 0 0 15px;
    }
    .burials-new-main .burials {
        height: auto;
        margin: 0 0 15px;
    }
    .burials-new-main {
        margin: 60px 0 46px;
    }
    .why-green-head h2 {
        font-size: 34px;
        margin: 0 0 15px;
    }
    .why-green-head {
        text-align: left;
        margin: 0 0 30px;
    }
    .why-green {
        height: auto;
        margin: 0 0 15px;
        padding: 20px;
    }
    .faq-new-main {
        margin: 0 0 50px !important;
    }
    .green-cta {
        margin: 0 0  70px !important;
    }
    .new-cta .new-cta-details {
        left: 15px;
    }
    #new-cta-main {
        margin: 0 0 70px;
    }
    .new-cta .new-cta-details p {
        font-weight: normal;
    }
    .burials-head {
        text-align: left;
        margin: 0 0 30px;
    }
    .burials-process img {
        height: 220px;
    }
    .burials-head h2 {
        font-size: 34px;
    }
    .burials-process .burials-details  h3 {
        font-size: 20px;
    }
    .burials-process .burials-details a {
        width: 155px;
        height: 45px;
        font-weight: 500;
        font-size: 14px;
    }
    .bannerContainer{
        height: 200px;
      }
    .footer-help {
        padding: 0 0 0 !important;
    }
    .footer {
        overflow: hidden;
    }
    .terms-details .back-btn {
        margin: 0 0 30px;
    }
    .terms-details .our-term h1 {
        font-size: 30px;
    }
    .terms-update {
        margin: 0 0 35px;
    }
    .terms-update h3 {
        margin:  0 0 5px;
        font-size: 20px;
    }
    #footer-main h2 {
        margin: 0 0 10px;
    }
    form .event_bullets ul li {
        font-size: 16px;
    }
    .new-location-form .form-main .fee-main {
        row-gap: 30px;
        flex-wrap: wrap;
    }
    .new-location-form .form-main form .input-date .input-field .input-date-picker {
        position: relative;
    }
    .date-picker-death, .date-picker-birth {
        position: absolute !important;
        left: 0 !important;
    }
    .new-location-form .form-main .fee-main .detail .head.pl {
        padding-left: 0;
    }
    .new-interment-form-design::before {
        width: 100%;
    }
    .new-location-form .form-main .application {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .new-location-form .form-main .application .input-group {
        width: 70%;
    }
    .new-location-form .form-main .application .input-group label {
        width: 54%;
        text-align: right;
    }
    .new-location-form h1 {
        font-size: 30px;
        margin: 0 0 25px;
    }
    .logo-for-pdf {
        padding: 20px;
    }
    .new-location-form .form-main .application .input-group input {
        width: 70% !important;
    }
    .new-location-form .form-main .fee-main .detail {
        width: 100%;
        justify-content: space-between;
    }
    .new-location-form .form-main .paragraph p {
        font-size: 16px !important;
        text-align: center;
    }
    .new-location-form .form-main form .input-field label {
        font-size: 16px;
    }
    #location-form-main {
        margin: 0 0 70px !important;
    }
    .new-location-form .form-main form h3 {
        font-size: 18px;
    }
    .logo-for-pdf img {
        width: 130px;
    }
    .overview-text {
        margin: 20px 0px 0px;
    }
    .new-location-form .form-main form .input-date .input-field.birth {
        width: 100%;
        justify-content: left;
    }
    .new-location-form .form-main form .input-date .input-field.birth label {
        width: 30%;
    }
    .new-location-form .form-main form .input-date .input-field.birth input {
        width: 100%;
    }
    .new-location-form .form-main form .input-date .input-field {
        width: 100%;
        justify-content: right;
    }
    .new-location-form .form-main form .input-date .input-field input {
        width:100%;
    }
    .new-location-form .form-main .fee-main .detail .head h4 {
        font-size: 15px;
    }
    .new-location-form .form-main .fee-main .detail .head p {
        font-size: 16px;
        line-height: 20px;
    }
    .new-location-form .form-main label {
        font-size: 16px;
    }
    .new-location-form .form-main form .input-date .input-field label {
        width: 43%;
    }
    .new-location-form .form-main form .input-date {
        flex-wrap: wrap;
    }
    .new-location-form .form-main .submit-btn p {
        font-size: 18px;
        font-weight: 500;
        margin: 30px 0 25px;
        text-align: center;
    }
    .new-location-form .form-main .application .input-group.first {
        width: 100%;
    }
    .new-location-form .form-main .application .input-group.first label {
        width: 30%;
    }
    .wrap {
        margin: 2rem !important;
    }
    .react-pdf__Page {
        width: 100% !important;
    }
    .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
    }
    .back_icon {
        margin-left: 0 !important;
    }
    .schedule-agent .schedule-agent-left {
        padding: 34px 15px 0;
    }
    .get-info .get-head {
        justify-content: left;
    }
    .overview-img {
        margin: 0 0 0px;
    } 
    .cart-carousel p {
        text-align: left;
    }
    .account-main .profile-details-main .profile-details .profile-info .profile-input i {
        top: 38px;
    }
    .adviser-plans-col {
        width: 100%;
    }

    .team-profile-col {
        display: none;
    }

    .team-content-col {
        width: 100%;
    }

    #purchase-main .slick-dots {
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }

    #purchase-main .slick-dots .slick-active {
        width: 20px;
        height: 10px;
        background-color: #219653;
        border-radius: 10px;
        transition: 0.3s;
    }

    #purchase-main .slick-dots li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #e0e0e0;
        transition: 0.3s;
    }

    #purchase-main .slick-dots li button:before {
        font-size: 0;
    }

    .purchase-product {
        display: none;
    }

    .purchase-for-mobile {
        display: inline-block;
        width: 100%;
    }

    .footer-logo-col {
        width: 100%;
    }

    .footer-links-col {
        width: 100%;
    }
    .social-links ul {
        margin: 25px 0;
    }

    .footer-links {
        padding: 0;
        margin: 10px 0 35px;
    }

    .footer-top {
        width: 100%;
        display: inline-block;
        padding: 35px 0 0;
    }

    .purchase-col {
        width: 100%;
        float: left;
        padding: 0 15px;
    }

    .purchase-content {
        margin: 0 0 30px;
    }

    .banner-img img {
        object-fit: cover;
        height: 350px;
    }

    .banner-text h1 {
        font-size: 41px;
    }

    .banner-text a {
        width: 170px;
        height: 46px;
        line-height: 40px;
        margin: 0;
    }

    /* 
    .overview-img-col {
        display: none;
    } */

    .overview-text-col {
        width: 100%;
    }

    .overview-text a {
        margin: 30px 0 0;
    }

    .inspired-content h2,
    .overview-img h2,
    .purchase-head h2 {
        font-size: 35px;
        margin: 0 0 15px;
    }

    .team-content h2 {
        font-size: 35px;
    }

    .team-content p {
        margin: 7px 0 20px;
    }

    .inspired-content a {
        height: 40px;
        line-height: 40px;
    }

    .talk-adviser {
        flex-wrap: wrap;
        margin: 0 0 15px;
        padding: 20px 25px;
        border-radius: 10px;
    }

    .talk-adviser .msg-img {
        width: 20%;
    }

    .talk-adviser .adviser-head {
        width: 80%;
        padding: 0;
    }

    .talk-adviser .adviser-contact {
        width: 100%;
        text-align: left;
        margin: 35px 0 0;
    }

    .talk-adviser .adviser-contact a {
        margin: 0;
        height: 40px;
    }

    .adviser-plans .adviser-content a {
        height: 40px;
    }

    .adviser-bg-img {
        display: none;
    }

    .future-planing {
        position: static;
    }

    .adviser-plans {
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        margin: 0 0 15px;
    }

    .adviser-plans .adviser-img img {
        height: 250px;
        border-radius: 10px;
    }

    .adviser-plans .adviser-content {
        padding: 15px;
    }

    .team-member-img .team-left {
        padding: 30px 25px 0 0;
    }

    .team-member-img .team-left img,
    .team-member-img .team-right img {
        width: 65px;

    }

    .team-member-img .team-right img {
        margin: 0 0 30px;
    }

    .team-member-img .t-right {
        text-align: left;
    }

    .team-member-img .team-left.pd {
        padding: 65px 0 0 0;
    }

    .team-details span {
        margin: 20px 0 0;
    }

    .team-details p {
        margin: 14px 0 25px;
        font-size: 28px;
        padding: 0;
    }

    .team-details a {
        margin: 0 0 0 10px;
        width: 150px;
        height: 40px;
        font-size: 13px;
    }

    .inspired-content h2 {
        margin-top: 0;
    }

    #overview-main,
    #team-main,
    #purchase-main {
        margin: 70px 0;
    }

    /* contact page */

    .custom-banner-text h1 {
        font-size: 41px;
    }

    .contact-details-col,
    .contact-form-col {
        width: 100%;
    }

    .contct-details {
        max-width: none;
    }

    #contact-main {
        margin: 70px 0;
    }

    /* schedule page */

    .main-agents,
    .select-agent {
        padding: 0 15px;
    }

    .agents-col {
        width: 50%;
    }

    .Schedule-head {
        padding-left: 15px;
        padding-right: 15px;
    }

    .agents {
        justify-content: left;
    }

    #Schedule-main {
        margin: 70px 0;
    }

    /* about page */

    .mission-details img {
        height: 395px;
    }

    .core-details {
        padding: 15px;
    }

    .core-achieve {
        padding: 28px 15px 40px 15px;
    }

    .core-bg-img img {
        min-height: 880px;
    }

    .commit-header h2 {
        font-size: 32px;
    }

    .commit-img img {
        height: 450px;
    }

    #our-mission,
    #Inspired-commitment {
        margin: 70px 0;
    }

    .commit-header {
        width: 100%;
        margin: 0 0 35px;
    }

    /* product page  */

    .products {
        flex-wrap: wrap;
        gap: 15px;
    }

    .products .category {
        width: 100%;
        margin: 0 0 10px;
    }

    .products .burials-plots {
        width: 100%;
    }

    .products .burials-plots .product-head .product-type {
        width: 100%;
        margin: 25px 0 0;
    }

    .products .burials-plots .product-head .product-type .product-field {
        margin: 0 0 0 15px;
    }

    /* location page */

    .location-details-col {
        width: 50%;
    }

    .back-btn {
        margin: 0 0 40px;
    }

    .details-img-view .p-spacing {
        margin: 25px 0;
    }

    .get-info .get-head span {
        margin: 0 0 10px;
    }

    .get-info {
        padding: 20px;
        margin: 25px 0 0;
        gap: 15px;
        position: relative;
    }
    .get-info .get-head a {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }

    .amenities-head h3 {
        margin: 0 0 25px;
    }

    /* #location-details-main {
        margin: 29px 0 70px;
    } */

    /* blog page */
    .blogs {
        border: 1px solid #e0e0e0;
        padding: 15px;
        margin: 0 0 30px;
        height: 360px;
    }

    .blogs-col {
        width: 50%;
    }

    .key-point .key-details {
        margin: 0 0 40px;
    }

    .key-point .key-details.p-spacing {
        margin: 0 0 40px;
    }

    .Blog-details-head span {
        margin: 30px 0 18px;
    }

    .key-point {
        width: 100%;
        margin: 35px 0;
    }

    /* cart product page */
    .cart-item-col {
        width: 100%;
    }

    .cart-head h1 {
        margin: 25px 0;
    }

    .cart-checkout-col {
        width: 40%;
        float: right;
    }

    .cart-checkout {
        margin: 35px 0 0;
    }

    /* profile page */

    .account-main ul {
        /* Safari */
        -webkit-justify-content: left;
        /* Microsoft Edge */
        -ms-justify-content: left;
        /* Opera */
        -o-justify-content: left;
        /* Standard */
        justify-content: left;
        padding: 0 15px;
        gap: 40px;
    }

    .account-main ul li {
        margin: 0;
    }

    .account-main ul li a {
        padding: 15px 0;
        font-size: 14px;
    }

    .account-head {
        margin: 34px 0 20px;
    }

    .account-main .profile-details-main .profile-details .profile-info h3 {
        margin: 0 0 25px;
    }

    .account-main .profile-details-main .profile-details .profile-info .profile-input {
        width: 40%;
        position: relative;
        margin: 0 0 25px;
    }

    .account-main .profile-details-main .profile-details .profile-info {
        margin: 0 0 90px;
    }

    .account-main .profile-details-main .profile-details {
        padding: 30px 0 0 25px;
    }

    .account-main .profile-details-main .profile-btn {
        padding: 15px 25px;
    }

    .account-main .profile-details-main .profile-details .profile-upload {
        margin: 0 0 30px;
    }

    .account-main .profile-details-main .profile-details .profile-info .profile-input label {
        margin: 0 0 8px;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main .order-details p {
        margin: 7px 0 5px;
    }

    .account-main .profile-details-main .profile-details .profile-info .profile-input input {
        height: 45px;
    }

    /* natural burials page */

    .burials-desk {
        display: none;
    }

    .burials-mobile {
        display: inline-block;
        padding: 0 15px;
    }

    .burials-col,
    .burials-half-col {
        width: 100%;
    }

    .burials-half {
        padding: 15px;
        height: 340px;
    }

    .key-chain .key-chain-img {
        margin: 0 15px 0 0;
    }

    .key-chain .key-chain-img span {
        width: 45px;
        height: 45px;
    }

    .key-chain .key-chain-img span img {
        width: 20px;
    }

    .key-chain .key-chain-img::before {
        display: none;
    }

    .key-chain .key-chain-head h3 {
        margin: 0 0 5px;
    }

    .key-chain {
        margin: 0 0 25px;
    }

    .chain-footer {
        margin: 25px 0 0;
    }

    .process-footer {
        width: 100%;
        margin: 30px 0 0;
    }

    .faq ul li .faq-content .faq-video {
        padding-right: 0;
    }

    .faq ul li {
        padding: 15px;
    }

    .faq ul li.faq-active .faq-content {
        flex-wrap: wrap;
        margin: 25px 0 0;
    }

    .faq ul li .faq-content p {
        width: 100%;
        margin: 0 0 15px;
    }

    .faq ul li .faq-content .faq-video {
        width: 45%;
    }

    /* .overview-text a,
    .inspired-content a,
    .talk-adviser .adviser-contact a,
    .adviser-plans .adviser-content a,
    .team-content a,
    .contact-form form .input-group input[type="submit"],
    .banner-text a {
        border-radius: 10px;
    } */

    .overview-text.pet-text {
        padding: 0 0 !important;
        margin: 0;
    }

    .paragraph p {
        font-size: 35px !important;
    }

    .pet-burial-right {
        display: none;
    }

    .pet-burial-left {
        width: 100%;
    }

    #pet-burials-main {
        margin: 36px 0 55px;
    }

    .Honouring {
        text-align: left;
    }

    .goodbye-main {
        margin: 40px 0 50px !important;
    }

    .Honouring img {
        height: 500px;
    }

    .pet-burial-left h2,
    .Honouring h2 {
        font-size: 30px;
    }

    #petburial-paragraph {
        margin: 60px 0;
    }

    .celebration-detail-main {
        gap: 15px;
    }

    .harmony-head {
        margin: 0 0 35px;
    }

    .details-img-view p {
        font-size: 30px !important; 
    }

    .harmony-head h1,
    .discover-heritage .discover-heritage-head h2,
    .kendal-unique .overview-text h2 {
        font-size: 30px;
    }

    .discover-heritage {
        padding: 40px 15px;
    }

    .kendal-unique {
        margin: 30px 0 36px !important;
    }

    .serene img {
        height: 300px;
    }

    .serene-text {
        padding: 0 0 0 16px;
    }

    .documents-dropdown {
        left: 0;
        transform: none;
    }

    .products .burials-plots .product-head {
        justify-content: unset;
        column-gap: 30px;
    }

    .products .category ul li .category-link {
        width: 120px;
        height: 40px;
        font-size: 15px;
    }

    .products .burials-plots .product-burials-map {
        height: auto;
    }

    .pet-burials-img-col {
        display: none;
    }

    .pet-burials-text-col {
        width: 100%;
    }

    .pet-burials-main .overview-text h2 {
        font-size: 35px;
        line-height: 35px;
    }

    .overview-text.pet-text p {
        margin: 15px 0 !important;
    }

    .overview-btn a,
    .pet-burials-main .overview-text a {
        height: 40px;
        margin: 25px 0 0;
    }
    .process {
        height: 565px;
    }
    .new-location-form .form-main form .input-date .input-field.birth .input-date-picker {
        width: 70%;
    }
    .overview-img img {
        height: 280px;
    }
}

@media screen and (max-width:767px) {
    .get-info .get-head a {
        position: static;
    }
    .footer-logo-col {
        width: 100%;
        border-bottom: 1px solid #fff;
    }

    .footer-links-col {
        width: 100%;
        border-bottom: 1px solid #fff;
    }

    .footer-links {
        margin: 35px 0;
    }

    .social-links {
        padding: 0 0 30px;
    }

    .footer-bottom-content {
        border: none;
        flex-wrap: wrap;
    }

    .footer-bottom-content .footer-copyright {
        width: 100%;
        margin: 0 0 5px;
    }

    .footer-bottom-content .footer-term {
        width: 100%;
    }

    .footer-bottom-content .footer-term span {
        margin: 0 10px 0 0;
    }

    .inspired-content .p-spacing {
        margin: 0 0 10px;
    }

    .talk-adviser .msg-img {
        display: none;
    }

    .talk-adviser .adviser-head {
        width: 100%;
    }

    .talk-adviser {
        padding: 20px 15px;
    }

    .talk-adviser .adviser-contact {
        margin: 20px 0 0;
    }

    .team-member-col {
        display: none;
    }

    .team-details-col {
        width: 100%;
    }

    #overview-main,
    #team-main,
    #purchase-main {
        margin: 50px 0;
    }

    .team-details span {
        margin: 0;
    }

    /* Faq page  */

    .faq ul li {
        position: relative;
    }

    /* .faq ul li.faq-active .plus-btn {
        position: absolute;
        top: 35px;
        width: auto;
        transform: none;
        padding: 0;
    }

    .faq ul li .plus-btn {
        position: absolute;
        right: 15px;
        width: 18px;
        top: 50%;
        transform: translateY(-50%);
    } */

    /* about page */

    .mission-details img {
        display: none;
    }

    .mission-details-col {
        width: 100%;
    }

    .core-bg-img {
        display: none;
    }

    .core-content {
        position: static;
    }

    .core-details-col {
        width: 100%;
    }

    .core-details {
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        padding: 30px 15px;
        margin: 0 0 15px;
    }

    .core-achieve {
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        min-height: 310px;
    }

    .mission-head {
        margin: 0 0 20px;
    }

    .mission-details h2 {
        margin: 0 0 10px;
    }

    .core-head p {
        color: #828282;
        line-height: 24px;
        margin: 10px 0 20px;
    }

    .core-footer p {
        margin: 15px 0 0;
    }

    .commit-header h2 {
        font-size: 27px;
        line-height: 40px;
        margin: 0 0 15px;
    }

    .commit-details-col {
        width: 100%;
    }

    .commit-details .commit-tab {
        max-width: none;
    }

    .commit-img-col {
        display: none;
    }

    .commit-details .commit-tab p {
        margin: 0 0 10px;
    }

    .commit-details .commit-tab span {
        margin: 15px 0 10px;
    }

    .commit-header .p-spacing {
        margin: 0 0 15px;
    }

    .commit-header {
        margin: 0 0 15px;
    }

    /* product page */

    /* .products .category ul {
        padding: 20px 20px 5px;
    } */

    /* .products .category ul li {
        margin: 0 0 15px;
    } */

    .products .burials-plots .product-head .product-type {
        margin: 25px 0 0;
    }

    .product-col {
        width: 50%;
    }

    /* add to cart pop up */

    .burials-add-to-cart {
        max-width: 555px;
        padding: 15px;
        height: auto;
    }

    .burials-plot-detail .cart-btn {
        margin: 20px 0 0;
    }

    .burials-plot-detail .burials-remove-btn {
        top: 0px;
        right: 0;
    }

    /* cart success popup  */

    .cart-success {
        padding: 20px;
    }

    /* location page  */
    .get-info .get-contact ul li {
        padding: 0 20px;
    }

    .details-head .head-text h2 {
        font-size: 30px;
    }

    .details-head .details-btn a {
        height: 40px;
        line-height: 40px;
    }

    .details-img-view .p-mr {
        margin: 0 0 15px;
    }

    .area-text {
        width: 100%;
        margin: 35px 0;
    }

    #location-details-main h3 {
        font-size: 22px;
    }

    .get-info {
        flex-wrap: wrap;
    }

    /* .get-info .get-head {
        width: 100%;
        border-bottom: 1px solid #fff;
        padding: 0 0 20px;
    } */

    .get-info .get-contact {
        width: 100%;
        padding: 0;
        border: none;
    }

    .get-info .get-head span {
        margin: 0 0 5px;
    }

    .get-info .get-contact ul li {
        padding: 0 20px 0 0;
    }

    .amenities .amenities-details .places .place-img {
        margin: 0 10px 0 0;
    }

    /* 
    #location-details-main p {
        font-size: 14px;
    } */

    .back-btn {
        margin: 0 0 30px;
    }

    /* cart product page */

    .cart-item-main .cart-item .cart-img {
        width: 25%;
    }

    .cart-item-main .cart-item .cart-details {
        width: 75%;
    }

    .cart-head h1 {
        font-size: 22px;
    }

    .add-cart-item {
        margin: 15px 0 0;
    }

    .add-cart-item .add-item {
        width: 175px;
        height: 40px;
    }

    .Checkout-btn button {
        height: 40px;
    }

    .cart-checkout .checkout-head {
        padding: 10px;
    }

    .cart-checkout {
        margin: 25px 0 0;
    }

    #cart-main {
        margin: 0 0 50px;
    }

    /* profile page */

    .account-main ul {
        flex-wrap: wrap;
        row-gap: 0;
    }

    .account-main ul li {
        width: 100%;
    }

    .account-main .profile-details-main .profile-details .profile-info {
        margin: 0 0 45px;
    }

    .account-main .profile-details-main .profile-details .profile-upload .profile-img img {
        width: 120px;
        height: 120px;
    }

    .account-main .profile-details-main .profile-details .profile-upload .profile-delete .new-upload {
        width: 145px;
        height: 35px;
    }

    .account-main .profile-details-main .profile-details .profile-upload .profile-delete button {
        height: 35px;
    }

    .account-main .profile-details-main .profile-details .profile-upload .profile-img {
        width: auto;
        margin: 0 20px 0 0;
    }

    .account-main .profile-details-main .profile-details .profile-info .profile-input input {
        height: 40px;
        padding: 0 15px;
    }

    .account-main .profile-details-main .profile-btn button,
    .account-main .profile-details-main .profile-btn button.save {
        height: 40px;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main .order-img img {
        height: 140px;
    }

    #Burials-main p {
        font-size: 15px;
    }

    #Burials-main h2,
    #Burials-process-main h2,
    .key-head h2 {
        font-size: 30px;
    }

    #Burials-process-main p {
        font-size: 15px;
    }

    #natural-key-main p {
        font-size: 15px;
    }

    .agents {
        flex-wrap: wrap;
        padding: 15px;
        min-height: 195px;
    }

    .agents-col {
        padding: 0 15px;
    }

    .agents .agent-bio {
        width: 100%;
    }

    .schedule-agent {
        flex-wrap: wrap;
    }

    .schedule-agent .schedule-agent-left {
        width: 100%;
        padding: 34px 20px 20px 20px;
    }

    .schedule-agent .schedule-agent-right {
        width: 100%;
    }

    #pet-live-main,
    #Honouring-main {
        margin: 0 0 40px;
    }

    #pet-burials-main,
    .goodbye-main {
        margin: 40px 0;
    }

    .Honouring img {
        height: 350px;
    }

    .celebration-main {
        padding: 30px 0;
    }

    .details-img-view p {
        line-height: 32px !important;
    }

    .location-access-meet {
        margin: 0 0 68px !important;
    }

    .kendal p,
    .kendal-paragraph p {
        font-size: 16px;
    }

    .serene-text a {
        width: 170px;
    }

    .blogs {
        height: fit-content;
    }

    .overview-btn {
        width: 100%;
    }

    .overview-text-col {
        flex-wrap: wrap;
    }

    .overview-btn a {
        margin: 15px 0 0;
    }

    .overview-text {
        margin: 15px 0 0;
    }

    .overview-img img {
        height: 250px;
    }

    .products .category {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .products .category ul {
        width: 100%;
    }

}

@media screen and (max-width:639px) {
    .footer-logo {
        margin: 0 0 25px;
    }

    .footer-links {
        margin: 25px 0;
    }

    .adviser-plans .adviser-img img {
        height: 100%;
    }

    .inspired-content h2,
    .overview-img h2,
    .purchase-head h2 {
        font-size: 30px;
    }

    .banner-text h1 {
        font-size: 38px;
    }

    .banner-text a {
        width: 135px;
        height: 35px;
        line-height: 35px;
        font-size: 14px;
    }

    .talk-adviser .adviser-head h3 {
        font-size: 25px;
    }

    .talk-adviser .adviser-head p {
        font-size: 15px;
    }

    .team-content h2 {
        font-size: 30px;
    }

    .talk-adviser .adviser-contact a,
    .adviser-plans .adviser-content a {
        width: 150px;
        font-size: 15px;
    }

    .talk-adviser .adviser-contact a {
        width: 170px;
    }

    #footer-main h2 {
        font-size: 16px;
    }

    /* contact page */

    .custom-banner-text h1 {
        font-size: 38px;
    }

    .contct-details .contact-head p {
        margin: 11px 0 40px;
    }

    .contct-details .contact-head h2 {
        font-size: 30px;
    }

    .contct-details .contact-social ul li span {
        margin: 0 15px 0 0;
    }

    .contct-details .contact-social ul li {
        padding: 0 0 15px;
        margin: 0 0 30px;
    }

    .contact-form form {
        padding: 15px;
    }

    /* schedule page  */

    .agents {
        padding: 15px;
    }

    .Schedule-head h1 {
        font-size: 25px;
    }

    .select-agent h3 {
        font-size: 20px;
        margin: 26px 0 15px;
    }

    .next-btn a {
        margin: 0;
    }

    .agents i {
        top: 10px;
        right: 10px;
    }

    .mission-details h2,
    .core-head h2 {
        font-size: 30px;
    }

    #our-mission,
    #Inspired-commitment {
        margin: 50px 0;
    }

    .products .burials-plots .product-head .product-type {
        flex-wrap: wrap;
        margin: 10px 0 0;
    }

    .products .burials-plots .product-head .product-type span {
        width: 100%;
        margin: 0 0 15px;
    }

    .products .burials-plots .product-head .product-type .product-field {
        margin: 0 0 20px;
        width: max-content;
    }

    /* add to cart popup  */

    .burials-add-to-cart {
        max-width: 490px;
    }

    .burials-img ul {
        display: none;
    }

    .burials-plot-detail .price {
        margin: 20px 0;
    }

    /* location page */

    .details-head {
        flex-wrap: wrap;
        margin: 0;
    }

    .head-text,
    .details-btn {
        width: 100%;
    }

    .details-head .details-btn a {
        margin: 0 10px 0 0;
    }

    .details-head .head-text h2 {
        margin: 0 0 20px;
        font-size: 25px;
    }

    .amenities .amenities-details .places {
        width: 100%;
        margin: 0 0 25px;
    }

    .amenities .amenities-details .places .place-img {
        margin: 0 20px 0 0;
    }

    .details-head .details-btn a {
        width: 125px;
    }

    /* blog page */

    .blogs p {
        font-size: 18px;
    }

    .overview-content h1 {
        font-size: 25px;
        margin: 20px 0;
    }

    #learn-overview {
        margin: 27px 0 60px;
    }

    .Blog-details-head span {
        margin: 30px 0 18px;
    }

    .Blog-details-head h2 {
        font-size: 25px;
    }

    /* natural burials  */

    .process {
        height: 540px;
    }

    .burials-half,
    .burials {
        height: 270px;
    }

    .process .process-content {
        padding: 15px;
    }

    #Burials-main,
    #natural-key-main {
        margin: 55px 0;
    }

    #Burials-process-main {
        padding: 40px 0;
    }

    .process-head p {
        margin: 10px 0 15px;
    }

    #Burials-main .slick-dots {
        bottom: -5px;
    }


    /* cart product page */
    .cart-head h1 {
        font-size: 20px;
    }

    .cart-checkout-col {
        width: 45%;
    }

    .add-cart-item .add-item {
        width: 160px;
        height: 35px;
    }

    .cart-checkout {
        margin: 15px 0 0;
    }

    .cart-item-main .cart-item .cart-img img {
        width: 100%;
        height: 122px;
    }

    .cart-item-main .cart-item {
        gap: 15px;
    }

    .cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn button {
        width: 25px;
        height: 25px;
    }

    .cart-item-main .cart-item .cart-details p {
        margin: 5px 0 15px;
    }

    .account-main .profile-details-main .profile-details {
        padding: 30px 0 0 15px;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main .order-img img {
        height: 157px;
    }

    .account-main ul li a .fa-shopping-bag,
    .account-main ul li a .fa-credit-card-alt {
        font-size: 16px;
    }

    .account-head h1 {
        font-size: 22px;
    }

    .account-head {
        margin: 30px 0 15px;
    }

    #profile-main {
        width: 100%;
        display: inline-block;
        margin: 0 0 70px;
    }

    .account-main .profile-details-main .profile-details .profile-info .visa-details-main {
        margin: 0 15px 0 0;
    }

    .account-main .profile-details-main .profile-details .profile-info .add-visa {
        margin: 21px 15px 0 0;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order {
        margin: 0 15px 17px 0;
    }

    .agents-col {
        width: 100%;
        padding: 0 15px;
    }

    .pet-burial-left h2,
    .Honouring h2,
    .paragraph p {
        font-size: 24px !important;
    }

    .celebration-detail-main {
        flex-wrap: wrap;
        margin: 20px 0 0;
    }

    .celebration-detail-main .celebration-detail {
        width: 48%;
    }

    .pet-burial-left span {
        font-size: 16px;
    }

    .kendal img {
        height: 250px;
    }

    .kendal-col {
        width: 100%;
    }

    .kendal p,
    .kendal-paragraph p {
        margin: 0;
    }

    .kendal {
        margin: 25px 0 0;
    }

    .details-img-view p {
        margin: 30px 0 20px;
    }

    .harmony-head h1,
    .discover-heritage .discover-heritage-head h2,
    .kendal-unique .overview-text h2 {
        font-size: 24px;
    }

    .harmony-head {
        margin: 0 0 15px;
    }

    #harmony-main .pet-burial-left p {
        font-size: 16px;
    }

    #harmony-main {
        margin: 40px 0 0;
        padding: 0;
    }

    #harmony-main::before {
        display: none;
    }

    #harmony-main .kendal-paragraph p {
        margin: 10px 0 20px;
    }

    .location-access-meet {
        margin: 0 0 40px !important;
    }

    .overview-text.pet-text p {
        margin: 20px 0 !important;
    }

    .conclusion-main {
        margin: 0 0 40px !important;
    }

    #discover-heritage-main {
        margin: 40px 0 0;
    }

    .discover-heritage {
        padding: 20px 15px;
    }

    .sunrise-main {
        padding: 20px 0 30px;
    }

    .location-access-meet .paragraph span {
        margin: 15px 0 0;
    }

    .paragraph {
        text-align: left;
    }

    .serene-text h2 {
        font-size: 22px;
    }

    .serene-text p {
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
    }

    #serene-main {
        margin: 0 0 40px;
    }

    .overview-img img {
        height: 200px;
    }

    .pet-burials-main .overview-text h2 {
        font-size: 32px;
    }
}


@media screen and (max-width:575px) {
    .purchase-col {
        width: 100%;
    }

    .contect-details ul li.profile-icon {
        padding: 0 0 0 20px;
    }

    .header-logo a img {
        width: 140px;
    }

    .banner-text h1 {
        font-size: 27px;
    }

    .faq-desk {
        display: none;
    }

    .faq-mobile {
        display: unset;
    }

    .slide-mobile {
        width: 100%;
        display: inline-block;
    }

    .natural-burial-slide {
        display: none;
    }

    .footer-bottom-content .footer-copyright p {
        font-size: 12px;
        line-height: normal;
    }

    .footer-bottom-content .footer-term span {
        margin: 0 0 0;
        font-size: 12px;
    }

    .footer-logo a img {
        width: 140px;
    }

    .footer-top {
        padding: 15px 0 0;
    }

    .inspired-content h2,
    .overview-img h2,
    .purchase-head h2 {
        font-size: 22px;
        margin: 0 0 10px;
    }

    .banner-img img {
        height: 300px;
    }

    .banner-text a {
        width: 115px;
        font-size: 12px;
        margin: 15px 0 0;
    }

    .contect-details ul li a img {
        width: 35px;
        height: 35px;
    }

    .overview-btn a,
    .inspired-content a {
        margin: 15px 0 0;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        width: 110px;
    }

    .overview-btn a img {
        width: 18px;
    }

    .purchase-head h2 {
        margin: 0 0 15px;
    }

    #purchase-main .slick-dots {
        bottom: -10px;
    }

    .inspired-content h2 {
        margin-top: 0;
    }

    .purchase-content {
        margin: 0 0 15px;
    }

    .purchase-content img {
        height: 280px;
        object-fit: cover;
    }

    .purchase-content .purchase-links {
        padding: 0 15px;
    }

    .purchase-content .purchase-links span {
        font-size: 16px;
    }

    .purchase-content .purchase-links a {
        width: 30px;
        height: 30px;
    }

    .adviser-plans .adviser-img {
        display: none;
    }

    .adviser-plans .adviser-content h2 {
        margin: 0 0 10px;
        font-size: 18px;
    }

    .adviser-plans .adviser-content p {
        margin: 0 0 20px;
        font-size: 15px;
    }

    .talk-adviser .adviser-head h3 {
        font-size: 20px;
    }

    .talk-adviser .adviser-head p {
        font-size: 14px;
        line-height: 20px;
    }

    .talk-adviser .adviser-contact a,
    .adviser-plans .adviser-content a {
        width: 120px;
        font-size: 12px;
        height: 30px;
    }

    .talk-adviser .adviser-contact a {
        width: 150px;
    }

    .team-content h2 {
        font-size: 22px;
    }

    .team-content a {
        width: 140px;
        height: 30px;
        font-size: 12px;
    }

    .team-content a img {
        margin: 0 0 0 3px;
        width: 16px;
    }

    .team-content p {
        font-size: 15px;
        margin: 5px 0 15px;
    }

    .team-details {
        justify-content: left;
    }

    .team-details a {
        margin: 0 10px 10px 0;
        width: 145px;
    }

    #overview-main,
    #team-main,
    #purchase-main {
        margin: 40px 0;
    }

    .adviser-plans-col:last-child .adviser-plans {
        margin-bottom: 0;
    }

    /* contact page */

    .custom-banner-img img {
        height: 200px;
        object-position: right;
    }

    .custom-banner-text h1 {
        font-size: 27px;
    }

    #contact-main {
        margin: 40px 0;
    }

    .contct-details .contact-head h2 {
        font-size: 22px;
    }

    .contct-details .contact-head p {
        line-height: 23px;
        font-size: 15px;
        margin: 11px 0 25px;
    }

    .contct-details .contact-social ul li span {
        margin: 0 10px 0 0;
    }

    .contct-details .contact-social ul li span img {
        width: 30px;
        height: 30px;
    }

    .contct-details .contact-social ul li .social-left label {
        font-size: 16px;
        line-height: 22px;
    }

    .contct-details .contact-social ul li .social-left a,
    .contct-details .contact-social ul li .social-left p {
        font-size: 14px;
    }

    .contct-details .contact-social ul li {
        padding: 0 0 10px;
    }

    .contact-form form h3 {
        font-size: 18px;
        margin: 0 0 15px;
    }

    /* Faq page */

    .faq ul li {
        padding: 15px;
    }

    .faq ul li.faq-active .faq-content {
        flex-wrap: wrap;
    }

    .faq ul li .faq-content p {
        width: 100%;
        margin: 0 0 15px;
    }

    .faq ul li .faq-content .faq-video {
        width: 100%;
    }

    .faq ul li.faq-active .plus-btn {
        top: 15px;
    }

    .faq ul li h2 {
        font-size: 14px;
    }

    .faq h2 {
        margin: 0 0 15px;
        font-size: 22px;
    }

    #Faq-main {
        margin: 40px 0;
    }

    .agents {
        margin: 0 0 15px;
        padding: 10px;
    }

    .agents img {
        width: 50px;
        height: 50px;
        margin-bottom: -41px;
    }

    .Schedule-head .powered {
        width: 85px;
        padding: 0;
    }

    .Schedule-head .powered span {
        font-size: 10px;
    }

    .Schedule-head .powered img {
        width: 70px;
    }

    .Schedule-head {
        padding: 20px 15px 20px 15px;
    }

    .Schedule-head h2 {
        font-size: 22px;
    }

    #Schedule-main {
        margin: 40px 0;
    }

    .next-btn a,
    .back-schduleBtn a {
        height: 40px;
    }

    .view-more-agent-col {
        display: unset;
    }

    .agent-for-mobile {
        display: none;
    }

    .agent-for-mobile.schedule-active {
        display: unset;
    }

    /* about page */

    .mission-details h2,
    .core-head h2 {
        font-size: 22px;
    }

    .commit-header h2 {
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 10px;
    }

    .mission-head p,
    .mission-details p,
    .core-head p,
    .core-details p,
    .core-achieve p,
    .core-footer p,
    .commit-header p,
    .commit-details .commit-tab p,
    .commit-footer p {
        font-size: 15px;
    }

    .core-achieve-col {
        width: 100%;
    }

    .core-achieve {
        margin: 0 0 15px;
    }

    .core-footer p {
        margin: 0;
    }

    .core-details {
        padding: 20px 15px;
    }

    .core-details span {
        margin: 10px 0;
    }

    .mission-head {
        margin: 0 0 10px;
    }

    .commit-header .p-spacing {
        margin: 0 0 5px;
    }

    .commit-header {
        margin: 0;
    }

    .commit-details .commit-tab span {
        padding: 0 0 5px;
        font-size: 16px;
    }

    #our-mission,
    #Inspired-commitment {
        margin: 45px 0;
    }

    /* empty cart page */

    #cart-empty-main {
        margin: 40px 0;
    }

    .cart-empty h2 {
        font-size: 22px;
    }

    .cart-empty p {
        margin: 15px 0;
        font-size: 15px;
    }

    .cart-empty a {
        font-size: 14px;
        width: 155px;
        height: 40px;
    }

    /* product page  */

    .product-col {
        width: 100%;
    }

    .products .burials-plots .product-burials-map img {
        height: 300px;
        object-fit: cover;
    }

    #product-main h2 {
        width: 100%;
    }

    .products .burials-plots .product-head h2 {
        margin: 0 0 15px;
    }

    .products .burials-plots .product-head .product-location {
        margin: 0 0 15px;
    }

    #product-main {
        margin: 30px 0 40px;
    }

    .products .burials-plots .product-head {
        flex-wrap: wrap;
        margin: 0;
    }

    .products .burials-plots .product-head .product-type .product-field .rounded-checkbox {
        width: 15px;
        height: 15px;
    }

    .products .burials-plots {
        width: 100%;
        margin: 10px 0 0;
    }

    .products .burials-plots .product-head .product-type {
        margin: 0;
        column-gap: 25px;
    }

    /* add to cart pop up  */

    .burials-plot-detail {
        width: 100%;
    }

    .burials-add-to-cart {
        padding: 15px;
        height: auto;
        max-width: 90%;
    }

    .burials-img {
        display: none;
    }

    .burials-plot-detail p {
        font-size: 14px;
    }

    .burials-plot-detail .cart-btn {
        margin: 20px 0 0;
    }

    .burials-plot-detail .price {
        margin: 10px 0;
    }

    /* cart success page */

    .cart-success {
        max-width: 300px;
        padding: 15px;
    }

    .cart-success p {
        margin: 0 0 10px;
    }

    .cart-success span {
        margin: 10px 0 8px;
    }

    /* location page */

    .location-desk {
        display: none;
    }

    .location-mobile {
        display: inline-block;
    }

    .location-details-col {
        width: 100%;
    }

    #location-main {
        margin: 60px 0 60px;
    }

    .location-details .location-text {
        padding: 15px;
    }

    .details-head .details-btn a {
        width: 110px;
        line-height: 30px;
        height: 30px;
        font-size: 14px;
    }

    .details-head .head-text h2 {
        margin: 0 0 15px;
        font-size: 22px;
    }

    .back-btn {
        margin: 0 0 10px;
    }

    .details-img-view img {
        height: 200px;
        object-fit: cover;
    }

    .amenities .amenities-details .places .place-img span {
        width: 40px;
        height: 40px;
    }

    .amenities .amenities-details .places .place-img span img {
        width: 20px;
    }

    .amenities .amenities-details .places .place-img {
        margin: 0 10px 0 0;
    }

    .amenities .amenities-details .places .place-content span {
        font-size: 16px;
        margin: 0;
    }

    #location-details-main .amenities .amenities-details .places .place-content p {
        font-size: 12px;
    }

    #location-details-main h3 {
        font-size: 18px;
        margin: 0 0 10px;
    }

    #location-main .slick-dots li.slick-active {
        width: 30px;
        border-radius: 10px;
        background-color: #219653;
        transition: all 0.5s;
    }

    #location-main .slick-dots li button::before {
        font-size: 0;
    }

    .location-details {
        margin: 0 0 10px;
    }

    #location-main .slick-dots li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #828282;
        transition: all 0.5s;
    }

    .area-text .area-field {
        margin: 0 0 15px;
    }

    .area-text .area-field span {
        font-size: 16px;
        margin: 0 0 5px;
    }

    .area-text {
        margin: 15px 0 0;
    }

    .amenities {
        margin: 20px 0 0;
    }

    .get-info {
        margin: 0;
        padding: 20px 15px;
        row-gap: 10px;
    }

    .get-info .get-head span {
        font-size: 18px;
    }

    .get-info .get-head {
        padding: 0 0;
        flex-wrap: wrap;
        row-gap: 0;
        justify-content: left;
    }

    .get-info .get-head a {
        margin: 15px 0 0;
        font-size: 14px;
        height: 30px;
        width: 165px;
    }

    .get-info .get-heading {
        width: 100%;
    }

    .get-info .get-contact {
        margin: 10px 0 0;
    }

    .get-info .get-contact ul li {
        padding: 0 10px 10px 0;
        margin: 0;
    }

    .get-info .get-contact ul li a img {
        width: 18px;
    }

    .get-info .get-contact ul li a {
        font-weight: 500;
        font-size: 14px;
    }

    /* #location-details-main {
        margin: 29px 0 50px;
    } */

    #location-details-main p {
        line-height: 20px;
    }

    /* blog page  */

    .key-point .key-details .key-text p.p-spacing {
        margin: 0 0 10px;
    }

    .key-point .key-details.p-spacing {
        margin: 0;
    }

    .blog-desk {
        display: none;
    }

    .blog-mobile {
        display: inline-block;
    }

    .blogs-col {
        width: 100%;
    }

    .blogs p {
        font-size:18px;
        /* font-size: 16PX; */
    }

    #blog-main {
        margin: 55px 0 40px;
    }

    .Blog-details-head h2 {
        font-size: 22px;
    }

    .Blog-details-head span {
        margin: 20px 0 18px;
        font-size: 14px;
    }

    .Blog-details-head img {
        height: 200px;
        object-fit: cover;
    }

    .key-point .key-details {
        flex-wrap: wrap;
        margin: 0 0;
    }

    .key-point .key-details .key-img,
    .key-point .key-details .key-text {
        width: 100%;
        padding: 0;
        margin: 0 0 15px;
    }

    .Blog-details-head p,
    .key-point .key-details .key-text p,
    .conclusion p {
        font-size: 14px;
    }

    .key-point {
        margin: 35px 0 0;
    }

    .key-point h1 {
        font-size: 20px;
        margin: 0 0 15px;
    }

    #Blog-details {
        margin: 27px 0 50px;
    }

    .key-point .key-details .revert-text {
        margin: 0 0 15px;
    }

    .Blog-details-head a img,
    .back-btn a img {
        width: 13px;
        height: 13px;
        margin: 0 5px 0 0;
    }

    /* cart product page */

    .cart-checkout-col {
        width: 100%;
    }

    .cart-item-main .cart-item {
        flex-wrap: wrap;
        border-bottom: none;
        gap: 10px;
    }

    .cart-item-main .cart-item .cart-img,
    .cart-item-main .cart-item .cart-details {
        width: 100%;
    }

    .cart-checkout {
        margin: 20px 0 0;
    }

    .cart-item-main .cart-item .cart-img img {
        height: auto;
        width: 150px;
    }

    .cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn button {
        width: 20px;
        height: 20px;
    }

    .cart-item-main .cart-item .cart-details p {
        line-height: 22px;
    }

    /* plot confirmation popup  */

    .plot-confirmation {
        padding: 15px;
        max-width: 270px;
    }

    .plot-confirmation img {
        width: 30px;
    }

    .plot-confirmation span {
        font-size: 18px;
        line-height: 14px;
        margin: 10px 0;
    }

    .plot-confirmation p {
        margin: 0 0 20px;
        font-size: 14px;
    }

    .plot-confirmation button {
        width: 85px;
        height: 35px;
    }

    .order-complete {
        padding: 15px;
        max-width: 270px;
    }

    .order-complete span {
        font-size: 18px;
        line-height: 14px;
        margin: 15px 0 0;
    }

    .order-complete p {
        margin: 8px 0 15px;
    }

    .order-complete button {
        width: 85px;
        height: 35px;
    }

    /* profile page */
    .account-main ul li a i {
        margin: 0 10px 0 0;
        width: 21px;
    }

    .account-main .profile-details-main .profile-details .profile-upload {
        flex-wrap: wrap;
    }

    .account-main .profile-details-main .profile-details .profile-upload .profile-img {
        width: 100%;
    }

    .account-main .profile-details-main .profile-details .profile-upload .profile-delete {
        margin: 20px 0 0;
    }

    .account-main .profile-details-main .profile-details .profile-info h3 {
        font-size: 18px;
        margin: 0 0 15px;
    }

    .account-main .profile-details-main .profile-details .profile-info .profile-input {
        width: 100%;
        margin: 0 0 20px;
    }

    .account-main .profile-details-main .profile-details .profile-info .profile-input input {
        font-size: 14px;
    }

    .account-main .profile-details-main .profile-details .profile-info .profile-input input::placeholder {
        font-size: 12px;
    }

    .account-main ul {
        padding: 0;
    }

    .account-main {
        padding: 0 15px;
    }

    .account-main .profile-details-main .profile-details {
        padding: 30px 0 0;
    }

    .account-main .profile-details-main .profile-btn button,
    .account-main .profile-details-main .profile-btn button.save {
        height: 35px;
    }

    .account-main .profile-details-main .profile-btn {
        padding: 15px 0px;
    }

    .account-main .profile-details-main .profile-btn button.save {
        width: 110px;
    }

    .account-main .profile-details-main .profile-btn button {
        width: 75px;
    }

    .account-main .profile-details-main .profile-details .profile-info .profile-input label {
        margin: 0 0 5px;
        font-size: 12px;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main {
        flex-wrap: wrap;
        padding: 0 0 10px;
        border-bottom: 1px solid #e0e0e0;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main .order-img {
        width: 100%;
        margin: 20px 0 15px;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order {
        margin: 0 0 17px 0;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main .order-img img {
        height: 115px;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main .order-details {
        width: 100%;
    }

    .account-main .profile-details-main .profile-details .profile-info .my-order .order-details-main .order-details p {
        margin: 7px 0 8px;
        line-height: 22px;
    }

    .account-main .profile-details-main .profile-details .profile-info .add-visa {
        justify-content: right;
        margin: 10px 0 0 0;
    }

    .account-main .profile-details-main .profile-details .profile-info .add-visa button {
        height: 35px;
        font-size: 12px;
    }

    .account-main .profile-details-main .profile-details .profile-info .visa-details-main {
        margin: 0;
        padding: 15px;
    }

    .account-main .profile-details-main .profile-details .profile-info .visa-details-main .visa-details img {
        width: 50px;
    }

    .account-main .profile-details-main .profile-details .profile-info .visa-details-main .visa-details .visa-number {
        margin: 0 0 0 10px;
    }

    .account-main .profile-details-main .profile-details .profile-info .visa-details-main .visa-details .visa-number span {
        line-height: unset;
        font-size: 12px;
    }

    .account-main .profile-details-main .profile-details .profile-info .visa-details-main .visa-details .visa-number p {
        font-size: 12px;
        color: #000;
        line-height: 139%;
    }

    #profile-main {
        margin: 0 0 45px;
    }

    /* natural burials page */

    #Burials-main h2,
    #Burials-process-main h2,
    .key-head h2 {
        font-size: 22px;
    }

    #Burials-main p,
    #Burials-process-main p,
    #natural-key-main p {
        font-size: 14px;
    }

    #Burials-main,
    #natural-key-main {
        margin: 40px 0;
    }

    #Burials-process-main {
        padding: 25px 0;
    }

    .burials-half,
    .burials {
        height: 355px;
    }

    .burials-half img,
    .burials img {
        width: 25px;
    }

    .burials h3 {
        margin: 9px 0;
        font-size: 16px;
    }

    .key-head {
        margin: 0 0 20px;
    }

    .key-chain .key-chain-img span {
        width: 40px;
        height: 40px;
    }

    .key-chain .key-chain-img span img {
        width: 18px;
    }

    .key-chain .key-chain-img {
        margin: 0 0 10px;
        width: 100%;
    }

    .key-chain {
        flex-wrap: wrap;
        border: 1px solid #f2f2f2;
        padding: 15px;
        border-radius: 10px;
        margin: 0 0 20px;
    }

    .key-chain .key-chain-head h3 {
        font-size: 18px;
    }

    #Burials-main .slick-dots {
        bottom: 0;
    }

    .Burials-head {
        margin: 0 0 15px;
    }

    .natural-footer {
        margin: 15px 0 0;
    }

    .process-footer {
        margin: 15px 0 0;
    }

    #Burials-process-main .slick-dots .slick-active {
        width: 30px;
    }

    #Burials-process-main .slick-dots li {
        width: 10px;
        height: 10px;
    }

    #Burials-main .slick-dots li {
        width: 10px;
        height: 10px;
    }

    #Burials-main .slick-dots li.slick-active {
        width: 30px;
    }

    /* confirm logout popup  */

    .confirm-logout {
        width: 270px;
        padding: 18px 0;
    }

    .confirm-logout p {
        margin: 12px 0 20px;
    }

    .confirm-logout ul li a {
        width: 70px;
        height: 30px;
    }

    /* profile dropdown */

    .profile-dropdown {
        top: 47px;
        right: 11px;
        width: 200px;
    }

    .profile-dropdown .profile-info img {
        width: 50px;
        height: 50px;
    }

    .profile-dropdown .profile-info h3 {
        font-size: 16px;
    }

    .profile-dropdown .profile-info span {
        font-size: 13px;
    }

    .profile-dropdown .profile-info {
        padding: 15px 0;
    }

    .profile-dropdown .log-out {
        padding: 15px 0 0 23px;
    }

    .profile-dropdown .log-out ul li {
        margin: 0 0 10px;
    }

    .profile-dropdown .log-out ul li a {
        font-size: 14px;
    }

    .social-links {
        padding: 0 0 20px;
    }

    /* .social-links ul li a img {
        width: 17px;
    } */

    /* learn more overview */

    .overview-content img {
        height: 200px;
    }

    .overview-content h1 {
        font-size: 22px;
        margin: 15px 0;
    }

   
    .overview-content a img {
        margin: 0 5px 0 0;
        width: 15px;
        height: 15px;
    }

    .overview-content img {
        margin: 0 0 20px;
    }

    .overview-content p {
        line-height: 22px;
        font-size: 15px;
        margin: 0 0 10px;
    }

    #learn-overview {
        margin: 40px 0;
    }

    .chain-footer {
        margin: 15px 0 0;
    }

    #Burials-process-main .slick-dots {
        bottom: -25px;
    }

    .products .category ul li {
        height: 40px;
        width: 33.33%;
    }

    .products .category ul li .category-link {
        justify-content: left;
        padding: 0 10px;
    }

    .agents span {
        font-size: 16px;
    }

    .schedule {
        padding: 0 0 25px;
    }

    .schedule-row {
        margin: 0 -15px;
    }

    .Schedule-head-col,
    .select-col,
    .agents-col,
    .next-btn-col {
        padding: 0 15px;
    }

    /* authentication popup */
    .login-main {
        padding: 20px;
        max-width: 90%;
    }

    .login-main .logo span {
        top: 0;
        right: 0;
    }

    .login-main .login-field .input-field .input-check input[type="checkbox"] {
        width: 15px;
        padding: 0;
        margin: 0 0 0;
        height: 15px !important;
    }
    .register-main .login-field .input-field .input-check input[type="checkbox"] {
        margin: 0 0 14px 0;
    }

    .login-main .login-field .input-field .input-check label {
        margin: 0 0 0 8px;
        font-size: 14px;
        
    }

    .login-main .login-field {
        margin: 25px 0 0;
    }

    .login-main .login-field .input-field a {
        margin: 0;
        font-size: 14px;
    }

    .login-main .login-field .input-field .input-check {
        margin: 0 0;
        gap: 5px;
    }

    .login-main .login-btn a,
    .login-main .login-btn .reset-btn {
        height: 40px;
        font-size: 14px;
    }

    .login-main .log-footer {
        margin: 10px 0 0;
    }

    .login-main .log-footer p button,
    .login-main .log-footer p .active-link,
    .login-main .log-footer p .register-login {
        font-size: 14px;
    }

    .login-main .login-field .input-field input {
        height: 40px !important;
    }
    .login-main .login-field .input-field input::placeholder {
        padding: 0 !important;
    }

    .login-main .login-field h2 {
        font-size: 20px;
    }

    .login-main .login-field p {
        margin: 9px 0 20px;
        font-size: 14px;
    }

    .login-main .login-field .input-field .verify-otp {
        margin: 0;
    }

    .login-main .login-field .input-field .verify-otp input {
        height: 30px !important;
        padding: 0;
    }

    .login-main .login-field .input-field .input-field-text {
        height: 40px !important;
        margin-top: -40px;
        padding: 0 10px;
    }

    .banner-img {
        height: 200px;
      }

    .products .burials-plots .product-items {
        padding: 0 15px;
    }

    #Burials-process-main .slick-next,
    #Burials-process-main .slick-prev {
        bottom: -25px;
    }

    .schedule-agent-profile {
        text-align: center;
    }

    .schedule-agent .schedule-agent-left h3 {
        margin: 0 0 10px;
    }

    .register-main.register-active {
        padding: 20px 20px 0;
    }


    .pet-burial-left h2,
    .Honouring h2,
    .paragraph p {
        font-size: 23px !important;
    }

    .Honouring h2 {
        margin: 0 0 20px;
    }

    .Honouring img {
        height: 230px;
    }

    .celebration-detail-main .celebration-detail {
        width: 100%;
    }

    #petburial-paragraph {
        margin: 40px 0;
    }

    .paragraph {
        margin: 0 0 20px;
        text-align: left;
    }

    .details-img-view p {
        font-size: 23px !important;
        margin: 30px 0 10px;
    }

    .harmony-head,
    .discover-heritage .discover-heritage-head {
        text-align: left;
    }

    .harmony-cta-main .cta {
        flex-wrap: wrap;
        padding: 15px;
    }

    .harmony-cta-main .cta .left {
        width: 100%;
    }

    .harmony-cta-main .cta a {
        width: 100%;
        height: 30px;
        font-size: 12px;
        gap: 5px;
        border-radius: 4px;
    }

    .harmony-cta-main .cta .left span {
        font-size: 16px;
    }

    .harmony-cta-main .cta .left p {
        font-size: 14px;
    }

    .discover-heritage .discover-heritage-head h2 {
        padding: 0;
    }

    .kendal-unique .overview-text a {
        margin: 15px 0 0 !important;
    }

    .serene-text a {
        height: 30px;
        font-size: 14px;
    }

    .overview-text p {
        padding: 0;
    }

    .documents-dropdown {
        width: 100%;
        padding: 10px;
    }

    .documents-dropdown ul li {
        padding: 0;
    }

    /* .overview-img img {
        display: none;
    } */

    .overview-text {
        margin: 15px 0 0;
    }

    .products .category ul {
        flex-wrap: wrap;
    }

    .pet-burials-main .overview-text h2 {
        font-size: 22px;
    }

    .overview-text.pet-text p {
        font-size: 15px;
        margin: 5px 0 15px !important;
    }

    .overview-btn a,
    .pet-burials-main .overview-text a {
        height: 30px;
        width: 140px;
        font-size: 13px;
    }

    .login-main .login-btn {
        margin: 10px 0 0;
    }

    .login-main .login-field .input-field input {
        padding: 0 10px;
    }
    .details-img-view {
        margin: 30px 0 0;
    }
    .account-main .profile-details-main .profile-details .profile-info .profile-input i {
        top: 31px;
        font-size: 16px;
    }
    .buyers-main {
        width: 90%;
        padding: 18px 18px 26px;
    }
    .buyers-main .closeBtn {
        top: 20px;
        right: 20px;
    }
    .buyers-main h2 {
        font-size: 18px;
        margin: 4px 0 30px;
    }
    .buyers-main .head p {
        font-size: 15px;
        margin: 0 0 10px;
    }
    .buyers-main .input-group .input-field input, .buyers-main .input-group .input-field textarea, 
    .buyers-main .next-btn{
        height: 35px;
        padding: 10px;
    }
    .buyers-main .input-group .input-field textarea {
        height: 60px !important;
    }
    .buyers-main .next-btn {
        height: 40px;
        margin-top: 10px;
    }
    .buyers-main .input-group .input-field input::placeholder, .buyers-main .input-group .input-field textarea::placeholder {
        padding: 0 ;
    }
    .buyers-main .input-group .input-field input, .buyers-main .input-group .input-field textarea,
    .buyers-main .input-group .input-pin {
        flex-wrap: wrap;
        font-size: 13px;
    }
    .buyers-main .input-group .input-pin .input-code {
        width: 100%;
    }
    .text-content .btn, .text-content1 .btn {
        font-size: 15px;
        height: 35px;
    }

    .cart-carousel {
        max-width: 90% !important;
    }
    .buyers-main .head p .active , .buyers-main .head p span {
        margin: 0;
    }
    .get-info .get-head a {
        position: static;
    }
    .get-info .get-head a img {
        width: 18px;
    }
    .serene-text h2 {
        font-size: 20px;
    }
    .serene-text p {
        font-size: 14px !important;
    }
    .serene-text {
        top: 45%;
    }
    .serene img {
        height: 265px;
    }
    .paragraph p {
        line-height: 30px !important;
    }

    .schedule-agent .schedule-agent-left .left-full {
        flex-wrap: wrap;
    }
    .schedule-agent .schedule-agent-left {
        padding: 20px 10px 20px 10px;
    }
    .agents .agent-bio p, .toggle-button {
        font-size: 14px;
    }
    .toggle-button {
        position: static;
    }
    .agents .agent-bio {
        position: relative;
        margin: 30px 0 0 0 ;
    }
    .burials-plot-detail button {
        font-size: 11px;
        font-weight: normal;
        width: 80px;
    }
    .back_icon {
        font-size: 14px;
    }
    .back_icon img {
        width: 12px;
    }
    .go-back a img {
        width: 13px;
    }
    .go-back a {
        font-size: 15px;
    }
    .terms-details .policy-head .heading {
        flex-wrap: wrap;
        margin: 0 0 15px;
    }
    .terms-details .policy-head .heading span.last-update {
        margin: 5px 0 0;
        width: 100%;
    }
    .terms-details .back-btn {
        margin: 0 0 20px;
    }
    .terms-details .policy-head p {
        font-size: 14px !important;
    }
    .terms-details .policy-list .policy h3 {
        font-size: 20px;
    }
    .terms-details p {
        font-size: 14px;
        line-height: 20px;
    }
    .terms-details .policy-list .policy {
        margin: 0 0 20px;
    }
    #terms-main {
        margin: 25px 0 50px;
    }
    .terms-details .policy-head .heading span {
        font-size: 22px;
    }
    .terms-details .policy-head .heading h1 {
        font-size: 22px;
    }
    label.MuiFormLabel-root  {
        line-height: 11px !important;
        overflow: unset !important;
        font-size: 14px !important;
    }
    .login-main .login-field .input-field input.dial-code-input {
        height: 40px !important;
        border-right: none;
        width: 55px !important;
        padding: 0 10px;
        font-size: 15px;
        border: 1px solid rgb(0 0 0 / 20%);
        border-radius: 2px 0 0 2px;
    }
    .login-main .login-field .input-field .input-check label.register-term {
        width: 275px;
        margin: 0;
    }
    .login-main .login-field .input-field .fa-eye-slash {
        top: 12px;
    }
    .login-main .login-field .input-field .fa-eye {
        top: 12px;
        transform: unset;
    }
    .verify-mail-main .login-field .input-field {
        margin: 0;
    }
    .cart-item-main .cart-item .cart-details .cart-quantity .cart-item-remove button {
        justify-content: right;
    }
    .cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn span {
        font-size: 10px;
    }
    .add-Card {
        max-width: 90%;
        padding: 20px 15px 27px;
    }
    .register-main .login-field .input-field input {
        border: unset;
    }
    .buyers-main .input-group .input-field input {
        padding: 4px 5px;
    }
    .new-location-form h1 {
        font-size: 23px;
        margin: 0 0 20px;
    } 
    .new-location-form .form-main form h3 {
        font-size: 16px;
        font-weight: 500;
    }
    .new-location-form .form-main form .input-field {
        flex-wrap: wrap;
    }
    .new-location-form .form-main form .input-field label {
        text-align: left;
        width: 100%;
    }
    .new-location-form .form-main form {
        margin: 0 0 20px;
    }
    .new-location-form .form-main form .input-date .input-field {
        justify-content: left;
    }
    .new-location-form .form-main form .input-date .input-field input {
        width: 100%;
    }
    .new-location-form .form-main form .input-field input {
        width: 100%;
        height: 40px;
    }
    .new-location-form .form-main form .input-date .input-field.birth label {
        width: 100%;
    }
    .new-location-form .form-main form .input-date .input-field input {
        width: 100% !important;
    } 
    .new-location-form .form-main form .input-date .input-field label {
        width: 100%;
    }
    .new-location-form .form-main form .input-date .input-field.birth input {
        width: 100% !important;
    }
    .new-location-form .form-main .fee-main .detail .head, .new-location-form .form-main .fee-main .detail .checkbox {
        width: 50%;
    }
    .new-location-form .form-main .fee-main .detail .head h4 {
        gap: 5px;
    }
    .new-location-form .form-main .fee-main .detail .checkbox .check {
        gap: 10px;
    }
    .new-location-form .form-main .fee-main .detail .checkbox .check input {
        height: 16px;
        width: 16px;
    }
    .new-location-form .form-main .fee-main {
        margin: 0 0 40px;
    }
    .new-location-form .form-main .paragraph p {
        line-height: 20px !important;
        text-align: left;
        font-size: 13px !important;
    }
    .new-location-form .form-main .application {
        margin: 20px 0 0;
    }
    .new-location-form .form-main .application .input-group.first, .new-location-form .form-main .application .input-group {
        flex-wrap: wrap;
        width: 100%;
    }
    .new-location-form .form-main .submit-btn p {
        margin: 25px 0;
        font-size: 15px;
    }
    .new-location-form .form-main .submit-btn button {
        width: 100%;
        font-size: 16px;
        height: 40px;
    }
    .new-location-form .form-main .application .input-group.first label, .new-location-form .form-main .application .input-group label {
        width: 100%;
        text-align: left;
    }
    .new-location-form .form-main .application .input-group input {
        width: 100% !important;
        height: 40px;
    }
    .footer-bottom-content .footer-term {
        justify-content: space-between;
    }
    .new-location-form .form-main .submit-btn button:hover::after {
        display: none;
    }
    .new-location-form .form-main .fee-main .detail .checkbox.second {
        width: 50% !important;
    }
    .logo-for-pdf img {
        display: none;
    }
    .logo-for-pdf {
        padding: 10px;
    }
    .invalidPrice {
        position: unset;
    }
    .new-location-form .form-main form .input-date .input-field.birth .input-date-picker {
        width: 100%;
    }
    .overview-img img {
        height: 120px;
    }
    form .event_bullets ul li {
        font-size: 13px;
    }
    .login-main .login-field .input-field {
        margin: 0 0 14px;
    }
    .cookie-main {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: fit-content;
    }
    .terms-details .our-term h1 {
        font-size: 24px;
        margin: 0 0 5px;
    }
    .terms-details .our-term {
        margin: 0 0 15px;
    }
    .terms-update {
        margin: 0 0 15px;
    }
    .social-links ul {
        margin: 25px 0 0;
    }
    .visited-main {
        width: 90%;
        padding: 50px 15px  15px;
    }
    .visited-main .input-group {
        flex-wrap: wrap;
    }
    .visited-main .input-group .input-field {
        width: 100%;
    }
    .visited-main .input-group .input-field label {
        line-height: 23px !important;
    }
    .visited-main .logo img {
        width: 45px;
    }
    .visited-main h2 {
        font-size: 18px;
    }
    .visited-main p {
        font-size: 14px;
        margin: 15px 0;
    }
    .street-postmain {
        width: 90%;
        height: 80vh;
        overflow-y: auto;
        padding:15px 15px;
    }
    .street-postmain .logo {
        position: static;
        transform: unset;
        padding: 0;
        margin: 0 0 15px;
        background-color: transparent;
    }
    .street-postmain .logo img {
        width: 45px;
    }
    .street-postmain .input-group {
        flex-wrap: wrap;
    }
    .street-postmain .input-group .input-field {
        width: 100%;
    }
    .street-postmain .input-group .input-field input {
        height: 45px;
    }
    .street-postmain .input-group .input-field label {
        line-height: 13px !important;
    }
    .street-postmain .submit-btn {
        height: 45px;
    }
    .contact-form form .input-group button {
       
          height: 45px;
          /* padding: 16px; */
          font-weight: normal;
      }
      .burials-process-col {
        width: 100%;
      }
      .burials-process {
        margin: 0 0 15px;
      }
      .burials-head h2 {
        font-size: 22px;
    }
    #burials-process-main {
        padding: 45px 0 0;
    }
    .burials-process img {
        height: 330px;
    }
    .burials-process .burials-details {
        text-align: left;
        padding: 15px;
    }
    .burials-process .burials-details a {
        margin: 0;
    }
    .burials-process .burials-details a {
        width: 140px;
        height: 40px;
        font-weight: 400;
        font-size: 14px;
    }
    .home-about-naturals {
        margin: 40px 0  !important;
    }
    .speak-advisor-btn {
        width: 190px !important;
    }
    .home-types-main .family-types .family-types-box .family-types-img {
        height: auto;
    }
    .kendal-perfect-main {
        margin: 40px 0 0  !important;
    }
    .cta_new {
        margin: 40px 0 0;
    }
    .burials-process .burials-details h3 {
        margin: 0 0 15px;
    }
    .faq-new-main .faq h2 {
        font-size: 22px;
        margin: 0 0 25px;
    }
    .faq-new-main .faq ul li .faq-content {
        margin: 15px 0 0;
    }
    .faq-new-main .faq ul li h2{
        font-size: 16px;
    }
    .faq-new-main .faq ul li {
        padding: 15px;
    }
    .new-cta .new-cta-details h3 {
        font-size: 18px;
        margin: 0 0 10px;
    }
    .new-cta .new-cta-details h2  {
        font-size: 18px;
        margin: 0 0 10px;
    }
    #new-cta-main {
        margin: 0 0 55px;
    }
    .burials-new-main {
        margin: 40px 0 25px  !important;
    }
    .new-cta .new-cta-details p {
        line-height: 20px;
        margin: 0 0 10px;
    }
    .new-cta .new-cta-details p {
        font-size: 14px;
    }
    .new-cta .new-cta-details {
        max-width: 85%;
    }
    .faq ul li .faq-content p {
        font-size: 14px;
    }
    .faq-new-main {
        margin: 0 0 20px !important;
    }
    .burials-new-main .burials-half p.pt-6{
        margin-top: 10px !important; 
    }
    .why-green-head h2 {
        font-size: 22px;
        margin: 0 0 10px;
        line-height: 34px;
    }
    #why-green-burials {
        padding: 45px 0;
        margin: 0 0 45px;
    }
    .why-green-head p {
        font-size: 14px;
        line-height: 20px;
    }
    .why-green-cols {
        width: 100%;
    }
    .why-green {
        height: auto;
        padding: 15px;
        margin: 0 0 15px;
    }
    .why-green p {
        font-size: 14px;
    }
    .why-green h4 {
        font-size: 18px;
        margin: 0 0 10px;
    }
    .why-green h3 {
        font-size: 18px;
        margin: 0 0 10px;
    }
    #about-family-main {
        margin: 25px 0 0;
    }
    .family-burial-img img {
        height: 240px;
    }
    .family-detail h2 {
        font-size: 22px;
        margin: 15px 0 10px;
    }
    .family-detail p {
        font-size: 14px;
        margin: 0 0 10px;
    }
    .family-burials-main .Burials-head h2 {
            line-height: 32px;
    }
    .family-burials-main .Burials-head {
        margin: 0 0 15px;
    }
    .family-burials-main {
        margin: 20px 0 !important;
    }
    .type-family-head h2 {
        font-size: 22px;
        line-height: 32px;
        margin: 0 0 10px;
    }
    .type-family-head p {
        font-size: 14px;
        line-height: 20px;
    }
    .family-types .family-types-box .family-types-img img {
        height: 260px;
    }
    .family-types .family-types-box .types-details {
        padding: 15px;
    }
    .family-types .family-types-box .types-details h3 {
        font-size: 20px;
    }
    .family-types .family-types-box .types-details p {
        font-size: 14px;
        line-height: 20px;
    }
    .family-types .family-types-box {
        margin: 0 0 15px;
    }
    .family-why-head h2 {
        font-size: 22px;
        line-height: 32px;
    }
    .family-why-head {
        margin: 0 0 20px;
    }
    .family-full {
        padding: 15px;
        margin: 0 0 15px;
    }
    .family-half-col {
        width: 100%;
    }
    .family-half {
        padding: 15px;
        height: auto;
        margin: 0 0 15px;
    }
    .family-full h3 {
        font-size: 18px;
    }
    .family-full p {
        font-size: 14px;
        line-height: 20px;
    }
    #family-why-choose-main {
        margin: 40px 0 30px;
    }
    .memorial-option-main .family-burial-img img {
        height: 350px;
    }
    .tribute-head {
        text-align: left;
    }
    .tribute-head h2 {
        font-size: 22px;
        margin: 0 0 20px;
    }
    .tribute-head p {
        font-size: 14px;
        line-height: 20px;
    }
    .tribute-head img {
        margin: 0 0 15px;
    }
    #memorial-tribute-service {
        padding: 25px 0;
    }
    #types-family-main {
        padding: 35px 0;
    }
    .what-we-do .family-burial-img img {
        height: 240px;
    }
    .what-we-do {
        margin: 45px 0 !important;
    }
    .home-natural-burial .tribute-head a {
        width: 195px;
        height: 40px;
        margin: 15px 0 0;
        font-size: 14px;
        font-weight: normal;
    }
    .home-types-main .family-types .family-types-box  .home-second-types, 
    .family-types .family-types-box.third .types-details ,
    .home-types-main .family-types .family-types-box .home-third-types,
    .home-types-main .family-types .family-types-box .home-five-types{
        padding: 15px;
    }
    .home-about-naturals .family-detail h2 {
        margin: 15px 0;
        padding-right: 0;
    }
    .home-about-naturals .family-detail a {
        width: 160px;
        height: 40px;
        font-weight: normal;
        font-size: 14px;
    }
    .home-about-naturals .family-detail p {
        margin: 0 0 15px;
    }
    .home-plans-main .burials-process .burials-details {
        padding: 15px;
    }
    .home-plans-main .burials-process .burials-details p {
        font-size: 14px;
    }
    .home-plans-main .burials-process .burials-details h3 {
        margin: 0 0 10px;
    }
    .phome-payment-plan {
        padding: 35px 0;
        margin: 45px 0 0 !important;
    }
    .home-place-main {
        padding: 35px 0;
    }
    .home-place-main .family-detail p {
        margin: 0 0 20px;
    }
    .home-banner p {
        margin: 10px 0 0;
        font-size: 14px;
        line-height: 20px;
    }
    .home-banner-main .banner-img {
        height: 325px;
    }
    .home-banner-main .banner-text-main {
        top: 80%;
    }
    .family-contact-main {
        margin: 0 0  !important;
    }
    .contct-details .contact-social ul li {
        margin: 0 0 15px;
    }
    nav ul li .for-mobile-show {
        padding: 0;
        gap: 10px;
        border-bottom: 0;
    }
    nav ul li a.for-mobile-show.active {
        border-bottom: 0;
    }
    nav ul li .for-mobile-show img {
        width: 28px;
        margin: 0 !important;
    }
    .Cta_new_main .talk-adviser {
        padding: 20px 15px;
    }
    .Cta_new_main .talk-adviser .adviser-head h3 {
        font-size: 16px !important;
        line-height: 26px !important;
    }
    .Cta_new_main .talk-adviser .adviser-contact {
        margin: 10px 0 0;
    }
    .Cta_new_main .talk-adviser .adviser-contact h3 {
        margin: 0;
    }
    .Cta_new_main .talk-adviser .adviser-contact a {
        width: 100%;
        border-radius: 4px;
    }
    .talk-adviser .adviser-contact a img {
        width: 18px;
    }
    .cta_new {
        margin: 30px 0 0;
    }
    .cta_two .harmony-cta-main .cta {
        padding: 15px;
        gap: 15px;
    }
    .cta_two .harmony-cta-main .cta a {
        width: 100%;
        border-radius: 4px;
    }
    .cta_two .harmony-cta-main .cta .left span.right-span {
        font-size: 16px;
    }
    .cta_location {
        margin: 20px 0 0;
    }
    .legacy-container .search-legacy {
        padding: 15px;
        flex-wrap: wrap;
        row-gap: 15px;
    }
    .legacy-container .view-legacy {
        padding: 15px;
    }
    .legacy-container .view-legacy .legacy-list {
        grid-template-columns: 1fr;
        gap: 15px;
    }
    .location-page {
        min-height: auto;
    }
    .account-main
  .profile-details-main
  .profile-details
  .profile-info
  .profile-input
  .input-email {
    font-size: 14px;
}
.account-main .profile-details-main .profile-details.legacy-info {
    padding: 15px 0;
}
.account-main .profile-details-main .profile-details .profile-info .legacy-field .input-field {
    width: 50%;
}
.account-main .profile-details-main .profile-details .profile-info .legacy-field .input-field input {
    padding: 0 10px;
}
.account-main .profile-details-main .profile-details .profile-info .legacy-field .input-field.w-full textarea {
    height: 130px;
    padding: 15px;
}
.account-main .profile-details-main .profile-details .profile-info .legacy-btn {
    margin: 20px 0 0;
}
.account-main .profile-details-main .profile-details.legacy-info .profile-info {
    margin: 0 0  25px;
}
#legacy-details-main .legacy-profile-details .data {
    flex-wrap: wrap;
}
#legacy-details-main .legacy-profile-details {
    padding: 15px;
}
#legacy-details-main .legacy-profile-details .data p {
    width: 100%;
}
#legacy-details-main .legacy-profile-details .data .pic-data {
    margin: 0;
}
.comments-main .head {
    padding: 15px;
}
.comments-main .head h3 {
    font-size: 18px;
}
.comments-main .head button {
    width: 113px;
    height: 31px;
    font-size: 14px;
    border: none;
}
.comments-main .comments {
    padding: 15px;
}
.comments-main .comments .comment-box {
    padding: 10px;
    flex-wrap: wrap;
    gap: 15px;
}
.comments-main .comments .comment-box .comment-profile {
    width: 40px;
    height: 40px;
}
.comments-main .comments .comment-type .comment-user textarea {
    padding: 10px;
    font-size: 14px;
    height: 100px;
}
.comments-main .comments .comment-box .comment-user {
    width: 100%;
}
.comments-main .comments .comment-type .comment-user .submit-btn {
    font-weight: normal;
    font-size: 14px;
    bottom: 15px;
    right: 18px;
    padding: 5px;
    border: 1px solid #219653;
}
.home-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.home-btns a {
    width: auto;
    height: 45px;
    margin: 0;
    font-size: 14px;
}
.home-btns {
    margin: 0;
}
.home-natural-burial .home-btns a {
    margin: 0;
}
.banner-text .play-link {
    width: 100%;
    font-size: 14px;
}
.home-place-main {
    margin: 35px 0 0 !important;
}
.home-banner .home-btns {
    margin: 15px 0 0;
}
.schedule-btn {
    width: 167px;
    height: 40px;
    font-size: 14px;
}
.kendal-perfect-main  .family-burial-img img {
    height: 240px;
}
.Burials-head .p-spacing {
    margin: 0 0 8px;
}

.why-green-head {
    margin: 0 0 10px;
}
.meadows-green-burials-btn {
    text-align: left;
}
.meadows-green-burials-btn a {
    margin: 0;
}
.green-cta {
    margin: 0 0  40px !important;
}
.new-cta img {
    height: 280px;
}
.burials {
    margin: 0 0 15px;
}
.type-family-head {
    margin: 0 0 20px;
}
.family-half p {
    line-height: 24px;
    font-size: 14px;
}
.green-eco-burials .family-detail a {
    width: 175px;
}
.about-memorial-garden {
    margin: 40px 0  !important;
}
.bannerContainer::after {
    height: 325px;
}
.kendal-link p {
    font-size: 16px;
}
.home-plans-main .burials-process .burials-details h3 {
    min-height: auto;
}
.why-choose-new p .links {
    height: auto;
}
.credit-content {
    height: auto;
    padding: 30px 20px;
}
.credit-content h2 {
    font-size: 28px;
    line-height: 38px;
}

.credit-content p {
    font-size: 14px;
}

.credit-content a {
    padding: 14px 20px;
    margin: 0;
}

}

/* Override border-radius for iOS */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .login-main .login-field .input-field input {
        border-radius: 0;
    }
}