.brochure_pdf-container{
    display: flex;
    flex-direction: column;
    user-select: none;
    align-items: center;
    margin-top: 30px;
    position: relative;
    height: 100vh;
}
.pdf-container{
    display: flex;
    flex-direction: column;
    user-select: none;
    align-items: center;
    margin-top: 30px;
    position: relative;
}

.wrap{
    margin:5rem;
    transform: scale(1.1);
}
.back_icon{
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center;
    font-weight:"600";
    color:"black";
    margin-left: 373px;
}
.back_icon img{
    margin-right: 10px;
}
.controls{
    margin:20px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.controls button{
    padding: 1rem 2rem;
    background-color: black;
    color: white;
    border-radius: 5px;
    margin-right: 2rem;
    outline:none;
    border:none;
    box-shadow: 1px 2px 5px black;
}
.react-pdf__Page__annotations.annotationLayer{
    width: 0 !important;
    height: 0 !important;
    margin: 20px;
}
@media print {
    .pdf-container{
        display: none;
    } 
}
.pdf-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.react-pdf__Page__canvas{
    border: 1px solid ;
}
.react-pdf__Page__textContent.textLayer{
display: none ;
}


.screenshot-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 9999;
    pointer-events: none;
  }
  