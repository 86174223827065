@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  color: #333;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  object-fit: cover;
}
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

a {
  list-style: none;
  text-decoration: none;
}

ul {
  list-style: none;
}

@font-face {
  font-family: "MyFontFamily";
  src: url("../fonts/playball/playball/Playball.ttf") format("truetype");
  /* Add similar declarations for other font weights or styles */
}
.pt-6 {
  margin-top: 24px !important;
}
.signatureStyle {
  font-family: "MyFontFamily", sans-serif !important;
}
.container-two {
  width: 100%;
  /* max-width: 1282px; */
  padding: 0px 15px;
  /* margin-left: 15px; */
}

.container {
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

.bannerContainer {
  height: 576px;
  position: relative;
}
/* a {
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

a:hover {
    background-color: #59cb5b;
    color: #fff;
    border-color: #000;
} */

.row {
  margin: 0 -15px;
}

span.text-danger {
  display: block;
  color: #eb5757;
  width: 100%;
  font-size: 12px;
  margin: 5px 0 0;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 85%);
  opacity: 0;
  visibility: hidden;
  z-index: 11;
}

.overlay-cookie {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 42%);
  opacity: 0;
  visibility: hidden;
  z-index: 11;
}
.overlay-visited {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 42%);
  opacity: 2;
  /* visibility: hidden; */
  z-index: 11;
}

.overlay.buyers-main-active {
  opacity: 1;
  visibility: visible;
}

.overlay.password-active {
  opacity: 1;
  visibility: visible;
}

.overlay.logout-active {
  opacity: 1;
  visibility: visible;
}

.overlay-cookie.logout-active {
  opacity: 1;
  visibility: visible;
}

.overlay.mail-active {
  opacity: 1;
  visibility: visible;
}

.overlay.register-active {
  opacity: 1;
  visibility: visible;
}

.overlay.cart-show {
  opacity: 1;
  visibility: visible;
}

.overlay-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(246 245 245);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
}

.overlay-map-message {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.overlay.logout-active {
  opacity: 1;
  visibility: visible;
}

.overlay.alert-active {
  opacity: 1;
  visibility: visible;
}

.overlay.card-active {
  opacity: 1;
  visibility: visible;
}

.overlay.save-successful {
  opacity: 1;
  visibility: visible;
}

.for-overlay {
  display: inline-block;
  width: 100%;
  position: relative;
}

.for-overlay.cart-show {
  position: fixed;
}

.for-overlay.logout-active {
  position: fixed;
}

.for-overlay.alert-active {
  position: fixed;
}

.for-overlay.card-active {
  position: fixed;
}

.for-overlay.save-successful {
  position: fixed;
}

/*********************************** confirm logout start *******************************/
.confirm-logout.logout-active {
  opacity: 1;
  visibility: visible;
}

.confirm-logout {
  width: 340px;
  background-color: #fff;
  text-align: center;
  padding: 18px 0 38px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 22;
}

.confirm-logout span {
  font-weight: bold;
  font-size: 18px;
  margin: 12px 0 0;
  display: block;
}

.confirm-logout ul li {
  display: inline-block;
}

.confirm-logout ul li a {
  width: 80px;
  height: 36px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  color: #828282;
  font-size: 14px;
  font-weight: 600;
  background-color: #e0e0e0;
  margin: 0 0 0 10px;
}

.confirm-logout ul li a.active {
  background-color: #219653;
  color: #fff;
}

.confirm-logout p {
  font-size: 14px;
  line-height: 18px;
  margin: 12px 0 30px;
}

.confirm-logout i {
  color: #eb5757;
  display: block;
  font-size: 25px;
}

/*********************************** confirm logout end *******************************/

.password-update {
  opacity: 0;
  visibility: hidden;
}

.password-update.save-successful {
  opacity: 0 !important;
  visibility: hidden !important;
}

.password-update.save-password {
  opacity: 1 !important;
  visibility: visible !important;
}

/* Login , register , forgot-password, verify-mail, set-new-password, password-confirmation pages css start */
.register-main {
  opacity: 0 !important;
  visibility: hidden !important;
}

.register-main.register-active {
  opacity: 1 !important;
  visibility: visible !important;
  padding: 40px 40px 0;
}

.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  display: none !important;
}

.forgot-main {
  opacity: 0 !important;
  visibility: hidden !important;
}

.forgot-main.forgot-active {
  opacity: 1 !important;
  visibility: visible !important;
}

.forgot-active .login-main.login-active {
  opacity: 0;
  visibility: hidden;
}

.verify-mail-main {
  opacity: 0 !important;
  visibility: hidden !important;
}

.verify-mail-main.mail-active {
  opacity: 1 !important;
  visibility: visible !important;
}

.new-password-main {
  opacity: 0 !important;
  visibility: hidden !important;
}

.new-password-main.password-active {
  opacity: 1 !important;
  visibility: visible !important;
}

.complete-reset {
  opacity: 0 !important;
  visibility: hidden !important;
}

.complete-reset.complete-active {
  opacity: 1 !important;
  visibility: visible !important;
}

.login-main {
  width: 100%;
  max-width: 500px;
  padding: 40px 40px 58px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 11;
}

.login-main.login-active {
  opacity: 1;
  visibility: visible;
}

.login-main .logo {
  width: 100%;
  position: relative;
}

.login-main .logo span {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
}

.login-main .logo span i {
  color: #bdbdbd;
  font-size: 20px;
}

.login-main .logo .logo-link {
  display: inline-block;
}

.login-main .logo .logo-link img {
  width: 150px;
  height: 43px;
  object-fit: contain;
  vertical-align: top;
}

.login-main .login-field {
  width: 100%;
  margin: 35px 0 0;
}

.login-main .login-field h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
}

.login-main .login-field h2 img {
  margin: 0 0 0 14px;
  width: 28px;
  height: 30px;
  object-fit: contain;
}

.login-main .login-field p {
  color: #828282;
  margin: 9px 0 25px;
}

.login-main .login-field p.error {
  color: #eb5757;
  text-align: center;
  margin: 20px 0 0;
}

.login-main .login-field .input-field {
  width: 100%;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  display: -webkit-flex;
  position: relative;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  flex-wrap: wrap;
  margin: 0 0 19px;
}

.login-main .login-field .input-field .fa-eye-slash,
.login-main .login-field .input-field .fa-eye {
  position: absolute;
  top: 25px;
  /* transform: translateY(-50%); */
  right: 16px;
  color: #828282;
  font-size: 20px;
  cursor: pointer;
}

.login-main .login-field .input-field .fa-eye-slash {
  transform: unset;
  top: 15px;
}

.fa-eye-login {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  color: #828282;
}

.login-main .login-field .input-field input {
  /* border: 1px solid #E0E0E0; */
  width: 100%;
  height: 50px !important;
  padding: 0 22px;
  font-size: 16px;
}

.login-main .login-field .input-field input.dial-code-input {
  padding: 0 22px 0 15px;
  cursor: default;
  pointer-events: none;
  border: 1px solid #cacaca;
  border-right: none;
  height: 50px !important;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 15%);
}

.login-main .login-field .input-field .input-field-text {
  border: 1px solid #e0e0e0;
  width: 86%;
  height: 50px !important;
  padding: 0 22px;
  font-size: 16px;
  margin-left: 59px;
  margin-top: -50px;
}

.login-main .login-field .input-field input::placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.login-main .login-field .input-field .input-check {
  width: auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  margin: 0 0 21px;
  flex-wrap: wrap;
}

.login-main .login-field .input-field .input-check input[type="checkbox"] {
  width: 20px;
  height: 20px !important;
  accent-color: #219653;
}

.login-main .login-field .input-field .input-check label {
  color: #828282;
  margin: 0 0 0 15px;
  font-size: 15px;
}

.login-main .login-field .input-field .input-check label a {
  margin: 0;
  color: #219653;
}

.login-main .login-field .input-field .forgot {
  color: #219653;
  display: inline-block;
  margin: 0 0 21px;
  cursor: pointer;
}

.directorOptional {
  display: inline-block;
  color: gray;
  font-size: 18px;
}

.login-main .login-field .input-field .verify-otp {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin: 0 0 20px;
  width: 100%;
}

.custom-otp-input {
  width: 100%;
  height: 40px;
}

.custom-otp-input input {
  width: 100% !important;
  height: 6vh !important;
  margin-right: 0px !important;
  margin: 4px !important;
  border: 1px solid gray !important;
}

.login-main .login-btn {
  width: 100%;
  display: inline-block;
}

.login-main .login-btn .return-link,
.login-main .login-btn .reset-btn,
.login-main .login-btn .login-button {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  color: #fff;
  font-weight: bold;
  width: 100%;
  height: 50px;
  border: none;
  background-color: #219653;
  cursor: pointer;
}

.login-main .login-btn .reset-btn {
  font-size: 16px;
}

.login-main .log-footer {
  width: 100%;
  display: inline-block;
  margin: 15px 0 0;
  text-align: center;
}

.login-main .for-verify p {
  margin: 0 0 6px;
}

.login-main .log-footer p {
  color: #828282;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
}

.login-main .log-footer p button,
.login-main .log-footer p .active-link,
.login-main .log-footer p .register-login {
  color: #219653;
  margin: 0 0 0 5px;
  background: none;
  border: none;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.login-main .back-login {
  width: 100%;
  text-align: center;
  margin: 22px 0 0;
  cursor: pointer;
}
.login-main .back-login img {
  margin-right: 10px;
}

.login-main .back-login .back-login-btn {
  color: #828282;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  gap: 5px;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
}

.login-main .back-login .back-login-btn img {
  margin: 0 5px 0 0;
}

/* Login , register , forgot-password, verify-mail, set-new-password, password-confirmation pages css end */

/********************************* Home page css start *****************************/
/******************** header css start *************/
#header-main {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  padding: 18px 40px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
}

.header-logo {
  width: auto;
}

.header-logo a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
}

.header-logo a img {
  width: 150px;
  height: 43px;
  object-fit: contain;
}

nav {
  width: max-content;
}

nav ul li {
  display: inline-block;
  padding: 0 18px;
}

nav ul span {
  display: none;
}

nav ul span .fa {
  color: #828282;
  font-size: 20px;
}

nav ul li:nth-child(1) {
  padding-left: 0;
}

nav ul li:last-child {
  padding-right: 0;
}

nav ul li a {
  font-weight: 500;
  font-size: 14px;
}

nav ul li a {
  color: #333;
}

nav ul li .for-mobile-show {
  display: none;
}

nav ul li a.active {
  color: #219653;
  border-bottom: 2px solid #219653;
  padding: 0 0 8px;
}

.contect-details {
  width: max-content;
}

.contect-details ul {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
}

.contect-details ul li {
  display: inline-block;
}

.contect-details ul li .bar-btn {
  display: none;
}

.contect-details ul li a,
.contect-details ul li .user-link {
  color: #828282;
  font-weight: 500;
  font-size: 14px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  position: relative;
}

.contect-details ul li .user-link .profile-initials {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: grey;
  border-radius: 50%;
  color: #fff;
}
.profile-img-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}

.contect-details ul li a .profile-initials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
}

.contect-details ul li.cart {
  padding: 0 0 0 29px;
}

.contect-details ul li.profile-icon {
  padding: 0 0 0 36px;
}

.contect-details ul li a img {
  margin: 0 6px 0 0;
  vertical-align: text-top;
}

.contect-details ul li a.active img {
  filter: brightness(0) saturate(100%) invert(26%) sepia(95%) saturate(7499%)
    hue-rotate(109deg) brightness(101%) contrast(109%);
  width: auto;
  height: auto;
}

.contect-details ul li a.active {
  color: #219653;
}

.contect-details ul li a .notification-number {
  background-color: #eb5757;
  width: 18px;
  height: 14px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: -5px;
  right: -10px;
  display: inline-block;
  color: #fff;
  font-size: 10px;
}

.contect-details ul li a i,
.contect-details ul li .user-link {
  font-size: 18px;
  cursor: pointer;
}

.modal-content {
  position: absolute;
  background-color: white;
  padding: 70px;
  top: 30%;
  z-index: 1111;
}

/****** profile dropdown ******/
.profile-dropdown {
  width: 254px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50px;
  right: 34px;
  margin: 55px 0 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 11;
  border: 1px solid #f2f2f2;
  padding: 0 0 15px;
}

.profile-dropdown.profile-active {
  opacity: 1;
  visibility: visible;
}

.profile-dropdown .profile-info::after {
  content: "";
  position: absolute;
  top: -20px;
  right: 15px;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #f2f2f2 transparent transparent transparent;
  transform: rotate(180deg);
}

.profile-dropdown .profile-info {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 18px 0 21px;
  position: relative;
}

.profile-dropdown .profile-info h3 {
  font-weight: bold;
  font-size: 18px;
}

.profile-dropdown .profile-info span {
  font-size: 14px;
  color: #828282;
}

.profile-dropdown .profile-info img {
  width: 80px;
  height: 80px;
  background-color: #f2f2f2;
  border-radius: 50%;
  margin: 0 auto 13px auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
}

.profile-dropdown .profile-info span.profile-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.profile-dropdown .log-out {
  width: 100%;
  padding: 18px 0 0 20px;
}

.profile-dropdown .log-out ul li {
  margin: 0 0 16px;
}

.profile-dropdown .log-out ul li a {
  font-weight: 500;
  color: #4f4f4f;
}

.profile-dropdown .log-out ul li a.active {
  color: #eb5757;
}

.profile-dropdown .log-out ul li a i {
  margin: 0 11px 0 0;
}

/********************* header css end *******************/

/************************* banner css start ***************/
#banner-main {
  width: 100%;
  display: inline-block;
  position: relative;
}

.banner-img {
  width: 100%;
}

.banner-img img {
  width: 100%;
  object-fit: cover;
  vertical-align: top;
}

.banner-text-main {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.banner-text {
  width: 100%;
  max-width: 600px;
}

.banner-text h1 {
  font-size: 62px;
  font-weight: bold;
  color: #fff;
}

.banner-text .play-link {
  display: inline-block;
  width: 170px;
  height: 46px;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  line-height: 46px;
  font-weight: bold;
}

.banner-text .play-link i {
  margin: 0 7px 0 0;
}

/************************* banner css end ***************/

/****************** overview section css start ************************/
#overview-main {
  width: 100%;
  display: inline-block;
  margin: 56px 0 42px;
}

.overview-img-col {
  width: 100%;
  /* float: left; */
  padding: 0 15px;
}

.overview-img {
  width: 100%;
}

.overview-img img {
  vertical-align: top;
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.overview-text-col {
  width: 100%;
  float: left;
  padding: 0 15px;
  display: flex;
}

.overview-text {
  width: 100%;
  /* padding: 0 15px; */
  position: relative;
  margin: 46px 0 0;
}

/* .overview-text::before {
    content: "";
    position: absolute;
    bottom: -110px;
    right: -240px;
    background-image: url(../images/Leaf.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 350px;
    height: 413px;
} */

.overview-img h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 0 0 23px;
  /* max-width: 370px; */
}

.overview-text p {
  color: #4f4f4f;
  line-height: 24px;
  padding: 0 40px 0 0;
}

.overview-text .p-spacing {
  margin: 0 0 25px;
}

.overview-btn a,
.pet-burials-main .overview-text a {
  width: 158px;
  height: 46px;
  background-color: #219653;
  line-height: 46px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  color: #fff;
  font-weight: bold;
  margin: 46px 0 0;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
}

.overview-text a img {
  margin: 0 0 0 6px;
  width: 20px;
}

/****************** overview section css end ************************/

/********************* why-inspired-main section css start *********************/
#why-inspired-main {
  width: 100%;
  display: inline-block;
  position: relative;
}

#why-inspired-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 517px;
  background-image: url(../images/inspired-bg-img.png);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}

.inspired-content {
  width: 100%;
}

.inspired-content h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 54px 0 42px;
}

.inspired-content .meadow-img {
  width: 100%;
  margin: 0 0 33px;
}

.inspired-content .meadow-img img {
  width: 100%;
}

.inspired-content p {
  color: #4f4f4f;
  line-height: 24px;
}

.inspired-content .p-spacing {
  margin: 0 0 30px;
}

.inspired-content a {
  width: 164px;
  height: 46px;
  border: 1px solid #219653;
  display: inline-block;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  line-height: 46px;
  color: #219653;
  font-weight: bold;
  margin: 33px 0 0;
}

.inspired-content a img {
  margin: 0 0 0 6px;
}

/********************* why-inspired-main section css end *********************/

/********************** purchase section css start **********************/
#purchase-main {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin: 73px 0 160px;
}

.purchase-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.purchase-head {
  width: 100%;
}

.purchase-head h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 0 0 33px;
}

.purchase-product {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  gap: 13px;
}

.purchase-col {
  width: 20%;
}

.urn-text {
  width: 20%;
}

.purchase-content {
  width: 100%;
  position: relative;
}

.purchase-content img {
  width: 100%;
  vertical-align: top;
  height: 300px;
}

.purchase-content .purchase-links {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  padding: 0 14px 0 19px;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  position: absolute;
  bottom: 10px;
  left: 0;
}

.purchase-content .purchase-links span {
  font-weight: bold;
  font-size: 17px;
  color: #fff;
}

.urn-text .purchase-content .purchase-links span {
  color: #fff;
}

.urn-text .purchase-content {
  background-color: #33333352;
}

.urn-text .purchase-content img {
  object-fit: contain;
}

.purchase-content .purchase-links a {
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
}

.purchase-content .purchase-links a img {
  width: auto;
  height: auto;
}

/********************** purchase section css end **********************/

/*********************** Adviser section css start ********************/
#Adviser-main {
  width: 100%;
  display: inline-block;
  position: relative;
}

.adviser-bg-img {
  width: 100%;
}

.adviser-bg-img img {
  width: 100%;
  height: 499px;
  object-fit: cover;
  vertical-align: top;
}

.future-planing {
  width: 100%;
  position: absolute;
  top: -71px;
  left: 0;
}

.overview-future-planing {
  position: static;
}

.overview-future-planing .talk-adviser::before {
  display: none;
}

.talk-adviser-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.talk-adviser {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  background-color: #219653;
  padding: 24px 62px 33px 62px;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  margin: 0 0 60px;
  position: relative;
}

.talk-adviser::before {
  content: "";
  position: absolute;
  top: -420px;
  right: -240px;
  background-image: url(../images/bg-leaf.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 571px;
  height: 675px;
  z-index: -1;
}

.talk-adviser .msg-img {
  width: 10%;
}

.talk-adviser .adviser-head {
  width: 75%;
  padding: 0px 0 0 57px;
  max-width: none;
}

.talk-adviser .adviser-head h3 {
  font-weight: bold;
  font-size: 30px;
  margin: 0 0 9px;
  color: #fff;
}

.talk-adviser .adviser-head p {
  color: #fff;
  line-height: 24px;
}

.talk-adviser .adviser-contact {
  width: 35%;
  text-align: center;
}

.talk-adviser .adviser-contact a {
  width: 188px;
  height: 46px;
  border: 1px solid #fff;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  font-weight: bold;
  color: #fff;
  margin-left: auto;
}

.talk-adviser .adviser-contact a img {
  margin: 0 0 0 7px;
}

.adviser-plans-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.adviser-plans {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  background-color: #fff;
}

.adviser-plans .adviser-content {
  width: max-content;
  padding: 0 32px 0 43px;
}

.adviser-plans .adviser-content h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 49px 0 13px;
}

.adviser-plans .adviser-content p {
  line-height: 24px;
  margin: 0 0 34px;
}

.adviser-plans .adviser-content a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;

  width: 164px;
  height: 46px;
  border: 1px solid #219653;
  font-weight: bold;
  color: #219653;
}

.adviser-plans .adviser-content a img {
  margin: 0 0 0 7px;
}

.adviser-plans .adviser-img {
  width: max-content;
}

.adviser-plans .adviser-img img {
  vertical-align: top;
  width: 208px;
  height: 324px;
  object-fit: cover;
}

/*********************** Adviser section css end ********************/

/******************** team section css start *******************/
#team-main {
  width: 100%;
  display: inline-block;
  margin: 100px 0;
  vertical-align: top;
}

.purchase-for-mobile {
  display: none;
}

.team-content-col {
  width: 45%;
  float: left;
  padding: 0 15px;
}

.team-content {
  width: 100%;
}

.team-content h2 {
  font-weight: bold;
  font-size: 40px;
}

.team-content p {
  color: #4f4f4f;
  font-size: 16px;
  line-height: 24px;
  margin: 7px 0 42px;
  padding: 0 20px 0 0;
}

.team-content a,
.contact-head a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  width: 194px;
  height: 46px;
  background-color: #219653;
  color: #fff;
  font-weight: bold;
}

.team-content a img {
  margin: 0 0 0 10px;
}

.team-profile-col {
  width: 55%;
  float: right;
  padding: 0 15px;
}

.team-profile {
  width: 100%;
}

.team-profile ul {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  justify-content: right;
  gap: 30px;
}

.team-profile ul li img {
  width: 140px;
  height: 165px;
  object-fit: cover;
  vertical-align: top;
}

/******************** team section css end *******************/

/****************** footer css start ********************/
#footer-main {
  width: 100%;
  display: inline-block;
  background-color: #00322d;
}

#footer-main h2 {
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}

.footer-top {
  width: 100%;
  display: inline-block;
  padding: 38px 0 40px;
}

.footer-logo-col {
  width: 39%;
  float: left;
  padding: 0 15px;
}

.footer-help {
  padding: 0 0 0 120px !important;
}

.footer-logo {
  width: 100%;
  margin: 0 0 10px;
}

.iccm-logo {
  margin: 15px 0 0;
}

.iccm-logo span {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 12px;
}
.company-details {
  width: 100%;
}
.company-details ul li {
  display: flex;
  gap: 20px;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  margin: 0 0;
  line-height: 22px;
}
.company-details ul li label {
  width: max-content;
}
.company-details ul li a {
  color: #fff;
}

.footer-logo .logo-link {
  display: inline-block;
}

.social-links ul {
  display: flex;
  align-items: center;
  margin: 25px 0 0;
}

.social-links h2 {
  margin: 0 0 17px;
}

.social-links ul li {
  display: inline-block;
  padding: 0 15px;
}

.social-links ul li:nth-child(1) {
  padding-left: 0;
}

.social-links ul li a {
  color: #fff;
}

.social-links ul li a i {
  font-size: 18px;
}

.footer-links-col {
  width: 20%;
  float: left;
  padding: 0 15px;
}

.footer-contact-col {
  width: 20%;
}

.footer-links {
  width: 100%;
  padding: 0 0 0 45px;
}

.footer-links h2 {
  margin: 0 0 21px;
}

.footer-links ul li {
  position: relative;
}

.footer-links ul li .document-btn,
.footer-links ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  line-height: 40px;
  transition: all 0.5s;
}

.footer-links ul li .document-btn:hover + .documents-dropdown,
.documents-dropdown:hover {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
  height: max-content;
  /* height: 192px; */
}

.footer-links ul li .document-btn,
.footer-links ul li a {
  /* padding: 20px 0; */
  display: inline-block;
}
.footer-links ul li a {
  padding: 0;
}

.footer-links ul li .document-btn img,
.footer-links ul li a img {
  margin: 0 0 0 10px;
  transition: all 0.5s;
}

.footer-links ul li .document-btn:hover img,
.footer-links ul li a:hover img {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.footer-bottom {
  width: 100%;
  display: inline-block;
}

.footer-bottom-content {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  border-top: 1px solid #fff;
  padding: 28px 0 39px;
}

.footer-bottom-content .footer-copyright {
  width: auto;
}

.footer-bottom-content .footer-copyright p {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}

.footer-bottom-content .footer-term {
  width: auto;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
}

.footer-bottom-content .footer-term span {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin: 0 0 0 13px;
}

.footer-bottom-content .footer-term .circle {
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
}

/****************** footer css end ********************/
/********************************* Home page css end *****************************/

/********************************* About page css start *****************************/
#banner-img-main {
  width: 100%;
  display: inline-block;
  position: relative;
}
#banner-img-main .banner-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 30%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* display: none; */
}

.custom-banner-img {
  width: 100%;
}

.custom-banner-img img {
  width: 100%;
  height: 258px;
  object-fit: cover;
  vertical-align: top;
}

.custom-banner-text {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
}

.custom-banner-text h1 {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
}

/**************** our mission css start *************/
#our-mission {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin: 66px 0 89px;
}

.mission-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.mission-head {
  width: 100%;
  margin: 0 0 66px;
}

.mission-head p {
  line-height: 24px;
  color: #4f4f44;
}

.mission-details-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.mission-details {
  width: 100%;
}

.mission-details img {
  width: 100%;
  vertical-align: top;
  object-fit: cover;
}

.mission-details h2 {
  font-size: 34px;
  font-weight: bold;
  margin: 22px 0 21px;
}

.mission-details p {
  line-height: 24px;
  color: #4f4f4f;
}

.mission-details .p-spacing {
  margin: 0 0 30px;
}

/**************** our mission css end *************/

/*************** core main css start  ******************/
#our-core-main {
  width: 100%;
  display: inline-block;
  position: relative;
}

#our-core-main span {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
  display: block;
}

.core-bg-img {
  width: 100%;
  display: inline-block;
}

.core-bg-img img {
  width: 100%;
  height: 812px;
  vertical-align: top;
}

.core-content {
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
}

.core-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.core-head {
  width: 100%;
}

.core-head h2 {
  font-weight: bold;
  font-size: 34px;
}

.core-head p {
  color: #4f4f4f;
  line-height: 24px;
  margin: 17px 0 30px;
}

.core-details-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.core-details {
  width: 100%;
  display: inline-block;
  min-height: 270px;
  background-color: #fff;
  padding: 24px 29px 34px 38px;
  margin: 0 0 26px;
  position: relative;
}

.core-details::after,
.core-achieve::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 125px;
  height: 147px;
  background-image: url(../images/core_leaf.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.core-details span {
  margin: 20px 0 9px;
}

.core-details p {
  line-height: 24px;
  color: #4f4f4f;
}

.core-achieve-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.core-achieve {
  width: 100%;
  display: inline-block;
  padding: 28px 47px 48px 38px;
  background-color: #fff;
  position: relative;
  min-height: 270px;
}

.core-achieve span {
  margin: 18px 0 9px;
}

.core-achieve p {
  line-height: 24px;
  color: #4f4f4f;
}

.core-footer-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.core-footer {
  width: 100%;
}

.core-footer p {
  line-height: 24px;
  color: #4f4f4f;
  margin: 30px 0 0;
}

/*************** core main css end  ******************/

/************** Inspired commit css start **********/
#Inspired-commitment {
  width: 100%;
  display: inline-block;
  margin: 71px 0 80px;
  vertical-align: top;
}

.commit-header-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.commit-header {
  width: 100%;
  margin: 0 0 49px;
}

.commit-header h2 {
  font-size: 34px;
  font-weight: bold;
  line-height: 44px;
  margin: 0 0 22px;
}

.commit-header p {
  line-height: 24px;
  color: #4f4f4f;
}

.commit-header .p-spacing {
  margin: 0 0 25px;
}

.commit-details-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.commit-details {
  width: 100%;
}

.commit-details .commit-tab {
  width: 100%;
  max-width: 481px;
}

.commit-details .commit-tab span {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #333;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 12px;
  margin: 0 0 20px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.commit-details .commit-tab span.active-tab {
  margin: 0 0 13px;
}

.commit-details .commit-tab span.active-tab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 1px;
  background-color: #219653;
}

.commit-details .commit-tab span.active-tab + p {
  display: block;
}

.commit-details .commit-tab p {
  display: none;
  line-height: 24px;
  color: #4f4f4f;
  margin: 0 0 34px;
}

.commit-img-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.commit-img {
  width: 100%;
}

.commit-img img {
  width: 100%;
  vertical-align: top;
}

.commit-footer-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.commit-footer p {
  line-height: 24px;
  color: #4f4f4f;
  margin: 54px 0 0;
}

/************** Inspired commit css start **********/
/********************************* About page css end *****************************/

/******************************** Loaction page css stat ****************************/
.location-mobile {
  display: none;
  width: 100%;
}

#location-main {
  width: 100%;
  display: inline-block;
  margin: 75px 0 70px;
  vertical-align: top;
}

.location-details-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.location-details {
  width: 100%;
  min-height: 400px;
  display: inline-block;
  background-color: #fbfaf8;
  margin: 0 0 30px;
}

.location-details img {
  width: 100%;
  vertical-align: top;
  height: 230px;
}

.location-details .location-text {
  width: 100%;
  display: inline-block;
  padding: 16px 19px 29px 19px;
}

.location-details .location-text span {
  display: block;
  font-size: 20px;
  font-weight: 600;
}

.location-details .location-text p {
  line-height: 24px;
  color: #828282;
  margin: 9px 0 15px;
}

.location-details .location-text a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  color: #219653;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
}

.location-details .location-text a img {
  width: auto;
  height: auto;
  margin: 0 0 0 4px;
}

/***************** location details page css  *******/
#location-details-main {
  width: 100%;
  display: inline-block;
  margin: 29px 0 0;
  vertical-align: top;
}

#location-details-main p {
  line-height: 24px;
  color: #4f4f4f;
  font-size: 16px;
}

#location-details-main h3 {
  font-weight: bold;
  font-size: 24px;
  color: #000;
}

.back-btn-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.back-btn {
  width: 100%;
  margin: 0 0 71px;
}

.back-btn a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  font-weight: bold;
  color: #333;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  width: max-content;
}

.back-btn a img {
  margin: 0 12px 0 0;
}

.details-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.details-head {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  margin: 0 0 24px;
}

.details-head .head-text h2 {
  font-size: 34px;
  color: #333;
  font-weight: bold;
}

.details-head .details-btn a {
  width: 136px;
  height: 46px;
  background-color: #f2f2f2;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  font-weight: bold;
  color: #4f4f4f;
  margin: 0 0 0 10px;
}

.details-head .details-btn a.active {
  background-color: #219653;
  color: #fff;
}

.details-view-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.details-img-view {
  width: 100%;
}

.details-img-view p {
  font-weight: bold;
  font-size: 40px !important;
  line-height: 49px;
}

.details-img-view img {
  width: 100%;
  vertical-align: top;
  height: 60vh;
  /* margin-left: 142px; */
}

.details-img-view .p-spacing {
  margin: 31px 0 0;
}

.details-img-view p {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px !important;
  text-align: center;
  margin: 68px 0;
}

.details-img-view .p-mr {
  margin: 0 0 28px;
}

.area-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.area-text {
  width: 100%;
  margin: 52px 0 72px;
}

.area-access {
  margin: 0 0 55px;
}

.area-text h3 {
  margin: 0 0 30px;
}

.area-text .area-field {
  width: 100%;
  margin: 0 0 21px;
}

.area-text .area-field.p-spacing {
  margin: 0;
}

.area-text .area-field span {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin: 0 0 5px;
}

.area-text .area-field p {
  line-height: 24px;
  color: #4f4f4f;
}

.area-text .area-field p.p-spacing {
  margin: 19px 0 0;
}

.amenities-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.amenities {
  width: 100%;
}

.amenities-head {
  width: 100%;
}

.amenities-head h3 {
  margin: 0 0 39px;
}

.amenities .amenities-details {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
}

.amenities .amenities-details .places {
  width: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  margin: 0 0 40px;
}

.amenities .amenities-details .places .place-img {
  margin: 0 17px 0 0;
}

.amenities .amenities-details .places .place-img span {
  width: 53px;
  height: 53px;
  background-color: #f2f2f2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  border-radius: 50%;
  text-align: center;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
}

.amenities .amenities-details .places .place-content span {
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin: 0 0 5px;
  display: inline-block;
}

.get-info-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.get-info {
  /* width: 80%; */
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  background-color: #219653;
  padding: 32px 0 45px 49px;
  margin: 35px 0px 45px;
}

.get-info .get-head {
  width: 50%;
  display: flex;
  gap: 0;
  align-items: center;
  padding: 0 30px 0 0;
  justify-content: space-evenly;
}

.get-info .get-head .get-left {
  width: calc(100% - 180px);
}

.get-info .get-head .get-link {
  width: 180px;
  height: 46px;
  border: 1px solid #fff;
  /* margin: 14px 0 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.get-info .get-head a {
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

.get-info .get-head .get-link img {
  margin: 0 0 0 9px;
}

.get-info .get-head span {
  display: block;
  font-weight: bold;
  font-size: 22px;
  color: #fff;
}

.get-info .get-head p {
  font-weight: 500;
  color: #fff !important;
}

.get-info .get-contact {
  width: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
  padding: 0 0 0 35px;

  border-left: 1px dotted #e0e0e0;
}

.get-info .get-contact ul li {
  display: inline-block;
  padding: 0 35px 0 0;
}

.get-info .get-contact ul li a {
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

.get-info .get-contact ul li:last-child {
  padding-left: 0;
}

.get-info .get-contact ul li .get-contact-link {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  color: #fff;
  font-weight: 500;
}

.get-info .get-contact ul li .get-contact-link img {
  margin: 0 13px 0 0;
}

/******************************** Loaction page css end ****************************/

/********************************* Blog pages css start *****************************/
#blog-main {
  width: 100%;
  display: inline-block;
  margin: 75px 0 43px;
}

.blog-mobile {
  width: 100%;
  display: none;
}

#blog-main .slick-dots li.slick-active {
  background-color: #219653;
  border-radius: 10px;
  width: 30px;
  transition: all 0.5s;
}

#blog-main .slick-dots li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #828282;
  transition: all 0.5s;
}

#blog-main .slick-dots li button::before {
  font-size: 0;
}

#blog-main .slick-dotted.slick-slider {
  margin-bottom: 0;
}

#blog-main .slick-dots {
  bottom: 0;
}

.blogs-col {
  width: 31.33%;
  float: left;
  padding: 0 15px;
}

.blogs {
  width: 100%;
  margin: 0 0 57px;
  height: 310px;
}

.blogs img {
  width: 100%;
  vertical-align: top;
  height: 180px;
}

.blogs span {
  color: #828282;
  font-size: 14px;
  margin: 11px 0 0;
  display: block;
}

.blogs p {
  margin: 12px 0 17px;
  font-weight: bold;
  font-size: 20px;
}

.blogs a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  color: #219653;
}

.blogs a img {
  width: auto;
  height: auto;
  margin: 0 0 0 3px;
}

#Blog-details {
  width: 100%;
  display: inline-block;
  margin: 27px 0 105px;
}

.Blog-details-head {
  width: 100%;
}

.Blog-details-head a,
span {
  display: block;
}

.Blog-details-head a {
  font-weight: bold;
  color: #333;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  width: max-content;
}

.Blog-details-head a img {
  width: auto;
  height: auto;
  margin: 0 12px 0 0;
}

.Blog-details-head span {
  color: #828282;
  margin: 54px 0 18px;
}

.Blog-details-head h2 {
  font-weight: bold;
  font-size: 34px;
  color: #000;
  margin: 0 0 27px;
}

.Blog-details-head img {
  width: 100%;
  vertical-align: top;
}

.Blog-details-head p {
  line-height: 24px;
  color: #4f4f4f;
  margin: 21px 0 0;
}

.key-point {
  width: 100%;
  margin: 45px 0 48px;
}

.key-point h1 {
  font-size: 24px;
  color: #000;
  font-weight: bold;
  margin: 0 0 28px;
}

.key-point .key-details {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 0 69px;
}

.key-point .key-details.p-spacing {
  margin: 0 0 90px;
}

.key-point .key-details .key-img {
  width: 26%;
  height: 232px;
}

.key-point .key-details .key-img img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.key-point .key-details .key-text {
  padding: 0 0 0 30px;
  width: 70%;
}

.key-point .key-details .key-text-second {
  /* padding: 0 0 0 30px; */
  width: 100%;
}

.key-point .key-details .key-text p {
  color: #4f4f4f;
  line-height: 24px;
}

.key-point .key-details .key-text-second p {
  color: #4f4f4f;
  line-height: 24px;
}

.key-point .key-details .key-text p.p-spacing {
  margin: 0 0 20px;
}

.key-point .key-details .revert-img {
  order: 2;
}

.key-point .key-details .revert-text {
  order: 1;
  padding: 0 30px 0 0;
  text-align: left;
}

.conclusion {
  width: 100%;
  display: inline-block;
}

.conclusion p {
  line-height: 24px;
  color: #4f4f4f;
}

/********************************* Blog pages css end *****************************/

/**************************** Schedule page css start *****************************/
#Schedule-main {
  width: 100%;
  display: inline-block;
  margin: 72px 0 100px;
}

.schedule {
  width: 100%;
  display: inline-block;
  border: 1px solid #f2f2f2;
  box-shadow: 0 3px 4px rgb(51 51 51 / 5%);
  padding: 0 0 47px;
}

.Schedule-head-col {
  width: 100%;
  float: left;
  padding: 0 22px;
}

.Schedule-head {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  background-color: #fbfaf8;
  padding: 32px 54px;
  position: relative;
}

.Schedule-head h1 {
  font-weight: 600;
  font-size: 30px;
  color: #000;
}

.Schedule-head .powered {
  width: 137px;
  background-color: #fff;
  text-align: center;
  padding: 6px 0 8px;
  /* position: absolute;
    right: 0px;
    top: 0; */
  border: 1px solid #f2f2f2;
}

.Schedule-head .powered span {
  font-weight: 500;
  font-size: 14px;
}

.Schedule-head .powered img {
  filter: grayscale(100%);
}

.select-col {
  width: 100%;
  float: left;
  padding: 0 22px;
}

.select-agent {
  width: 100%;
  padding: 0 0 0 54px;
}

.select-agent h3 {
  font-weight: 600;
  font-size: 22px;
  margin: 26px 0 28px;
}

.schedule-row {
  margin: 0 -22px;
}

.agents-col {
  width: 33.33%;
  float: left;
  padding: 0 22px;
}

.schedule-agent-col {
  width: 100%;
  float: left;
  padding: 0 22px;
}

.schedule-agent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.schedule-agent .schedule-agent-left {
  width: 100%;
  border-right: 1px solid #f2f2f2;
  /* box-shadow: 0 4px 3px rgba(0, 0, 0, 5%); */
  padding: 34px 55px 0 55px;
}

.schedule-agent .schedule-agent-left .left-full {
  width: 100%;
  display: flex;
  gap: 23px;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 26px;
}

.schedule-agent .schedule-agent-left .schedule-agent-profile img {
  width: 150px;
  height: 150px;
}

.schedule-agent .schedule-agent-left h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px;
}

.calendly-inline-widget {
  min-width: 320px !important;
  height: 100vh !important;
}

.schedule-agent .schedule-agent-left p {
  color: #828282;
}

.schedule-agent .schedule-agent-left p.collapsed {
  top: 100%;
  transition: all 5s;
}

.schedule-agent .schedule-agent-right {
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
  padding: 26px 0 0 16px;
}

.calendly-details-main {
  width: 100%;
  display: inline-block;
  border-top: 1px solid #f2f2f2;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 5%);
}

.schedule-agent .schedule-agent-right h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 30px;
}

.schedule.pb {
  padding: 0;
}

/* ._cUP1np9gMvFQrcPftuf.xahN8AEzyAvQtVj17TPv {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
} */

.agents {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  gap: 19px;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: top;

  border: 1px solid #f2f2f2;
  padding: 30px 30px 30px 30px;
  margin: 0 0 26px;
  cursor: pointer;
  position: relative;
  /* min-height: 170px; */
}

.agents.agent-active i {
  opacity: 1;
  visibility: visible;
}

.agents input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
  cursor: pointer;
}

.agents img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.agents i {
  position: absolute;
  color: #219653;
  top: 20px;
  right: 20px;
  font-size: 20px;
  opacity: 0;
  visibility: hidden;
}

.agents.agent-active {
  border: 1px solid #219653;
}

.agents .agent-profile {
  width: 54px;
}

.agents .agent-bio {
  width: calc(100% - 54px);
  position: relative;
}

.agents .agent-bio h2 {
  font-weight: 600;
  font-size: 20px;
  display: inline-block;
}

.agents .agent-bio span {
  font-size: 12px;
  color: #828282;
  font-weight: 500;
  display: block;
  margin: 5px 0;
}

.agents .agent-bio p {
  font-size: 10px;
  color: #828282;
  appearance: none;
  /* white-space: pre; */
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.main-agents {
  width: 100%;
  padding: 0 54px;
}

.view-more-agent-col {
  width: 100%;
  float: left;
  padding: 0 15px;
  display: none;
}

.view-more-agent {
  width: 100%;
  margin: 0 0 15px;
}

.view-more-agent button {
  background: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  justify-content: right;
  width: 100%;
  border: none;
  font-size: 14px;
  color: #219653;
  font-weight: 600;
  border-bottom: 2px solid #219623;
  padding: 0 0 5px;
  max-width: max-content;
  margin-left: auto;
}

.next-btn-col,
.back-schduleBtn-col {
  width: 100%;
  float: left;
  padding: 0 22px;
}

.next-btn,
.back-schduleBtn {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  justify-content: right;
}

p.error {
  color: #eb5757;
  text-align: center;
}

/* .next-btn a.agent-active {
    background-color: #219653;
    color: #fff;
} */

.next-btn a,
.back-schduleBtn span {
  width: 120px;
  height: 50px;
  color: #bdbdbd;
  background-color: #f2f2f2;
  font-weight: bold;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  cursor: pointer;
  /* Safari */
  -webkit-justify-content: center;
  /* Microsoft Edge */
  -ms-justify-content: center;
  /* Opera */
  -o-justify-content: center;
  /* Standard */
  justify-content: center;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  margin: 25px 0 0;
}

.back-schduleBtn span {
  color: #fdfdfd;
  background-color: #219653;
}

/**************************** Schedule page css start *****************************/

/*************************** contact page css start  **************************/
#contact-main {
  width: 100%;
  display: inline-block;
  margin: 110px 0 40px;
}

.contact-details-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.contct-details {
  width: 100%;
  max-width: 500px;
}

.contct-details .contact-head {
  width: 100%;
}

.contct-details .contact-head h2 {
  color: #000;
  font-weight: 600;
  font-size: 34px;
}

.contct-details .contact-head p {
  line-height: 26px;
  font-size: 20px;
  color: #000;
  font-weight: 300;
  margin: 11px 0 0;
}

.contact-head a {
  margin: 20px 0 34px;
}

.contct-details .contact-social {
  width: 100%;
  display: inline-block;
}

.contct-details .contact-social ul li {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  padding: 0 0;
  /* border-bottom: 1px solid #f2f2f2; */
  margin: 0 0 26px;
}

.contct-details .contact-social ul:last-child li {
  border-bottom: none;
}

.contct-details .contact-social ul li span {
  margin: 0 38px 0 0;
  width: 50px;
}

.contct-details .contact-social ul li .social-left {
  width: 100%;
}

.contct-details .contact-social ul li .social-left label {
  width: 100%;
  display: block;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  color: #000;
}

.contct-details .contact-social ul li .social-left a,
.contct-details .contact-social ul li .social-left p {
  font-size: 20px;
  font-weight: 300;
  color: #000;
  line-height: 30px;
}

.contact-form-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.contact-form {
  width: 100%;
}

.contact-form form {
  width: 100%;
  background-color: #fbfaf8;
  padding: 31px 40px 30px;
  height: 734px;
  /* border-bottom: 3px solid #219653; */
}

.contact-form form h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin: 0 0 26px;
}

.contact-form form .input-group {
  width: 100%;
  margin: 0 0 20px;
}

.contact-form form .input-group .textarea {
  height: 155px;
  padding-bottom: 46px;
}

.contact-form form .input-group input {
  width: 100%;
  height: 54px;
  border: 1px solid #f2f2f2;
  padding: 0 23px;
  font-size: 16px;
}

.contact-form form .input-group button {
  font-weight: 800;
  color: #fff;
  background-color: #219653;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  border: none;
  height: 67px;
  /* padding: 16px; */
}

.contact-form form .input-group input::placeholder,
.contact-form form .input-group textarea {
  color: #828282;
  font-size: 16px;
}

.contact-form form .input-group textarea {
  width: 100%;
  padding: 11px 0 0 23px;
  height: 100px;
  border: 1px solid #f2f2f2;
}

/*************************** contact page css end  **************************/

/********************** Faq page css start *******************************/
#Faq-main {
  width: 100%;
  display: inline-block;
  margin: 62px 0 100px;
}

.faq-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.faq-mobile {
  display: none;
}

.faq {
  width: 100%;
}

.faq h2 {
  font-weight: bold;
  font-size: 24px;
  margin: 0 0 38px;
}

.faq ul li {
  border: 1px solid #e0e0e0;
  padding: 33px 31px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  margin: 0 0 20px;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  flex-wrap: wrap;
  position: relative;
}

.faq ul li .faq-content {
  width: 100%;
  display: none;
  /* Safari */
  -webkit-justify-content: left;

  /* Microsoft Edge */
  -ms-justify-content: left;

  /* Opera */
  -o-justify-content: left;

  /* Standard */
  justify-content: left;

  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  margin: 13px 0 0;
}

.faq ul li .faq-content p {
  color: #4f4f4f;
  line-height: 24px;
  width: 60%;
  padding-right: 66px;
}

.faq ul li .faq-content .faq-video {
  width: 36%;
  position: relative;
  text-align: center;
  padding-right: 66px;
}

.faq ul li .faq-content .faq-video img {
  vertical-align: top;
  width: 261px;
  height: 147px;
}

.faq ul li .faq-content .faq-video .faq-play-btn {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.faq ul li .faq-content .faq-video .faq-play-btn i {
  color: #fff;
  font-size: 25px;
}

.faq ul li .faq-content .faq-video .faq-play-btn span {
  color: #fff;
  font-weight: bold;
  line-height: 24px;
  font-size: 16px;
}

.faq ul li .plus-btn {
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}

.faq ul li .plus-btn .fa-minus-circle {
  display: none;
}

.faq ul li h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  width: calc(100% - 20px);
}

.faq ul li.open-accordion {
  background-color: #fbfaf8;
}

.faq ul li .plus-btn {
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  width: auto;
}

.faq ul li .plus-btn .fa-plus-circle {
  color: #828282;
}

.faq ul li.faq-active .plus-btn .fa-plus-circle {
  display: none;
}

.faq ul li.faq-active .plus-btn .fa-minus-circle {
  display: inline-block;
  color: #219653;
}

.faq ul li .faq-content {
  width: 100%;
  display: none;
  margin: 10px 0 0;
}

.faq ul li.faq-active .faq-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
}

.faq ul li .faq-content p {
  color: #4f4f4f;
  line-height: 24px;
  width: 60%;
}

/* .faq ul li .faq-content .faq-video {
    width: 40%;
    position: relative;
    text-align: center;
} */

.faq ul li .faq-content .faq-video .faq-play-btn {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.faq ul li .faq-content .faq-video .faq-play-btn i {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

.faq ul li .faq-content .faq-video .faq-play-btn span {
  color: #fff;
  font-weight: bold;
  line-height: 24px;
}

/********************** Faq page css start *******************************/

/*************************** natural burials pages css start ********************/
#Burials-main {
  width: 100%;
  display: inline-block;
  margin: 68px 0 90px;
}

.burials-mobile {
  display: none;
  width: 100%;
}

.burials-slider {
  width: 100%;
  display: inline-block;
}

#Burials-main .slick-dots li.slick-active {
  width: 44px;
  background-color: #219653;
  transition: all 0.5s;
  border-radius: 10px;
}

#Burials-main .slick-dots li button::before {
  font-size: 0;
}

#Burials-main .slick-dots li {
  width: 14px;
  transition: all 0.5s;
  height: 14px;
  border-radius: 50%;
  background-color: #828282;
}

#Burials-main .slick-dotted.slick-slider {
  margin-bottom: 0;
}

#Burials-main .slick-dots {
  bottom: -10px;
}

#Burials-main h2 {
  font-weight: bold;
  font-size: 34px;
}

#Burials-main p {
  line-height: 24px;
  color: #4f4f4f;
}

.Burials-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.Burials-head {
  width: 100%;
  margin: 0 0 41px;
}

.Burials-head h2 {
  margin: 0 0 12px;
}

.Burials-head .p-spacing {
  margin: 0 0 16px;
}

.burials-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.burials {
  width: 100%;
  background-color: #fbfaf8;
  padding: 24px 38px 62px 38px;
  height: 340px;
  margin: 0 0 26px;
  position: relative;
}

.burials::after {
  content: "";
  position: absolute;
  bottom: 29px;
  right: 0;
  background-image: url(../images/core_leaf.png);
  width: 125px;
  height: 148px;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 11;
}

.burials h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  display: block;
  margin: 20px 0 9px;
}

.burials-half-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.burials-half {
  width: 100%;
  background-color: #fbfaf8;
  padding: 28px 38px 50px;
  height: 290px;
  position: relative;
}

.burials-half::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 5px;
  background-image: url(../images/core_leaf.png);
  width: 125px;
  height: 148px;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 11;
}

.burials-half h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  display: block;
  margin: 18px 0 9px;
}

.natural-footer-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.natural-footer {
  width: 100%;
  margin: 26px 0 0;
}

#Burials-process-main {
  width: 100%;
  display: inline-block;
  padding: 68px 0 71px;
  position: relative;
  /* z-index: 1; */
}

#Burials-process-main .slide {
  width: 100%;
  display: inline-block;
}

#Burials-process-main .slick-dots {
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
}

#Burials-process-main .slick-dots .slick-active {
  width: 35px;
  height: 10px;
  background-color: #219653;
  border-radius: 10px;
  transition: 0.3s;
}

#Burials-process-main .slick-dots li button:before {
  font-size: 0;
}

#Burials-process-main .slick-dots li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #828282;
  transition: 0.3s;
}

#Burials-process-main .slick-prev {
  top: unset;
  left: unset;
  bottom: -35px;
  right: 50px;
}

#Burials-process-main .slick-next {
  top: unset;
  bottom: -35px;
  right: 15px;
}

#Burials-process-main .slick-next:before {
  color: green;
  font-size: 40px;
  font-family: monospace;
}

#Burials-process-main .slick-prev::before {
  font-size: 40px;
  color: #bdbdbd;
  font-family: monospace;
}

#Burials-process-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/natural-process-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

#Burials-process-main h2 {
  font-weight: bold;
  font-size: 34px;
}

#Burials-process-main p {
  line-height: 24px;
  color: #4f4f4f;
}

.process-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.process-head {
  width: 100%;
}

.process-head p {
  margin: 16px 0 31px;
}

.process-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.process-col-lst {
  width: 33.33%;
  float: left;
  padding: 0 15px;
  display: none;
}

.process {
  width: 100%;
  background-color: #fff;
  height: 540px;
  position: relative;
}

.process-lst {
  display: none;
}

.process img {
  width: 100%;
  height: 217px;
}

.process .process-content {
  width: 100%;
  padding: 26px 32px 81px;
}

.text-content,
.text-content1 {
  width: 100%;
  display: flex;
  justify-content: right;
  padding: 15px;
  position: absolute;
  bottom: 0;
  /* margin-top: -12px; */
}

.text-content .btn,
.text-content1 .btn {
  width: 124px;
  height: 42px;
  background-color: #219653;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .text-content1{
    width: 124px;
    height: 42px;
    background-color: #219653;
    display: flex;
    color: #fff;
    font-weight: bold;
    margin-left: 33px;
    justify-content: center;
    padding: 10px;
    margin-left: 221px;
    margin-top: -59px;
} */
.documents-dropdown {
  width: 391px;
  background-color: #0095a8;
  border-radius: 10px;
  padding: 10px 0 10px 0;
  /* margin-left: 57%;
    margin-top: -256px; */
  position: absolute;
  /* top: 35px; */
  left: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  height: 0%;
}

.documents-dropdown ul li {
  line-height: 30px;
  color: #fff;
  font-size: 14px;
  position: relative;
  padding: 0 20px;
}

.documents-dropdown ul li:hover {
  color: #333;
}

.documents-dropdown ul li span {
  line-height: 20px;
  display: inline-block;
}

.documents-dropdown ul li span:hover {
  color: #333 !important;
}

.documents-dropdown ul li.lh {
  line-height: 25px;
}

.pdf-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pdf-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.process .process-content-lst {
  width: 100%;
  padding: 31px 32px 81px;
}

.process .process-content span {
  font-weight: bold;
  font-size: 20px;
}

.process .process-content p {
  margin: 15px 0 0;
  color: #000;
}

.process-fotter-col {
  width: 100%;
  float: left;
  padding: 0 15px;
  margin-top: 15px;
}

.process-footer {
  width: 100%;
  margin: 43px 0 0;
}

#natural-key-main {
  width: 100%;
  display: inline-block;
  margin: 60px 0;
}

#natural-key-main p {
  color: #4f4f4f;
  line-height: 24px;
}

.key-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.key-head {
  width: 100%;
  margin: 0 0 38px;
}

.key-head h2 {
  font-size: 34px;
  font-weight: bold;
  margin: 0 0 12px;
}

.key-chain-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.key-chain {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;

  /* Microsoft Edge */
  -ms-justify-content: space-between;

  /* Opera */
  -o-justify-content: space-between;

  /* Standard */
  justify-content: space-between;

  margin: 0 0 40px;
}

.key-chain.p-spacing {
  margin: 0;
}

.key-chain .key-chain-img {
  width: auto;
  position: relative;
  margin: 0 42px 20px 0;
}

.key-chain .key-chain-img::before {
  content: "";
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background-color: #219653;
}

.key-chain-col:nth-last-child(2) .key-chain .key-chain-img::before {
  display: none;
}

.key-chain .key-chain-img span {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #219653;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
}

.key-chain .key-chain-head {
  width: auto;
}

.slide-mobile {
  display: none;
}

.natural-burial-slide {
  width: 100%;
  display: inline-block;
}

.key-chain .key-chain-head h3 {
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 14px;
}

.chain-footer-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.chain-footer {
  width: 100%;
  margin: 60px 0 0;
}

/*************************** natural burials pages css end ********************/

/**************************** product page css start ************************/
#product-main {
  width: 100%;
  display: inline-block;
  margin: 54px 0 0;
}

#product-main h2 {
  font-weight: bold;
  font-size: 18px;
}

#product-main h3 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}

.products {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  /* Safari */
  -webkit-justify-content: center;

  /* Microsoft Edge */
  -ms-justify-content: center;

  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;

  gap: 10px;
}

.products .category {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  gap: 20px;
}

.products .category h2 {
  margin: 0;
}

.products .category ul {
  display: flex;
}

.products .category ul li {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  /* margin: 0 0 10px; */
  padding: 0;
}

.products .category ul li:last-child {
  margin-bottom: 0;
}

.products .category ul li .category-link .square {
  width: 6px;
  height: 6px;
  background-color: #bdbdbd;
  display: inline-block;
  margin: 0 8px 0 0;
}

.products .category ul li .category-link.category-active {
  color: #219653;
  border: 1px solid #219653;
}

.products .category ul li .category-link.category-active .square {
  background-color: #219653;
}

.products .category ul li .category-link {
  font-weight: 500;
  font-size: 16px;
  color: #828282;
  width: 136px;
  height: 45px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.products .category ul li .number {
  width: 26px;
  height: 26px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
  /* Safari */
  -webkit-justify-content: center;
  /* Microsoft Edge */
  -ms-justify-content: center;
  /* Opera */
  -o-justify-content: center;

  /* Standard */
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.products .category h2 {
  margin: 0;
}

.products .burials-plots {
  width: 100%;
  display: none;
}

.products .burials-plots.category-active {
  display: unset;
}

.products .burials-plots .product-head {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
}

.products .burials-plots .product-head .product-location {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
  width: 30%;
}

.products .burials-plots .product-head .product-location span {
  font-size: 14px;
  font-weight: bold;
  margin: 0 7px 0 0;
}

.products .burials-plots .product-head .product-type span {
  font-size: 12px;
  font-weight: 500;
  margin: 0 7px 0 0;
}

.products .burials-plots .product-head .product-location .product-select {
  width: 75px;
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
}

.products
  .burials-plots
  .product-head
  .product-location
  .product-select
  select {
  border: none;
  appearance: none;
  font-size: 14px;
  height: 100%;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 65px; */
  padding: 0 5px 0 0;
  background: none;
}

.products
  .burials-plots
  .product-head
  .product-location
  .product-select::after {
  content: "";
  position: absolute;
  top: 6px;
  right: -100px;
  background-image: url(../images/cart_down.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 10px;
  height: 5px;
}

.products .burials-plots .product-head .product-type {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;
}

.products .burials-plots .product-head .product-type .product-field {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  width: auto;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  margin: 0 0 0 10px;
  gap: 7px;
}

/* .products .burials-plots .product-head .product-type .product-field .circular-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0 4px 0 0;
}

.products .burials-plots .product-head .product-type .product-field .circular-checkbox input[type="checkbox"] {
    display: none;
}

.products .burials-plots .product-head .product-type .product-field .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #E3DEDE;
    border-radius: 50%;
}

.products .burials-plots .product-head .product-type .product-field .circular-checkbox input[type="checkbox"]:checked+.checkmark {
    background-color: #219653;
    border: none;
}

.products .burials-plots .product-head .product-type .product-field .rounded-checkbox {
    width: 18px;
    height: 18px;
    margin: 0 4px 0 0;
    accent-color: #219653;
    border-radius: 50%;
}

.products .burials-plots .product-head .product-type .product-field .circular-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.products .burials-plots .product-head .product-type .product-field .circular-checkbox input[type="checkbox"] {
    display: none;
}

.products .burials-plots .product-head .product-type .product-field .circular-checkbox input[type="checkbox"]:checked+.checkmark {
    background-color: #219653;
    border: none;
}

.products .burials-plots .product-head .product-type .product-field .circular-checkbox input[type="checkbox"]:checked+.checkmark::before {
    content: '\f00c';
    font-family: FontAwesome;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    color: #fff;
} */
.products .burials-plots .product-head .product-type .product-field input {
  width: 14px;
  height: 14px;
  accent-color: #219653;
}

.products .burials-plots .product-head .product-type .product-field .input-box {
  width: 14px;
  height: 14px;
  accent-color: #f61018;
}
.products
  .burials-plots
  .product-head
  .product-type
  .product-field
  .input-box-new {
  width: 14px;
  height: 14px;
  accent-color: #808080;
}
.products
  .burials-plots
  .product-head
  .product-type
  .product-field:nth-child(3)
  input {
  /* accent-color: #C8D400; */
  accent-color: #f1ff05;
}

.products
  .burials-plots
  .product-head
  .product-type
  .product-field:nth-child(4)
  input {
  accent-color: #20519b;
}

.products
  .burials-plots
  .product-head
  .product-type
  .product-field:nth-child(5)
  input {
  accent-color: #f9a303;
}

.products
  .burials-plots
  .product-head
  .product-type
  .product-field:nth-child(6)
  input {
  accent-color: #b6424c;
}

.products .burials-plots .product-head .product-type .product-field label {
  font-size: 14px;
  color: #4f4f4f;
  font-weight: 500;
}

.products .burials-plots .product-burials-map {
  width: 100%;
  cursor: pointer;
  position: relative;
  height: 660px;
}

.products .burials-plots .product-burials-map img,
.products .burials-plots .product-burials-map canvas {
  width: 100% !important;
}

.products .burials-plots .product-items {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.product-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.product-plant {
  width: 100%;
  display: inline-block;
  background-color: #fbfaf8;
  margin: 0 0 40px;
  padding: 20px;
  border: 1px solid #f2f2f2;
  cursor: pointer;
}

.product-plant img {
  vertical-align: top;
  width: 100%;
  height: 150px;
  object-position: center top;
}

.product-plant span {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
  margin: 15px 0 0;
}

.product-plant h3 {
  font-weight: 600;
  color: #000;
  margin: 5px 0 15px;
}

.product-plant .price {
  font-weight: bold;
  font-size: 18px;
  color: #eb5757;
}

.burials-add-to-cart.cart-show {
  opacity: 1;
  visibility: visible;
}

.info-main {
  width: 100%;
  max-width: 360px;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* opacity: 0;
    visibility: hidden; */
  z-index: 111;
  padding: 28px 37px 42px;
}

.info-main span {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: #4f4f4f;
  display: block;
  margin: 26px 0 8px;
}

.info-main p {
  color: #828282;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 37px;
}

.info-main .ok-btn {
  width: 94px;
  height: 40px;
  background-color: #219653;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
}

/* burials add to cart css  */
.burials-add-to-cart {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  width: 100%;
  max-width: 749px;
  height: 496px;
  background-color: #fff;
  padding: 40px 40px 45px;
  column-gap: 20px;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
}

.burials-img {
  width: 35%;
}

.burials-img img {
  width: 100%;
  height: 290px;
  vertical-align: top;
}

.burials-img ul {
  display: inline-block;
}

.burials-img ul li img {
  width: auto;
  height: auto;
}

.burials-plot-detail {
  width: 65%;
  position: relative;
}

.burials-plot-detail h2 {
  font-weight: bold;
  font-size: 22px;
}

.burials-plot-detail button {
  width: 93px;
  height: 24px;
  border: 1px solid #219653;
  text-align: center;
  color: #219653;
  font-size: 12px;
  font-weight: 500;
  background: none;
  border-radius: 30px;
  margin: 8px 10px 5px 0;
}

.cart-details button {
  width: 93px;
  height: 24px;
  border: 1px solid #219653;
  text-align: center;
  color: #219653;
  font-size: 12px;
  font-weight: 500;
  background: none;
  border-radius: 30px;
  margin: 8px 10px 5px 0;
}

.burials-plot-detail ul li {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;

  /* Microsoft Edge */
  -ms-align-items: center;

  /* Opera */
  -o-align-items: center;

  /* Standard */
  align-items: center;

  margin: 0 0 13px;
}

.burials-plot-detail ul li span {
  font-size: 12px;
  font-weight: 500;
  color: #4f4f4f;
  margin: 0 0 0 7px;
}

.burials-plot-detail .price {
  font-weight: 600;
  color: #eb5757;
  display: block;
  margin: 13px 0 18px;
}

.burials-plot-detail p {
  line-height: 24px;
  color: #4f4f4f;
}

.burials-plot-detail .cart-btn {
  height: 39px;
  width: 100%;
  border-radius: 0px;
  background-color: #219653;
  color: #fff;
  font-weight: bold;
  margin: 40px 0 0;
  cursor: pointer;
}

.burials-plot-detail .burials-remove-btn {
  position: absolute;
  top: -30px;
  right: -25px;
  cursor: pointer;
}

.burials-plot-detail .burials-remove-btn span i {
  color: #828282;
  font-size: 18px;
}

.cart-success.cart-show {
  opacity: 1;
  visibility: visible;
}

.cart-success.save-successful {
  opacity: 1;
  visibility: visible;
}

/* cart success  */
.cart-success {
  width: 100%;
  max-width: 360px;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 111;
  padding: 28px 37px 42px;
}

.cart-success span {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: #4f4f4f;
  display: block;
  margin: 26px 0 8px;
}

.cart-success p {
  color: #828282;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 37px;
  text-align: center;
}

.cart-success .ok-btn {
  width: 94px;
  height: 40px;
  background-color: #219653;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
}

.ok-btn-footer {
  width: 61px;
  height: 39px;
  background-color: #219653;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  margin: 40px 180px;
}

/**************************** product page css start ************************/

/*************************** cart page css start *************************/
#cart-main {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 100px;
}

.cart-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.cart-head {
  width: 100%;
}

.cart-head h1 {
  font-weight: bold;
  font-size: 24px;
  margin: 34px 0;
}

.cart-item-col {
  width: 70%;
  float: left;
  padding: 0 15px;
}

.cart-item-main {
  width: 100%;
  border: 1px solid #f2f2f2;
  padding: 26px 0;
}

.cart-item-main .cart-itme-head {
  width: 100%;
  display: inline-block;
  padding: 0 31px;
}

.cart-item-main .cart-itme-head span {
  color: #828282;
  font-size: 14px;
  font-weight: 500;
}

.cart-item-main .cart-item {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 31px 26px;
  margin: 16px 0 0;
  gap: 10px;
}

.cart-item-main .cart-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.cart-item-main .cart-item .cart-img {
  width: 20%;
}

.cart-item-main .cart-item .cart-img img {
  width: 135px;
  height: 122px;
  object-fit: cover;
  vertical-align: top;
}

.cart-item-main .cart-item .cart-details {
  width: 80%;
}

.cart-item-main .cart-item .cart-details h3 {
  font-size: 18px;
  margin: 0 0 5px;
  line-height: 18px;
}

.cart-item-main .cart-item .cart-details span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #219653;
}

.cart-item-main .cart-item .cart-details p {
  font-size: 14px;
  color: #828282;
  line-height: 18px;
  max-width: 500px;
  margin: 15px 0;
}

.cart-item-main .cart-item .cart-details .cart-quantity {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
}

.cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn {
  width: max-content;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
}

.cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn button {
  width: 31px;
  height: 31px;
  background: none;
  border: 1px solid #e0e0e0;
}

.cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn button i {
  color: #828282;
  font-size: 10px;
}

.cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn span {
  font-weight: 600;
  color: #eb5757;
  font-size: 14px;
  /* margin: 0 0 0 14px; */
  line-height: 18px;
}

.cart-item-main .cart-item .cart-details .cart-quantity .cart-item-remove {
  width: max-content;
}

.cart-item-main
  .cart-item
  .cart-details
  .cart-quantity
  .cart-item-remove
  button {
  color: #828282;
  line-height: 18px;
  background: none;
  border: none;
  font-size: 12px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  cursor: pointer;
}

.cart-item-main
  .cart-item
  .cart-details
  .cart-quantity
  .cart-item-remove
  button
  i {
  font-size: 14px;
  margin: 0 6px 0 0;
}

.add-cart-item {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  justify-content: right;
  margin: 21px 0 0;
}

.add-cart-item .add-item {
  width: 196px;
  height: 44px;
  background: none;
  border: 2px dotted #219653;
  color: #219653;
  cursor: pointer;
  line-height: 18px;
}

.add-cart-item .add-item i {
  margin: 0 6px 0 0;
}

.cart-checkout-col {
  width: 30%;
  float: left;
  padding: 0 15px;
}

.cart-checkout {
  width: 100%;
  border: 1px solid #f2f2f2;
}

.cart-checkout .checkout-head {
  width: 100%;
  display: inline-block;
  background-color: #f8f8f8;
  padding: 21px 30px;
}

.cart-checkout .checkout-head span {
  font-weight: 500;
  font-size: 14px;
  color: #828282;
}

.cart-checkout .checkout-field {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
  color: #4f4f4f;
  padding: 16px 22px;
  line-height: 16px;
}

.cart-checkout .checkout-field label {
  font-weight: 500;
  width: 200px;
}

.cart-checkout .checkout-field span {
  font-weight: bold;
}

.cart-checkout .checkout-total {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
  padding: 30px 22px 39px;
  border-top: 2px dotted #f2f2f2;
}

.cart-checkout .checkout-total label {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
}

.cart-checkout .checkout-total span {
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 800;
  line-height: 16px;
}

.Checkout-btn {
  width: 100%;
  margin: 12px 0 0;
}

.Checkout-btn button {
  width: 100%;
  background-color: #219653;
  height: 50px;
  border: none;
  color: #fff;
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  font-size: 16px;
}

/***** plot confirmation  *****/
.plot-confirmation.alert-active {
  opacity: 1;
  visibility: visible;
}

.plot-confirmation {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  margin: 0 auto;
  text-align: center;
  padding: 44px 59px 53px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* opacity: 0;
    visibility: hidden; */
  z-index: 11;
}

.plot-confirmation img {
  vertical-align: top;
}

.plot-confirmation span {
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  margin: 17px 0 9px;
}

.plot-confirmation p {
  color: #4f4f4f;
  line-height: 20px;
  margin: 0 0 23px;
}

.plot-confirmation button {
  width: 94px;
  height: 40px;
  background-color: #219653;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}

/* order complete */
.order-complete {
  width: 100%;
  max-width: 360px;
  background-color: #fff;
  margin: 0 auto;
  text-align: center;
  padding: 30px 37px 42px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 11;
}

.order-complete.alert-active {
  opacity: 1;
  visibility: visible;
}

.order-complete span {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  margin: 25px 0 0;
  color: #4f4f4f;
}

.order-complete p {
  color: #828282;
  font-size: 14px;
  margin: 8px 0 26px;
  line-height: 20px;
}

.order-complete button {
  /* width: 94px; */
  height: 40px;
  background-color: #219653;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  padding: 0px 12px;
}

/* empty cart page  */
#cart-empty-main {
  width: 100%;
  display: inline-block;
  margin: 100px 0;
}

.cart-empty {
  width: 100%;
  text-align: center;
}

.cart-empty h2 {
  font-weight: bold;
  font-size: 40px;
}

.cart-empty h2 span {
  color: #219653;
  display: inline-block;
}

.cart-empty p {
  line-height: 18px;
  color: #828282;
  margin: 30px 0 50px;
}

.cart-empty a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: center;
  /* Microsoft Edge */
  -ms-justify-content: center;
  /* Opera */
  -o-justify-content: center;
  /* Standard */
  justify-content: center;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;

  width: 200px;
  height: 50px;
  background-color: #219653;
  color: #fff;
  font-weight: bold;
  margin: 0 auto;
}

/*************************** cart page css end *************************/

/*************************** profile page css start *********************/
#profile-main {
  width: 100%;
  display: inline-block;
  margin: 0 0 110px;
}

.account-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.account-head {
  width: 100%;
  display: inline-block;
  margin: 34px 0 46px;
}

.account-head h1 {
  font-weight: bold;
  font-size: 24px;
}

.account-main-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.account-main {
  width: 100%;
  border: 1px solid #f2f2f2;
}

.account-main ul {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 0 45px;
}

.account-main ul li {
  margin: 0 30px 0 0;
}

.account-main ul li a {
  display: inline-block;
  padding: 23px 10px;
  color: #828282;
  font-weight: 600;
  border: 2px solid transparent;
}

.account-main ul li a.profile-active {
  color: #219653;
  border-bottom: 2px solid #219653;
}

.account-main ul li a.profile-active i {
  color: #219653;
}

.account-main ul li a i {
  color: #828282;
  margin: 0 10px 0 0;
  font-size: 20px;
}

.account-main .profile-details-main {
  width: 100%;
  display: none;
}

.account-main .profile-details-main.profile-active {
  display: inline-block;
}

.account-main .profile-details-main .profile-details {
  width: 100%;
  padding: 56px 0 0 47px;
}
.account-main .profile-details-main .profile-details.legacy-info {
  padding: 56px 45px 0 47px;
}
.account-main .profile-details-main .profile-details.legacy-info .profile-info {
  margin: 0 0 75px;
}
.account-main .profile-details-main .profile-password {
  padding-top: 0;
}

.account-main .profile-details-main .profile-details .profile-upload {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-upload
  .profile-img {
  width: auto;
  margin: 0 33px 0 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-upload
  .profile-img
  .profile-initials {
  width: 160px;
  height: 160px;
  background-color: #f2f2f2;
  border-radius: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  /* Safari */
  -webkit-justify-content: center;
  /* Microsoft Edge */
  -ms-justify-content: center;
  /* Opera */
  -o-justify-content: center;
  /* Standard */
  justify-content: center;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-upload
  .profile-img
  .profile-initials
  img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  vertical-align: top;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-upload
  .profile-delete {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-upload
  .profile-delete
  .new-upload {
  width: 149px;
  height: 37px;
  margin: 0 9px 0 0;
  position: relative;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-upload
  .profile-delete
  .new-upload
  button {
  width: 100%;
  height: 100%;
  border: 1px solid #bdbdbd;
  color: #4f4f4f;
  font-size: 14px;
  background-color: #fff;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-upload
  .profile-delete
  .new-upload
  input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-upload
  .profile-delete
  button {
  width: 63px;
  height: 37px;
  background-color: #f2f2f2;
  border: none;
  font-size: 14px;
  color: #4f4f4f;
}

.account-main .profile-details-main .profile-details .profile-info {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  margin: 0 0 154px;
  row-gap: 15px;
}

.account-main .profile-details-main .profile-details .profile-info h3 {
  font-weight: 600;
  font-size: 20px;
  margin: 49px 0 36px;
  width: 100%;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .visa-details-main {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  border: 1px solid #f2f2f2;
  margin: 0 50px 0 0;
  padding: 20px 24px 16px 20px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .visa-details-main
  .visa-details {
  width: auto;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .visa-details-main
  .visa-details
  .visa-number {
  margin: 0 0 0 17px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .visa-details-main
  .visa-details
  .visa-number
  span {
  display: inline-block;
  color: #4f4f4f;
  font-weight: 500;
  line-height: 139%;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .visa-details-main
  .visa-details
  .visa-number
  p {
  font-size: 14px;
  color: #000;
  line-height: 139%;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .visa-details-main
  .visa-remove
  span
  i {
  color: #bdbdbd;
  cursor: pointer;
}

.account-main .profile-details-main .profile-details .profile-info .add-visa {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  justify-content: right;
  margin: 21px 49px 0 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .add-visa
  button {
  padding: 0 10px;
  height: 44px;
  background: transparent;
  color: #219653;
  border: 2px dotted #219653;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .add-visa
  button
  i {
  margin: 0 5px 0 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .profile-input {
  width: 31%;
  position: relative;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .profile-input
  label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 5px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .profile-input
  input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 22px;
  font-size: 20px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .profile-input
  input::placeholder {
  color: #000;
  font-size: 14px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .profile-input
  .input-email {
  color: #797979;
  font-size: 20px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .profile-input
  i {
  position: absolute;
  top: 34px;
  font-size: 20px;
  right: 16px;
  color: #828282;
}

.account-main .profile-details-main .profile-details .profile-info .my-order {
  width: 100%;
  display: inline-block;
  margin: 0 49px 17px 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  column-gap: 20px;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 26px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-img {
  width: auto;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-img
  img {
  width: 135px;
  height: 122px;
  object-fit: cover;
  vertical-align: top;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details {
  width: auto;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  span.order-head {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: #333;
  margin: 0 0 5px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #219653;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  p {
  color: #828282;
  font-size: 14px;
  line-height: 18px;
  margin: 7px 0 23px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  .order-price {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  .order-price
  span.price {
  color: #eb5757;
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
  margin-right: 10px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  .order-price
  span.price
  label {
  font-weight: 500;
  line-height: 18px;
  color: #4f4f4f;
  margin: 0 14px 0 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  .order-price
  span.status {
  color: #333;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  .order-price
  span.status
  .circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #219653;
  margin: 0 5px 0 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .my-order
  .order-details-main
  .order-details
  .order-price
  span.status
  .circle1 {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(221 237 5);
  margin: 0 5px 0 0;
}

.account-main .profile-details-main .profile-btn {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: end;
  padding: 15px 49px;
  border-top: 1px solid #f2f2f2;
}

.account-main .profile-details-main .profile-btn button {
  color: #828282;
  font-weight: 600;
  line-height: 18px;
  background-color: #f2f2f2;
  width: 94px;
  height: 50px;
  cursor: pointer;
  border: none;
}

.account-main .profile-details-main .profile-btn button.save {
  width: 151px;
  background-color: #219653;
  color: #fff;
}

/********* add card page css ***********************/

.add-Card {
  width: 100%;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 39px 40px 56px;
  opacity: 0;
  visibility: hidden;
  z-index: 111;
  background-color: #fff;
}

.checkout-card span {
  position: absolute;
  top: 19px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.checkout-card h2 {
  font-size: 22px;
  line-height: 18px;
  font-weight: bold;
  margin: 0 0 31px;
}

.checkout-card button {
  width: 100%;
  height: 50px;
  background-color: #219653;
  border: none;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 31px 0 0;
}

.add-Card.card-active {
  opacity: 1;
  visibility: visible;
}

.add-Card .add-card-head {
  position: relative;
}

.add-Card .add-card-head i {
  color: #828282;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 20px;
}

.add-Card .add-card-head h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  margin: 0 0 31px;
}

.add-Card .input-field {
  width: 100%;
  margin: 0 0 14px;
  position: relative;
}

.add-Card .expire-date {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Microsoft Edge */
  -ms-justify-content: space-between;
  /* Opera */
  -o-justify-content: space-between;
  /* Standard */
  justify-content: space-between;
  column-gap: 15px;
}

.add-Card .expire-date input {
  width: 50%;
}

.add-Card .input-field label {
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 5px;
  display: block;
}

.add-Card .input-field input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 22px;
}

.add-Card .input-field i {
  position: absolute;
  top: 50%;
  right: 14px;
  color: #828282;
}

.add-Card .save-btn {
  width: 100%;
  display: inline-block;
}

.add-Card .save-btn button {
  width: 100%;
  height: 50px;
  font-weight: bold;
  color: #fff;
  background-color: #219653;
  border: none;
  cursor: pointer;
  line-height: 18px;
  font-size: 16px;
}

/*************************** profile page css start *********************/

/* learn overview page */

#learn-overview {
  width: 100%;
  display: inline-block;
  margin: 27px 0 30px;
  vertical-align: top;
}

.overview-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.overview-content {
  width: 100%;
}

.overview-content .back-link {
  width: max-content;
  font-weight: bold;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  color: #333;
}

.overview-content .back-link img {
  margin: 0 12px 0 0;
  width: auto;
  height: auto;
}

.overview-content h1 {
  font-weight: bold;
  font-size: 30px;
  margin: 27px 0;
}

.overview-content img {
  vertical-align: top;
  width: 100%;
  margin: 0 0 40px;
  object-fit: cover;
}

.overview-content p {
  color: #4f4f4f;
  margin: 0 0 20px;
  line-height: 24px;
}

.overview-content p a {
  color: #219653;
  text-decoration: underline;
  font-weight: normal;
  display: unset;
}

.overview-content p.p-spacing {
  margin: 0;
}

/* term condition page */
#terms-main {
  width: 100%;
  display: inline-block;
  margin: 27px 0 100px;
}

.terms-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.terms-details {
  width: 100%;
  display: inline-block;
}

.terms-details p {
  line-height: 24px;
  color: #4f4f4f;
}

.terms-details .back-btn {
  width: auto;
  margin: 0 0 68px;
}

.terms-details .back-btn .back-link {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  /* Safari */
  -webkit-align-items: center;
  /* Microsoft Edge */
  -ms-align-items: center;
  /* Opera */
  -o-align-items: center;
  /* Standard */
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.terms-details .back-btn .back-link img {
  margin: 0 15px 0 0;
}

.terms-details .our-term {
  width: 100%;
  margin: 0 0 46px;
}

.terms-details .our-term h1 {
  font-weight: bold;
  font-size: 34px;
  color: #000;
  margin: 0 0 11px;
}

.terms-update {
  width: 100%;
  margin: 0 0 35px;
}

.terms-update h3 {
  font-weight: bold;
  font-size: 22px;
  color: #000;
  margin: 0 0 11px;
}

.terms-update p.mb {
  margin: 20px 0;
}

.terms-details .instruction {
  width: 100%;
  margin: 0 0 27px;
}

.terms-details .instruction h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000;
  margin: 0 0 11px;
}

.terms-details .policy-head {
  width: 100%;
}

.terms-details .policy-head p {
  line-height: 24px;
  color: #4f4f4f;
  margin: 0 0 20px;
}

.terms-details .policy-head .heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 25px;
}

.terms-details .policy-head .heading span.last-update {
  color: #828282;
  font-size: 12px;
  font-weight: 500;
}

.terms-details .policy-head .heading span {
  font-size: 23px;
  font-weight: bold;
}
.terms-details .policy-head .heading h1 {
  font-size: 23px;
  font-weight: bold;
}

.terms-details .policy-list {
  width: 100%;
}

.terms-details .policy-list .policy {
  width: 100%;
  margin: 0 0 40px;
}

.terms-details .policy-list .policy .policy-category {
  width: 100%;
  margin: 20px 0;
}

.terms-details .policy-list .policy .policy-category b {
  margin: 0 0 5px;
  display: block;
}

.terms-details .policy-list .policy h3 {
  font-size: 22px;
  font-weight: bold;
  color: #000;
  margin: 0 0 5px;
}

.terms-details .policy-list .policy p span {
  color: #219653;
  font-weight: 500;
  display: inline-block;
}

.services-use {
  width: 100%;
  margin: 0 0 40px;
}

.services-use p span.terms-heading,
.term-contact p span.terms-heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  color: #4f4f4f;
}

.services-use p.service-first {
  margin: 0 0 14px;
}

.services-use ul {
  padding: 0 0 0 20px;
}

.services-use ul li {
  list-style: disc;
}

.services-use ul li p.mb {
  margin: 0 0 27px;
}

.term-paragraph p.mb {
  margin: 20px 0 0;
}

.term-contact {
  margin: 37px 0 0;
}

.forgot-back {
  margin: 22px 0 20px !important;
}

.pet-burials-main {
  margin: 58px 0 0 !important;
}

.pet-burials-img img {
  height: 369px;
}

/* .overview-text.pet-text {
    padding: 35px 0 0 !important;
} */

.overview-text.pet-text::before {
  display: none;
}

.overview-text.pet-text h2,
.overview-text.pet-text p,
.overview-text.pet-text a {
  margin: 0 !important;
}

.overview-text.pet-text p {
  margin: 23px 0 !important;
}

/* pet-burial-main */
#pet-burials-main {
  width: 100%;
  display: inline-block;
  margin: 36px 0 84px;
  vertical-align: top;
}

.pet-burials-img-col {
  width: 50%;
  padding: 0 15px;
  float: left;
}

.pet-burials-text-col {
  width: 50%;
  padding: 0 15px;
  float: left;
}

.pet-burials-main .overview-text h2 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}

.pet-burials-main .overview-text p {
  color: #4f4f4f;
  font-size: 16px;
  line-height: 24px;
}

.pet-burials-main .overview-text a {
}

.pet-burial-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.pet-burials-find {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
}

.pet-burial-left,
.pet-burial-right {
  width: 50%;
}

.pet-burial-right img {
  width: 100%;
}

.pet-burial-left span {
  font-weight: bold;
  font-size: 20px;
}

.pet-burial-left h2 {
  font-weight: bold;
  margin: 9px 0 14px;
  font-size: 40px;
}

.pet-burial-left p {
  color: #4f4f44;
  line-height: 24px;
}

.pet-burial-left .py {
  margin: 20px 0 0 !important;
}

#pet-live-main {
  margin: 0 0 154px;
  width: 100%;
  display: inline-block;
}

#Honouring-main {
  width: 100%;
  display: inline-block;
  margin: 0 0 175px;
}

.Honouring-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.Honouring {
  width: 100%;
  text-align: center;
}

.Honouring h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 0 0 53px;
}

.Honouring {
  width: 100%;
}

.Honouring img {
  width: 100%;
  height: 664px;
  object-fit: cover;
}

.celebration-main {
  background-color: #e9fdf2;
  padding: 55px 0;
  margin: 0 !important;
}

.celebration-main .pet-burial-right img {
  height: 377px;
  width: 100%;
}

.celebration-detail-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.celebration-detail-main {
  width: 100%;
  display: flex;
  margin: 45px 0 0;
  gap: 47px;
}

.celebration-detail-main .celebration-detail {
  width: 33%;
  background-color: #fff;
  height: 223px;
  padding: 20px;
}

.celebration-detail-main .celebration-detail p {
  color: #4b5563;
  font-size: 14px;
  line-height: 20px;
}

.embrace-main {
  background-color: #fffdec;
}

.forever-heart-main {
  background-color: #d6eded;
}

.forever-heart-main .celebration-detail {
  height: 183px;
}

.goodbye-main {
  margin: 84px 0 50px !important;
}

.sunrise-main {
  margin: 0 !important;
  padding: 50px 0 58px;
  position: relative;
}

.sunrise-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/sunrise_bg_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

#petburial-paragraph {
  width: 100%;
  display: inline-block;
  margin: 107px 0;
}

.paragraph-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.paragraph {
  width: 100%;
  text-align: center;
}

.paragraph p {
  font-weight: bold;
  font-size: 40px !important;
}

#kendal-main {
  width: 100%;
  display: inline-block;
}

.kendal-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.kendal {
  width: 100%;
}

.kendal img {
  width: 100%;
  height: 369px;
  object-fit: cover;
}

.kendal p,
.kendal-paragraph p {
  color: #4b5563;
  line-height: 26px;
  font-size: 20px;
  margin: 20px 0 0;
}

.kendal-paragraph-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.kendal-paragraph {
  width: 100%;
}

.kendal-paragraph p {
  margin: 40px 0 68px;
}

#harmony-main {
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 30px 0 0;
}

.harmony-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.harmony-head {
  width: 100%;
  text-align: center;
  margin: 0 0 66px;
}

.harmony-head h1 {
  font-weight: bold;
  font-size: 40px;
}

#harmony-main .pet-burial-left p {
  /* font-size: 20px; */
  line-height: 26px;
  color: #4b5563;
}

#harmony-main .kendal-paragraph p {
  margin: 40px 0 20px;
}

#harmony-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 517px;
  background-image: url(../images/harmony_bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}

.harmony-cta-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.harmony-cta-main {
  width: 100%;
  display: flex;
  gap: 26px;
}

.harmony-cta-main .cta {
  width: 50%;
  display: flex;
  gap: 23px;
  background-color: #219653;
  padding: 38px 46px;
}

.harmony-cta-main .cta .left {
  width: calc(100% - 180px);
}

.harmony-cta-main .cta .left span {
  font-weight: bold;
  font-size: 22px;
  color: #fff;
}

.harmony-cta-main .cta .left p {
  color: #fff;
  font-weight: 500;
}

.harmony-cta-main .cta a {
  width: 180px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  justify-content: center;
  gap: 9px;
  height: 46px;
}

.harmony-cta-main .cta-purchase {
  background-color: transparent;
  border: 1px solid #219653;
}

.harmony-cta-main .cta-purchase .left span,
.harmony-cta-main .cta-purchase .left p,
.harmony-cta-main .cta-purchase a {
  color: #219653;
}

.harmony-cta-main .cta-purchase a {
  border: 1px solid #219653;
}

.kendal-unique {
  margin: 68px 0 36px !important;
}

.kendal-unique .overview-text h2 {
  max-width: none;
}

.kendal-unique .overview-text p {
  margin: 20px 0 0;
}

.kendal-unique .overview-text a {
  margin: 23px 0 0;
}

.kendal-unique .overview-text.pet-text {
  padding: 0 !important;
}

.kendal-unique .overview-text.pet-text {
  margin: 0;
}

.kendal-unique .overview-img img {
  height: 430px;
  vertical-align: top;
}

.peace-main {
  width: 100%;
}

.peace-main .overview-img img {
  height: auto;
}

#discover-heritage-main {
  width: 100%;
  display: inline-block;
  margin: 81px 0 0;
}

.discover-heritage-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.discover-heritage {
  width: 100%;
  background-color: #e9fdf2;
  padding: 70px 53px;
}

.discover-heritage .discover-heritage-head {
  width: 100%;
  text-align: center;
}

.discover-heritage .discover-heritage-head h2 {
  font-size: 40px;
  font-weight: bold;
  padding: 0 15px;
}

.conclusion-main .overview-img img {
  height: 475px;
}

.location-access-meet {
  margin: 30px 0 68px !important;
}

.location-access-meet .paragraph span {
  color: #4f4f44;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: 23px 0 0;
}

/* pet-burial-main */
#pet-burials-main {
  width: 100%;
  display: inline-block;
  margin: 36px 0 84px;
}

.pet-burial-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.pet-burials-find {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
}

.pet-burial-left,
.pet-burial-right {
  width: 50%;
}

.pet-burial-right img {
  width: 100%;
  object-fit: cover;
}

.pet-burial-left span {
  font-weight: bold;
  font-size: 20px;
}

.pet-burial-left h2 {
  font-weight: bold;
  margin: 9px 0 14px;
  font-size: 40px;
}

.pet-burial-left p {
  color: #4f4f44;
  line-height: 24px;
}

.pet-burial-left .py {
  margin: 20px 0 0 !important;
}

#pet-live-main {
  margin: 0 0 154px;
  width: 100%;
  display: inline-block;
}

#Honouring-main {
  width: 100%;
  display: inline-block;
  margin: 0 0 175px;
}

.Honouring-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.Honouring {
  width: 100%;
  text-align: center;
}

.Honouring h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 0 0 53px;
}

.Honouring {
  width: 100%;
}

.Honouring img {
  width: 100%;
  height: 664px;
  object-fit: cover;
}

.celebration-main {
  background-color: #e9fdf2;
  padding: 55px 0;
  margin: 0 !important;
}

.celebration-main .pet-burial-right img {
  height: 377px;
  width: 100%;
}

.celebration-detail-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.celebration-detail-main {
  width: 100%;
  display: flex;
  margin: 45px 0 0;
  gap: 47px;
}

.celebration-detail-main .celebration-detail {
  width: 33%;
  background-color: #fff;
  height: 223px;
  padding: 20px;
}

.celebration-detail-main .celebration-detail p {
  color: #4b5563;
  font-size: 14px;
  line-height: 20px;
}

.embrace-main {
  background-color: #fffdec;
}

.forever-heart-main {
  background-color: #d6eded;
}

.forever-heart-main .celebration-detail {
  height: 183px;
}

.goodbye-main {
  margin: 84px 0 50px !important;
}

.sunrise-main {
  margin: 0 !important;
  padding: 50px 0 58px;
  position: relative;
}

.sunrise-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/sunrise_bg_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

#petburial-paragraph {
  width: 100%;
  display: inline-block;
  margin: 107px 0;
}

.paragraph-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.paragraph {
  width: 100%;
  text-align: center;
}

.paragraph p {
  font-weight: bold;
  font-size: 40px;
}

#kendal-main {
  width: 100%;
  display: inline-block;
}

.kendal-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.kendal {
  width: 100%;
}

.kendal img {
  width: 100%;
  height: 369px;
}

.kendal p,
.kendal-paragraph p {
  color: #4b5563;
  line-height: 26px;
  font-size: 14px;
  margin: 20px 0 0;
}

.kendal-paragraph-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.kendal-paragraph {
  width: 100%;
}

.kendal-paragraph p {
  margin: 40px 0 68px;
}

#harmony-main {
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 30px 0 0;
}

.harmony-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.harmony-head {
  width: 100%;
  text-align: center;
  margin: 0 0 66px;
}

.harmony-head h1 {
  font-weight: bold;
  font-size: 40px;
}

#harmony-main .pet-burial-left p {
  /* font-size: 14px; */
  line-height: 26px;
  color: #4b5563;
}

#harmony-main .kendal-paragraph p {
  margin: 40px 0 20px;
}

#harmony-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 517px;
  background-image: url(../images/harmony_bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}

.harmony-cta-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.harmony-cta-main {
  width: 100%;
  display: flex;
  gap: 26px;
}

.harmony-cta-main .cta {
  width: 50%;
  display: flex;
  gap: 23px;
  background-color: #219653;
  padding: 38px 46px;
}

.harmony-cta-main .cta .left {
  width: calc(100% - 180px);
}

.harmony-cta-main .cta .left span {
  font-weight: bold;
  font-size: 22px;
  color: #fff;
}

.harmony-cta-main .cta .left p {
  color: #fff !important;
  font-weight: 500;
}

.harmony-cta-main .cta a {
  width: 180px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  justify-content: center;
  gap: 9px;
  height: 46px;
}

.harmony-cta-main .cta-purchase {
  background-color: transparent;
  border: 1px solid #219653;
}

.harmony-cta-main .cta-purchase .left span,
.harmony-cta-main .cta-purchase .left p,
.harmony-cta-main .cta-purchase a {
  color: #219653 !important;
}

.harmony-cta-main .cta-purchase a {
  border: 1px solid #219653;
}

.kendal-unique {
  margin: 68px 0 36px !important;
}

.kendal-unique .overview-text h2 {
  max-width: none;
}

.kendal-unique .overview-text p {
  margin: 20px 0 0;
}

.kendal-unique .overview-text a {
  margin: 23px 0 0;
}

.kendal-unique .overview-text.pet-text {
  padding: 0 !important;
}

.kendal-unique .overview-img img {
  height: 430px;
  vertical-align: top;
  object-fit: cover;
}

.peace-main {
  width: 100%;
}

.peace-main .overview-img img {
  height: auto;
}

#discover-heritage-main {
  width: 100%;
  display: inline-block;
  margin: 81px 0 0;
}

.discover-heritage-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.discover-heritage {
  width: 100%;
  background-color: #e9fdf2;
  padding: 70px 53px;
}

.discover-heritage .discover-heritage-head {
  width: 100%;
  text-align: center;
}

.discover-heritage .discover-heritage-head h2 {
  font-size: 40px;
  font-weight: bold;
  padding: 0 15px;
}

.conclusion-main .overview-img img {
  height: 475px;
}

.location-access-meet {
  margin: 30px 0 68px !important;
}

.location-access-meet .paragraph span {
  color: #4f4f44;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: 23px 0 0;
}

#serene-main {
  width: 100%;
  display: inline-block;
  margin: 0 0 96px;
}

.serene-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.serene {
  width: 100%;
  position: relative;
}

.serene img {
  width: 100%;
  vertical-align: top;
  object-fit: cover;
}

.serene-text {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding: 0 0 0 78px;
}

.serene-text h2 {
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  color: #fff;
}

.serene-text p {
  font-size: 18px;
  line-height: 27px;
  color: #fff !important;
  max-width: 545px;
  margin: 18px 0;
}

.serene-text a {
  width: 202px;
  height: 48px;
  background-color: #219653;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 16px;
  font-weight: bold;
}

.serene-text a img {
  width: 20px;
  height: 20px;
}

.buyers-main {
  width: 630px;
  background-color: #fff;
  padding: 25px 30px;
  position: relative;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
  z-index: 1111;
}

.buyers-main .closeBtn {
  position: absolute;
  top: 26px;
  right: 36px;
  background: none;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  cursor: pointer;
  border: none;
}

.buyers-main h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  margin: 20px 0 31px;
}

.buyers-main .head {
  width: 100%;
  display: inline-block;
  margin: 0 0 15px;
}

.buyers-main .head p {
  color: #6c6c6c;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 14px;
}

.buyers-main .head p span {
  /* color: #219653; */
  display: inline-block;
  margin: 0 19px 0 0;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 5px 12px;
  font-size: 15px;
}

.buyers-main .head p .active {
  color: #219653;
  display: inline-block;
  margin: 0 19px 0 0;
  background-color: #bff0d4;
  border: 1px solid #7ad5a1;
}

.buyers-main .head p span b {
  color: #e60000;
  margin: 10px 0 0;
  font-size: 20px;
}

.buyers-main .input-group {
  width: 100%;
}

.buyers-main .input-group .input-field {
  width: 100%;
  margin: 0 0 14px;
}

.buyers-main .input-group .input-field label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 0 0 5px;
  display: block;
}

.buyers-main .input-group .input-field label span {
  display: inline-block;
  color: #e60000;
}

.buyers-main .input-group .input-field input,
.buyers-main .input-group .input-field textarea {
  width: 100%;
  /* height: 50px; */
  padding: 12px 10px;
  font-size: 16px;
  /* border: 1px solid #E0E0E0; */
}

.buyers-main .input-group .input-field textarea {
  padding: 16px 11px;
  font-family: "Inter", sans-serif;
}

/* .buyers-main .input-group .input-field textarea::placeholder {
    padding:16px 0 0 ;
} */
.buyers-main .input-group .input-field input::placeholder,
.buyers-main .input-group .input-field textarea::placeholder {
  color: #bdbdbd;
}

.buyers-main .input-group .input-field textarea {
  height: 92px;
}

.buyers-main .input-group .input-pin {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.buyers-main .input-group .input-pin .input-code {
  width: 48%;
}

.buyers-main .next-btn {
  width: 100%;
  height: 50px;
  background-color: #219653;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  display: unset;
  cursor: pointer;
  margin-top: 19px;
}

.calendly-details-main
  .lmtWIHO_gkbTeeyuvoJC
  ._cUP1np9gMvFQrcPftuf._Y8HCTxgNkwxXcG_DCXx
  ._3efP_GeH5kyBAzqnLzL.cllbjvXCdYDt9A3te4cz {
  display: flex;
  flex: 1 1 auto;
  min-height: 550px;
  transition: all 0.22s ease-out;
}

.calendly-details-main
  .lmtWIHO_gkbTeeyuvoJC
  ._cUP1np9gMvFQrcPftuf._Y8HCTxgNkwxXcG_DCXx {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.toggle-button {
  border: none;
  background: none;
  color: green;
  font-weight: 700;
  font-size: x-small;
  /* position: absolute; */
  left: 77%;
}

.toggle-button-detail {
  border: none;
  background: none;
  color: green;
  font-weight: 700;
  font-size: unset;
  /* position: absolute;
    left: 80%;  */
  cursor: pointer;
}

.toggle-button-detail p {
  border: none;
  background: none;
  color: green;
  font-weight: 700;
  font-size: unset;
  position: absolute;
  left: 80%;
  top: 0;
  transition: top 0.5s, opacity 0.5s;
}

#location-form-main {
  width: 100%;
  display: inline-block;
  margin: 39px 0 202px;
}

.go-back {
  width: 100%;
  margin: 0 0 61px;
}

.go-back .back-link {
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #333;
}

.location-form {
  width: 100%;
  display: inline-block;
}

.location-form h2 {
  font-weight: bold;
  font-size: 34px;
  line-height: 42px;
  margin: 0 0 26px;
  color: #000;
}

.location-form .form-main {
  width: 100%;
  margin: 0 0 50px;
}

.location-form .form-main h3 {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 21px;
}

.location-form .form-main form {
  width: 100%;
  background-color: #fbfaf8;
  padding: 40px;
}

.location-form .form-main .internet-fee {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  gap: 125px;
}

.location-form .form-main .internet-fee .info-left {
  width: 50%;
}

.location-form .form-main .internet-fee .info-left p {
  color: #4f4f4f;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  display: flex;
  gap: 93px;
}

.location-form .form-main .internet-fee .info-left .fee-select {
  width: 100%;
  display: flex;
  gap: 43px;
}

.location-form .form-main .internet-fee .info-left .fee-select label {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  /* gap: 95px; */
  color: #000;
  flex-wrap: wrap;
  width: 80%;
}

.location-form .form-main .internet-fee .info-left .fee-select label p span,
.location-form .form-main .internet-fee .info-left .fee-select label p b {
  font-weight: 500;
  font-size: 18px;
  color: #000;
}

.location-form .form-main .internet-fee .info-left .fee-select .check-box {
  width: 33%;
  display: flex;
  gap: 10px;
  justify-content: right;
  flex-wrap: wrap;
  text-align: right;
}

.location-form
  .form-main
  .internet-fee
  .info-left
  .fee-select
  .check-box
  > div {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: right;
  text-align: right;
}

.location-form
  .form-main
  .internet-fee
  .info-left
  .fee-select
  .check-box
  input {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  accent-color: #45b876;
  border: 1px solid #818181;
  background-color: #fff;
  appearance: none;
}

.location-form
  .form-main
  .internet-fee
  .info-left
  .fee-select
  .check-box
  input:checked {
  background-color: #219623;
}

.location-form
  .form-main
  .internet-fee
  .info-left
  .fee-select
  .check-box
  input::before {
  content: "\2714";
  display: block;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  line-height: 18px;
}

.location-form
  .form-main
  .internet-fee
  .info-left
  .fee-select
  .check-box
  label {
  font-weight: normal;
  font-size: 16px;
  width: auto;
}

.location-form .form-main form .input-group {
  width: 100%;
  display: flex;
  gap: 46px;
  margin: 0 0 20px;
}

.location-form .form-main form .input-group.mb {
  margin: 0;
}

.location-form .form-main form .input-group .input-field {
  width: 50%;
}

.location-form .form-main form .input-group .input-field label {
  color: #000;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 9px;
  display: block;
}

.location-form .form-main form .input-group .input-field label.application {
  margin: 0 0 18px;
}

.location-form .form-main form .input-group .input-field label span {
  display: block;
  color: #4f4f4f;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 5px 0 0;
}

.location-form .form-main form .input-group .input-field .upload {
  width: 100%;
  position: relative;
}

.location-form .form-main form .input-group .input-field .upload button {
  width: 100%;
  height: 54px;
  border: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  font-size: 16px;
  color: #828282;
}

.location-form .form-main form .input-group .input-field .upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.location-form .form-main form .input-group .input-field input,
.location-form .form-main form .input-group textarea {
  width: 100%;
  height: 54px;
  padding: 0 25px;
  border: none;
  font-size: 16px;
  outline: none;
}

.location-form .form-main form .input-group .input-field input::placeholder,
.location-form .form-main form .input-group textarea::placeholder {
  color: #828282;
  font-weight: normal;
}

.location-form .form-main form .input-group textarea {
  padding: 17px 25px 0;
  height: 74px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

.location-form .form-paragraph {
  width: 100%;
  margin: 0 0 50px;
}

.location-form .form-paragraph p {
  line-height: 24px;
  color: #4f4f4f;
}

.location-form .form-paragraph p span {
  color: #219623;
  text-decoration: underline;
}

.location-form .submit-btn {
  width: 100%;
  text-align: center;
}

.location-form .submit-btn p {
  color: #4f4f4f;
  line-height: 24px;
}

.location-form .submit-btn button {
  width: 501px;
  height: 54px;
  background-color: #219653;
  margin: 14px 0 0;
  color: #fff;
  font-weight: 800;
  font-size: 16px;
  border: none;
}

@media screen and (max-width: 1099px) {
  .location-form .form-main .internet-fee .info-left .fee-select label {
    gap: 10px;
  }

  .location-form .form-main form .input-group .input-field label span {
    font-size: 13px;
  }
}

@media screen and (max-width: 991px) {
  .location-form .form-main form {
    padding: 15px;
  }

  .location-form .form-main form .input-group {
    gap: 15px;
  }

  .location-form .form-main form .input-group .input-field input,
  .location-form .form-main form .input-group textarea {
    padding: 0 15px;
  }

  .location-form .form-main form .input-group textarea {
    padding: 10px 15px 0;
  }

  .go-back {
    margin: 0 0 25px;
  }

  .location-form h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }

  .location-form .form-main .internet-fee {
    row-gap: 25px;
    margin: 30px 0 0;
    flex-wrap: wrap;
  }

  .location-form .form-main .internet-fee .info-left {
    width: 100%;
    border-bottom: 1px solid #e4e1e1;
    padding: 0 0 10px;
  }

  .location-form .form-main form .input-group .input-field label span {
    min-height: 50px;
  }

  /* .event-form {
        flex-wrap: wrap;
    }
    .location-form .form-main form .event-form .input-field {
        width: 100%;
    } */
}

@media screen and (max-width: 575px) {
  #location-form-main {
    margin: 35px 0 50px;
  }

  .location-form .form-main form .input-group {
    flex-wrap: wrap;
  }

  .location-form .form-main form .input-group .input-field {
    width: 100%;
  }

  .location-form .submit-btn button {
    width: 100%;
    font-weight: 500;
    height: 45px;
  }

  .location-form h2 {
    font-size: 23px;
    line-height: 30px;
  }

  .go-back {
    margin: 0 0 15px;
  }

  .location-form .form-main h3 {
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 10px;
  }

  .location-form .form-main form .input-group .input-field input,
  .location-form .form-main form .input-group textarea {
    height: 45px;
    font-size: 14px;
  }

  .location-form .form-main {
    margin: 0 0 30px;
  }

  .location-form .form-main form .input-group .input-field label span {
    min-height: auto;
    font-size: 12px;
    margin: 0;
  }

  .location-form .form-main form .input-group .input-field label {
    font-size: 16px;
  }

  .location-form .form-main form .input-group textarea {
    height: 70px;
    padding: 10px 13px 0;
    font-size: 14px;
  }

  .location-form .form-main .internet-fee .info-left .fee-select {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .location-form .form-main .internet-fee .info-left .fee-select label {
    width: 100%;
    font-size: 16px;
    justify-content: space-between;
    gap: 5px;
  }

  .location-form .form-main .internet-fee .info-left .fee-select .check-box {
    width: 100%;
    justify-content: left;
  }

  .location-form
    .form-main
    .internet-fee
    .info-left
    .fee-select
    .check-box
    > div {
    justify-content: left;
  }

  .location-form .form-main .internet-fee .info-left p {
    font-size: 12px;
    line-height: 10px;
    margin: 10px 0 0;
  }

  .location-form .form-paragraph {
    margin: 0 0 30px;
  }

  .location-form .form-main form .input-group .input-field label.application {
    margin: 0 0 10px;
  }

  .location-form .form-main form .input-group .input-field .upload button {
    padding: 0 10px;
    height: 45px;
  }

  .location-form .submit-btn p {
    font-size: 13px;
  }
}

.cookie-main {
  width: 340px;
  text-align: center;
  padding: 23px 15px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 22;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.cookie-main > span {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0;
  display: block;
}

.cookie-main ul li {
  display: inline-block;
}

.cookie-main ul li span {
  width: 70px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #828282;
  font-size: 13px;
  font-weight: 500;
  background-color: #fff;
  margin: 0 0 0 10px;
}

.cookie-main ul li span.active {
  background-color: #219653;
  color: #fff;
}

.cookie-main p {
  font-size: 13px;
  line-height: 18px;
  margin: 7px 0 15px;
}

/* new uinterment form */
.logo-for-pdf {
  width: 100%;
  text-align: center;
  padding: 40px;
}

.logo-for-pdf img {
  width: 150px;
}

.location-form-main {
  width: 100%;
  display: inline-block;
}

.new-interment-form-design {
  margin-top: 16px !important;
  position: relative;
}

.new-interment-form-design::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../images/transparentLogo.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1000px;
  height: 1000px;
  z-index: -1;
  opacity: 0.4;
}

.new-location-form {
  width: 100%;
  display: inline-block;
}

.new-location-form h1 {
  font-weight: bold;
  font-size: 34px;
  width: 100%;
  text-align: center;
  margin: 0 0 30px;
}

.new-location-form .form-main {
  width: 100%;
}

.new-location-form .form-main label {
  font-size: 20px;
  color: #000;
  text-align: right;
}

.new-location-form .form-main input {
  height: 45px;
  background-color: #f3f6ff;
  border: 1px solid #dbdbdb;
}

.new-location-form .form-main form {
  width: 100%;
  margin: 0 0 55px;
}

.new-location-form .form-main form h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
}

.new-location-form .form-main form .input-date {
  display: flex;
}

.new-location-form .form-main form .input-date .input-field {
  width: 50%;
  position: relative;
}
.myOrderPlotId {
  width: 93px;
  height: 24px;
  border: 1px solid #219653;
  text-align: center;
  color: #219653;
  font-size: 12px;
  font-weight: 500;
  background: none;
  border-radius: 30px;
  /* margin: 8px 10px 5px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-location-form .form-main form .input-date .input-field input {
  width: 100%;
  font-size: 17px;
  color: #373636;
  /* text-transform: uppercase; */
  font-family: Inter, sans-serif;
}
.date-picker-death {
  position: absolute !important;
  top: 46px !important;
  left: 173px !important;
  z-index: 9 !important;
}
.date-picker-birth {
  position: absolute !important;
  top: 46px !important;
  left: 363px !important;
  z-index: 9 !important;
}

.new-location-form .form-main form .input-date .input-field label {
  width: 50%;
}

.new-location-form .form-main form .input-date .input-field .input-date-picker {
  width: 100%;
}
.new-location-form
  .form-main
  form
  .input-date
  .input-field
  .input-date-picker
  input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.new-location-form
  .form-main
  form
  .input-date
  .input-field.birth
  .input-date-picker {
  width: 47%;
}

.new-location-form .form-main form .input-date .input-field.birth {
  width: 68%;
}

.new-location-form .form-main form .input-date .input-field.birth label {
  width: 52%;
}

.new-location-form .form-main form .input-date .input-field.birth input {
  width: 100%;
}

.new-location-form .form-main form .input-field {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 0 20px;
}

.new-location-form .form-main form .input-field label {
  width: 30%;
  font-size: 18px;
  color: #000;
  text-align: right;
}

.new-location-form .form-main form .input-field input {
  width: 70%;
  height: 45px;
  background-color: #f3f6ff;
  border: 1px solid #dbdbdb;
  font-size: 18px;
  padding: 0 10px;
}
.new-location-form .form-main form .input-field input::placeholder {
  color: red;
  opacity: 1; /* Firefox */
  font-size: 16px;
}

.new-location-form .form-main form .input-field input:focus {
  background-color: #fff;
}

.new-location-form .form-main .fee-main {
  width: 100%;
  display: flex;
  margin: 0 0 55px;
  gap: 98px;
}

.new-location-form .form-main .fee-main .detail {
  width: 50%;
  display: flex;
}

.new-location-form .form-main .fee-main .detail .head {
  width: 70%;
}
.new-location-form .form-main .fee-main .detail .head.first {
  width: 61% !important;
}
.new-location-form .form-main .fee-main .detail .head.pl {
  padding-left: 144px;
  width: 75%;
}

.new-location-form .form-main .fee-main .detail .head h4 {
  display: flex;
  font-size: 18px;
  gap: 20px;
  font-weight: normal;
  position: relative;
}

.new-location-form .form-main .fee-main .detail .head h4 span {
  font-weight: bold;
}

.new-location-form .form-main .fee-main .detail .head p {
  font-size: 18px;
  line-height: 24px;
  color: #4f4f4f;
  margin: 10px 0 0;
}

.new-location-form .form-main .fee-main .detail .checkbox {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
}
.new-location-form .form-main .fee-main .detail .checkbox.second {
  width: 25%;
}

.new-location-form .form-main .fee-main .detail .checkbox .check {
  display: flex;
  width: 100%;
  justify-content: right;
  gap: 15px;
}

.new-location-form .form-main .fee-main .detail .checkbox .check input {
  height: 20px;
  width: 20px;
}

.new-location-form .form-main .paragraph {
  width: 100%;
}

.new-location-form .form-main .paragraph p {
  font-size: 18px !important;
  color: rgb(79, 79, 79);
  line-height: 24px;
  text-align: left;
  font-weight: normal;
}

.new-location-form .form-main .paragraph p span {
  color: #219653;
  font-weight: 500;
  display: inline-block;
}

.new-location-form .form-main .application {
  width: 100%;
  display: flex;
  margin: 50px 0 0;
}

.new-location-form .form-main .application input:focus {
  background-color: #fff;
  padding: 0 10px;
}

.new-location-form .form-main .application .input-group {
  width: 30%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.new-location-form .form-main .application .input-group.first {
  width: 70%;
}

.new-location-form .form-main .application .input-group.first label {
  width: 23%;
}

.new-location-form .form-main .application .input-group.first input {
  width: 70%;
}
.new-location-form
  .form-main
  .application
  .input-group.first
  input::placeholder {
  color: red;
  opacity: 1;
  text-transform: capitalize;
}

.new-location-form .form-main .application .input-group label {
  width: 15%;
  text-align: left;
}

.new-location-form .form-main .application .input-group input {
  width: 85%;
  font-size: 14px;
  color: #656565;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  padding: 0 10px;
}

.new-location-form .form-main .submit-btn {
  width: 100%;
  margin: 50px 0 0;
  text-align: center;
}

.new-location-form .form-main .submit-btn p {
  font-size: 20px;
  font-weight: 500;
  margin: 70px 0 110px;
  text-align: left;
}
.invalid {
  border: 1px solid red !important;
}
.invalidPrice {
  color: red;
  position: absolute;
  left: -15px;
  font-size: 25px;
}

.new-location-form .form-main .submit-btn button {
  width: 200px;
  height: 45px;
  cursor: pointer;
  border: 1px solid #d5d2d2;
  border-radius: 5px;
  background-color: #f3f6ff;
  font-size: 18px;
  font-weight: bold;
  color: #219653;
  position: relative;
  overflow: hidden;
}
.new-location-form .form-main .submit-btn button:hover::after {
  opacity: 1;
  visibility: visible;
  left: 0;
  transition: all 0.5s;
}

.new-location-form .form-main .submit-btn button::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  transition: all 0.5s;
  height: 100%;
  background-color: #21965330;
  color: #fff;
  opacity: 0;
  visibility: hidden;
}

.react-pdf__Page__canvas {
  border: 1px solid;
  width: 70% !important;
  height: 100% !important;
  margin: 0 auto;
}

.back_icon {
  margin-left: 0 !important;
  cursor: pointer;
  width: max-content;
}

.back_icon img {
  width: 13px;
}

.footer {
  width: 100%;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
}
.location-page {
  min-height: 100vh;
}
.blog-page {
  min-height: 100vh;
}
.pdf-back-btn {
  width: 100%;
  max-width: 93%;
  margin: 40px auto 0;
}
.wrap {
  margin: 0 5rem !important;
}

.login-field .input-field label.MuiFormLabel-root {
  line-height: 20px;
}
.phone-input .MuiInputBase-root {
  border-radius: 0 4px 4px 0;
}
.phone-input .MuiInputBase-root input {
  border-left: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.address label.address-heading {
  font-weight: 500;
  font-size: 15px;
  color: #333;
  display: block;
  margin: 0 0 10px;
}
.address .address-type {
  width: auto;
}
.address .address-type ul {
  display: flex;
  margin: 0 0 14px;
}
.address .address-type ul li {
  padding: 10px;
  color: #828282;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border-bottom: 2px solid #e0e0e0;
  cursor: pointer;
}
.address .address-type ul li.active {
  background-color: #e5fff0;
  border-bottom: 2px solid #219653;
  color: #219653;
}
.address .address-type .address-fields {
  width: auto;
  /* display: none; */
}
.address .address-type .address-fields .postcode-bar {
  width: 100%;
  border: 1px solid #333;
  border-top: none;
  height: 200px;
  overflow-y: auto;
  /* display: visible; */
}
.address .address-type .address-fields .postcode-bar ul {
  display: unset;
}
.address .address-type .address-fields .postcode-bar ul li {
  padding: 10px;
  border: none;
}
.address .address-type .address-fields.active {
  display: unset;
}
.address .address-type .address-fields.active .input-code label {
  line-height: 12px;
  overflow: visible;
  font-size: 14px;
}
.address .address-type .address-fields.active .input-code input {
  height: 47px;
  padding: 0 10px;
}
form .event_bullets ul {
  list-style: disc;
  padding: 0 0 0 20px;
}

form .event_bullets ul li {
  list-style: circle;
  font-size: 18px;
  color: rgb(79, 79, 79);
  margin: 0 0 5px;
}

.visited-main {
  width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 76px 32px 37px;
  text-align: center;
  border-radius: 5px;
}
.visited-main button.close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.visited-main .logo {
  position: absolute;
  top: 10px;
  left: 40%;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 12px;
  transform: translateY(-50%);
}

.visited-main h2 {
  font-size: 22px;
  line-height: 18px;
  font-weight: 600;
}
.visited-main p {
  font-size: 16px;
  font-weight: normal;
  margin: 14px 0 32px;
}
.visited-main p .click-link {
  color: #219653;
  font-weight: bold;
  text-decoration: underline !important;
  display: inline-block;
}

.visited-main .input-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 0 0 20px;
}
.visited-main .input-group .input-field {
  width: 50%;
}
.visited-main .input-group .email-field {
  width: 100%;
}
.visited-main .input-group .input-field input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 22px;
  /* color: #BDBDBD; */
  font-size: 14px;
}
.visited-main .input-group .input-field input::placeholder {
  color: #bdbdbd;
}

.visited-main .submit-btn {
  width: 100%;
  height: 50px;
  background-color: #219653;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  border: none;
  cursor: pointer;
}

/* visiter popup css  */

.street-postmain {
  width: 500px;
  background-color: #fff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 40px 40px;
}
.street-postmain .logo {
  margin: 0 0 25px;
  position: absolute;
  top: 0;
  left: 40%;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 50%;
  padding: 12px;
}

.street-postmain .close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

.street-postmain .input-group {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 0 0 14px;
}
.street-postmain .input-group .input-field {
  width: 50%;
}
.street-postmain .input-group .full-width {
  width: 100%;
  position: relative;
}
.street-postmain .input-group .full-width ul.postcode {
  width: 100%;
  position: absolute;
  z-index: 11;
  background-color: #fff;
  border: 1px solid #eaeaea;
  height: 200px;
  overflow-y: auto;
  opacity: 1;
  visibility: visible;
}
.street-postmain .input-group .full-width ul.postcode li {
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}
.street-postmain .input-group .input-field label {
  display: block;
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px !important;
}

.street-postmain .input-group .input-field input {
  width: 100%;
  height: 50px;
  /* border: 1px solid #E0E0E0; */
  padding: 0 22px;
  font-size: 14px;
}

.street-postmain .input-group .input-field input::placeholder {
  color: #bdbdbd;
}
.street-postmain .submit-btn {
  width: 100%;
  height: 50px;
  background-color: #219653;
  color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

/* brochure download pdf button  */

.download_btn {
  background-color: #28944e;
  padding: 12px;
  display: flex;
  justify-content: center;
  border-radius: 29px;
  height: 50px;
  width: 50px;
  /* box-shadow: 0px 3px 11px -1px #28944e; */
  position: fixed;
  bottom: 40px;
  z-index: 999;
  right: 59px;
  cursor: pointer;
}
.download_btn:hover {
  transition: 0.3s;
  box-shadow: 0px 3px 11px -1px #28944e;
}
.Blog-details-head h1 {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 15px;
}

/* LEGACY BOOK CSS */
#legacy-main {
  width: 100%;
  display: inline-block;
  margin: 72px 0 50px;
}

.legacy-container {
  width: 100%;
  border: 1px solid #f2f2f2;
  box-shadow: 0 4px 3px #0000000d;
}

.legacy-container .search-legacy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 50px;
  background-color: #fbfaf8;
}

.legacy-container .search-legacy h3 {
  font-weight: 600;
  font-size: 30px;
  color: #000;
}

.legacy-container .search-legacy .search-field {
  width: 700px;
  position: relative;
}

.legacy-container .search-legacy .search-field input::placeholder {
  color: #bdbdbd;
}

.legacy-container .search-legacy .search-field input {
  width: 100%;
  height: 40px;
  padding: 0 38px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #e0e0e0;
}

.legacy-container .search-legacy .search-field span {
  position: absolute;
  top: 12px;
  left: 15px;
}

.legacy-container .view-legacy {
  width: 100%;
  padding: 25px 50px 50px;
}

.legacy-container .view-legacy h3 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
}

.legacy-container .view-legacy .legacy-list {
  width: 100%;
  margin: 28px 0 0;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}

.legacy-container .view-legacy .legacy-list .box {
  width: 100%;
  display: flex;
  gap: 20px;
  cursor: pointer;
  padding: 25px 20px;
  border: 1px solid #f2f2f2;
}

.legacy-container .view-legacy .legacy-list .box .profile-img {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #80808066;
  font-weight: bold;
}

.legacy-container .view-legacy .legacy-list .box .profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.legacy-container .view-legacy .legacy-list .box .detail {
  width: calc(100% - 70px);
}

.legacy-container .view-legacy .legacy-list .box .detail h4 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.legacy-container .view-legacy .legacy-list .box .detail span {
  color: #828282;
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin: 5px 0;
}

.legacy-container .view-legacy .legacy-list .box .detail p {
  font-weight: normal;
  font-size: 12px;
  color: #828282;
}
.legacy-container .view-legacy .legacy-list .box .detail a.read-more {
  margin: 5px 0 0;
  font-size: 14px;
  color: #219653;
  display: inline-block;
}

/* legacy details css */
#legacy-details-main {
  width: 100%;
  margin: 27px 0 0;
  display: inline-block;
}

#legacy-details-main .go-back {
  width: auto;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

#legacy-details-main h2 {
  font-weight: bold;
  font-size: 34px;
  margin: 0 0 34px;
}

#legacy-details-main .legacy-profile-details {
  width: 100%;
  border: 1px solid #f2f2f2;
  padding: 30px 35px;
  margin: 0 0 20px;
}

#legacy-details-main .legacy-profile-details .data {
  width: 100%;
  display: flex;
  gap: 14px;
  align-items: center;
}

#legacy-details-main .legacy-profile-details .data .pic-data {
  width: 410px;
  padding: 20px;
  border: 1px solid #f2f2f2;
  margin: 0 0 20px;
}

#legacy-details-main .legacy-profile-details .data p {
  width: calc(100% - 410px);
  font-size: 16px;
  color: #4f4f4f;
  line-height: 24px;
}

#legacy-details-main .legacy-profile-details .data .pic-data .profile-name {
  display: flex;
  width: 100%;
  gap: 19px;
}

#legacy-details-main
  .legacy-profile-details
  .data
  .pic-data
  .profile-name
  .pic {
  width: 123px;
  height: 123px;
}

#legacy-details-main
  .legacy-profile-details
  .data
  .pic-data
  .profile-name
  .pic
  img {
  width: 100%;
  height: 100%;
}

#legacy-details-main
  .legacy-profile-details
  .data
  .pic-data
  .profile-name
  .name {
  width: auto;
}

#legacy-details-main
  .legacy-profile-details
  .data
  .pic-data
  .profile-name
  .name
  h3 {
  font-weight: 600;
  font-size: 22px;
  color: #333;
  margin: 0 0 5px;
}

#legacy-details-main
  .legacy-profile-details
  .data
  .pic-data
  .profile-name
  .name
  span {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
}

#legacy-details-main .legacy-profile-details .data .pic-data ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 20px 0 0;
}

#legacy-details-main .legacy-profile-details .data .pic-data ul li {
  width: 55px;
  height: 55px;
}

#legacy-details-main .legacy-profile-details .data .pic-data ul li img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

#legacy-details-main .legacy-profile-details p {
  font-size: 16px;
  color: #4f4f4f;
  margin: 0 0 25px;
  line-height: 24px;
}

.comments-main {
  width: 100%;
  border: 1px solid #f2f2f2;
  box-shadow: 0 4px 3px #0000000d;
  margin: 0 0 50px;
}

.comments-main .head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #fbfaf8;
}

.comments-main .head h3 {
  font-weight: 600;
  font-size: 30px;
  color: #000;
  line-height: 36px;
}

.comments-main .head button {
  width: 133px;
  height: 40px;
  background-color: #219653;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border: none;
}

.comments-main .comments {
  width: 100%;
  padding: 30px 50px;
}

.comments-main .comments .comment-box {
  width: 100%;
  display: flex;
  margin: 0 0 18px;
  gap: 32px;
  padding: 20px 25px;
  background-color: #fbfaf8;
}

.comments-main .comments .comment-box .comment-profile {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  font-weight: bold;
  border-radius: 50%;
  color: #fff;
}

.comments-main .comments .comment-box .comment-profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.comments-main .comments .comment-box .comment-user {
  width: calc(100% - 60px);
  position: relative;
}

.comments-main .comments .comment-box .comment-user span {
  font-size: 18px;
  line-height: 30px;
  display: block;
  color: #000000cc;
}

.comments-main .comments .comment-box .comment-user p {
  font-size: 18px;
  line-height: 30px;
  color: #000000cc;
  margin: 13px 0 0;
}

.comments-main .comments .comment-type {
  background-color: transparent;
}

.comments-main .comments .comment-type .comment-user textarea::placeholder {
  color: #000000cc;
}

.comments-main .comments .comment-type .comment-user textarea {
  width: 100%;
  height: 155px;
  border: 1px solid #cfcfcf;
  padding: 22px;
  font-size: 18px;
  color: #000000cc;
  resize: none;
}

.comments-main .comments .comment-type .comment-user .submit-btn {
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: #219653;
  position: absolute;
  bottom: 5px;
  right: 21px;
  cursor: pointer;
  padding: 8px;
  background-color: #fff;
  border: 2px solid #219653;
  text-align: right;
  border-radius: 5px;
}

.account-main .profile-details-main .profile-details .profile-info .gallery {
  width: 100%;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .gallery
  label {
  font-weight: 600;
  font-size: 14px;
  color: #333;
  display: block;
  margin: 0 0 10px;
}

.account-main .profile-details-main .profile-details .profile-info .gallery ul {
  padding: 0;
  display: flex;
  gap: 10px;
  border: none;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .gallery
  ul
  li {
  width: 100px;
  height: 100px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9ffe9;
  position: relative;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .gallery
  ul
  li
  img {
  width: 100%;
  height: 100%;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .gallery
  ul
  li.upload-img
  input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .gallery
  ul
  li.upload-img
  img {
  width: 30px;
  height: 30px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .gallery
  ul
  li
  button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #dbdbdb;
  border: none;
  cursor: pointer;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field {
  width: 100%;
  display: flex;
  gap: 20px;
  margin: 20px 0 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field
  .input-field {
  width: 33%;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field
  .input-field
  label {
  color: #333;
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin: 0 0 5px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field
  .input-field
  input::placeholder {
  color: #828282;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field
  .input-field
  input,
.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field
  .input-field.w-full
  textarea {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 20px;
  font-size: 14px;
  color: #333;
  resize: none;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field
  .input-field.w-full {
  width: 100%;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field
  .input-field.w-full
  textarea {
  height: 350px;
  padding: 20px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-field
  .input-field.w-full
  textarea::placeholder {
  color: #333;
}

.account-main .profile-details-main .profile-details .profile-info .legacy-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 65px 0 0;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-btn
  button {
  width: 94px;
  height: 50px;
  background-color: #f2f2f2;
  border: none;
  color: #828282;
  font-weight: 600;
  font-size: 16px;
}

.account-main
  .profile-details-main
  .profile-details
  .profile-info
  .legacy-btn
  button.save-btn {
  background-color: #219653;
  position: relative;
  color: #fff;
  cursor: pointer;
  width: 150px;
}

.profile-details-main .legacy-info .profile-info {
  margin: 0 0 48px;
}

.profile-details-main .legacy-info {
  padding: 0 50px;
}

.upload_loader-two {
  border: 3px solid #ffffff;
  border-top-color: #219653;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

/* new upadted css 24/12/24 */
.bg-light {
  background-color: #fffef5 !important;
}

#burials-process-main {
  width: 100%;
  display: inline-block;
  padding: 60px 0 0;
}

.burials-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.burials-head {
  width: 100%;
  text-align: center;
  margin: 0 0 58px;
}
.burials-head h2 {
  font-weight: bold;
  font-size: 34px;
}

.burials-process-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.burials-process {
  width: 100%;
  display: inline-block;
  background-color: #f3fff8;
}
.burials-process img {
  width: 100%;
  height: 391px;
  object-fit: cover;
}
.burials-process .burials-details {
  width: 100%;
  text-align: center;
  padding: 24px 0;
}

.burials-process .burials-details h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 20px;
}
.home-plans-main .burials-process .burials-details h3 {
  min-height: 72px;
}

.burials-process .burials-details a {
  width: 194px;
  height: 46px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #219653;
  font-weight: bold;
  font-size: 16px;
  color: #219653;
  margin: 0 auto;
}
.burials-process .burials-details a:hover {
  background-color: #219653;
  color: #fff;
}

/* new faq */
.faq-new-main {
  margin: 0 0 100px !important;
}

.faq-new-main .faq h2 {
  font-size: 34px;
}
.faq-new-main .faq ul li {
  padding: 30px 25px;
  cursor: pointer;
}
.faq-new-main .faq ul li h2 {
  font-size: 20px;
}
.faq-new-main .faq ul li .faq-content {
  margin: 27px 0 0;
}
.faq ul li .faq-content p {
  width: 100%;
  padding: 0;
  font-size: 16px;
}
.faq-new-main .faq ul li.faq-active {
  background-color: #fbfaf8;
}

.custom-banner-text p {
  color: #fff;
  margin: 10px 0 0;
}

.burials-new-main .burials-half {
  margin: 0 0 25px;
  height: 423px;
}

.burials-new-main .burials {
  margin: 0;
  height: 380px;
}

.natural-key-newmain {
  margin: 0 !important;
}

#new-cta-main {
  width: 100%;
  display: inline-block;
  margin: 0 0 140px;
}

.new-cta {
  width: 100%;
  display: inline-block;
  position: relative;
}
.new-cta img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.new-cta .new-cta-details {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 78px;
  max-width: 683px;
  color: #fff;
}

.new-cta .new-cta-details h3 {
  font-weight: bold;
  font-size: 30px;
  margin: 0 0 18px;
}
.new-cta .new-cta-details h2 {
  font-weight: bold;
  font-size: 30px;
  margin: 0 0 18px;
}
.new-cta .new-cta-details p {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  margin: 0 0 20px;
}

#why-green-burials {
  width: 100%;
  display: inline-block;
  background-color: #f3fff8;
  padding: 70px 0;
  margin: 0 0 60px;
}

.why-green-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}
.why-green-head {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 0 50px;
}
.why-green-head h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 0 0 28px;
}
.why-green-head p {
  color: #4f4f4f;
}
.why-green-cols {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.why-green {
  width: 100%;
  padding: 44px 44px 24px;
  background-color: #fff;
  margin: 0 0 20px;
  border: 1px solid #f2f2f2;
  box-shadow: 0 4px 4px #00000005;
}
.why-green h4 {
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 15px;
}
.why-green  h3 {
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 15px;
}

.why-green p {
  line-height: 24px;
}

#about-family-main {
  width: 100%;
  display: inline-block;
  margin: 85px 0 0;
}

.family-burial-img-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}
.family-burial-img {
  width: 100%;
}
.family-burial-img img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.family-detail-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.family-detail {
  width: 100%;
}
.family-detail h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 20px 0 34px;
}
.family-detail p {
  color: #4f4f4f;
  line-height: 24px;
  margin: 0 0 20px;
}
.family-burials-main .burials {
  height: 380px;
}

#types-family-main {
  width: 100%;
  display: inline-block;
  background-color: #fffef5;
  padding: 70px 0;
}

.type-family-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}
.type-family-head {
  width: 100%;
  text-align: center;
  margin: 0 0 40px;
}
.type-family-head h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 0 0 30px;
}
.type-family-head p {
  color: #4f4f4f;
}

.family-types-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}
.family-types {
  width: 100%;
}

.family-types .family-types-box {
  display: flex;
  width: 100%;
  margin: 0 0 30px;
}
.family-types .family-types-box.third .types-details {
  padding: 65px;
}
.family-types .family-types-box .types-details {
  width: calc(100% - 435px);
  background-color: #fff;
  padding: 90px;
}
.family-types .family-types-box .types-details h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 12px;
}
.family-types .family-types-box .types-details p {
  color: #4f4f4f;
  line-height: 24px;
}
.family-types .family-types-box .types-details p span {
  color: #219653;
  display: inline;
}
.family-types .family-types-box .family-types-img {
  width: 435px;
  height: 324px;
}
.family-types .family-types-box .family-types-img img {
  height: 100%;
  vertical-align: top;
  width: 100%;
  object-fit: cover;
}

#family-why-choose-main {
  width: 100%;
  display: inline-block;
  margin: 70px 0 100px;
}

.family-why-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.family-why-head {
  width: 100%;
  text-align: center;
  margin: 0 0 50px;
}
.family-why-head h2 {
  font-weight: bold;
  font-size: 40px;
}

.family-full-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.family-full {
  width: 100%;
  border: 1px solid #219653;
  padding: 44px 90px;
  margin: 0 0 20px;
  box-shadow: 0 4px 4px #00000005;
}
.family-full h3 {
  font-weight: bold;
  font-size: 20px;
  color: #219653;
  margin: 0 0 15px;
}
.family-full p {
  line-height: 24px;
}

.family-half-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.family-half {
  width: 100%;
  border: 1px solid #219653;
  height: 269px;
  padding: 55px 44px 30px;
  box-shadow: 0 4px 4px #00000005;
}
.family-half h3 {
  color: #219653;
  margin: 0 0 10px;
}
.family-half p {
  line-height: 24px;
}

.memorial-cta-new {
  margin: 80px 0 109px !important;
}

.about-memorial .family-detail h2 {
  margin: 0 0 34px !important;
}

.memorial-option-main {
  background-color: #f4ffff;
  padding: 45px 0;
  vertical-align: top;
}

.memorial-option-main .family-detail h2 {
  margin: 0 0 15px;
}
.memorial-option-main .family-burial-img img {
  height: 472px;
  object-fit: cover;
}

#memorial-tribute-service {
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 90px 0;
}
#memorial-tribute-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/tribute_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.tribute-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.tribute-head {
  width: 100%;
  text-align: center;
}

.tribute-head h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 0 0 40px;
}

.tribute-head img {
  width: 100%;
  height: 210px;
  object-fit: cover;
  margin: 0 0 28px;
}
.tribute-head p {
  line-height: 24px;
  color: #4f4f4f;
  font-size: 18px;
  margin: 0 0 15px;
}

.what-we-do .family-burial-img img {
  height: 320px;
}
.family-detail h2 {
  margin: 58px 0 34px;
}

.home-natural-burial .tribute-head {
  text-align: left;
}
.home-natural-burial .tribute-head a {
  width: 226px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  background-color: #219653;
  color: #fff;
  font-weight: bold;
  margin: 0 0 0;
}
.home-natural-burial .tribute-head a img {
  width: 24px;
  height: 12px;
  margin: 0;
}
.home-natural-burial .tribute-head img {
  height: 287px;
}

.home-natural-burial::before {
  background-image: url("../../assets/images/home_natural.png") !important;
}

.what-we-do {
  margin: 55px 0 !important;
}

.home-types-main {
  background-color: transparent !important;
  position: relative;
}

.home-types-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/home_blue_bg.png") !important;
  z-index: -1;
}

.home-types-main .family-types .family-types-box .home-second-types {
  padding: 65px;
}

.home-types-main .family-types .family-types-box .home-third-types {
  padding: 54px;
}

.home-types-main .family-types .family-types-box .home-four-types {
  padding: 40px;
}

.home-types-main .family-types .family-types-box .home-five-types {
  padding: 78px;
}

.home-about-naturals .family-detail a {
  width: 194px;
  height: 45px;
  background-color: #219653;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
}

.home-about-naturals .family-detail h2 {
  margin: 30px 0 20px;
  padding-right: 20px;
}
.home-about-naturals .family-detail p {
  margin: 0 0 25px;
}
.home-plans-main {
  vertical-align: top;
  position: relative;
  overflow: hidden;
}

.home-plans-main::before {
  content: "";
  position: absolute;
  bottom: -235px;
  right: 0;
  background-image: url("../../assets/images/bg-leaf.png");
  background-repeat: no-repeat;
  width: 570px;
  height: 775px;
  transform: rotate(17deg);
  z-index: -1;
}

.home-plans-main::after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  background-image: url("../../assets/images/leaf_bg.png");
  background-repeat: no-repeat;
  width: 79px;
  height: 100px;
  transform: rotate(17deg);
  z-index: -1;
  opacity: 15%;
}

.home-plans-main .burials-process img {
  height: 158px;
}
.home-plans-main .burials-process .burials-details {
  padding: 24px;
  text-align: left;
}
.home-plans-main .burials-process .burials-details p {
  line-height: 24px;
  color: #4f4f4f;
}
.home-plans-main .burials-process {
  height: 565px;
}

.home-plans-main .burials-process.third {
  background-color: #d6eded;
}

.phome-payment-plan {
  position: relative;
  margin: 50px 0 0 !important;
  padding: 85px 0;
}

.phome-payment-plan::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/home_natural.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.home-place-main {
  background-color: #e9fdf2;
  padding: 60px 0;
  margin: 0 !important;
  vertical-align: top;
}

.home-place-main .family-burial-img img {
  height: 375px;
}

.home-place-main .family-detail h2 {
  margin: 85px 0 13px;
}

.kendal-perfect-main .family-detail h2 {
  margin: 0 0 20px;
}

.home-banner {
  max-width: 1090px;
}
.home-banner p {
  color: #fff;
  margin: 25px 0 0;
  font-size: 20px;
  line-height: 24px;
}

.family-contact-main {
  margin: 0 0 100px !important;
}

.family-img-col {
  width: 50%;
  float: left;
  padding: 0 15px;
}
.family-contact-img img {
  width: 100%;
  height: 266px;
  object-fit: cover;
}

.family-contact-main .contact-social ul li .social-left p {
  max-width: 177px !important;
}

.cta_new {
  margin: 80px 0 0;
}

.Cta_new_main .talk-adviser .adviser-head h3 {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  margin: 0;
  color: #fff !important;
}
.Cta_new_main .talk-adviser {
  padding: 24px 62px;
  margin: 0;
}
.Cta_new_main .talk-adviser .adviser-contact a {
  width: 240px;
}

.product-cta .talk-adviser {
  background: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.product-cta .talk-adviser .adviser-contact {
  width: 100%;
  background-color: #219653;
  padding: 20px;
  text-align: left;
  height: 100%;
}
.product-cta .talk-adviser .adviser-contact h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  margin: 0 0 16px;
}
.product-cta .talk-adviser .adviser-contact a {
  width: fit-content;
  padding: 10px;
  margin: 10px 0 0;
}
.product-cta .talk-adviser .adviser-head {
  padding: 0 0 0 15px;
  width: 100%;
  background-color: #219653;
  padding: 20px;
  height: 100%;
}
.product-cta .talk-adviser .msg-img {
  display: none;
}

.product-cta .talk-adviser .adviser-head h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
}
.product-cta .talk-adviser .adviser-head a {
  width: 250px;
  height: 46px;
  border: 1px solid #fff;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  margin: 20px 0 0;
}

.product-cta {
  display: none !important;
}

.cta_two {
  margin: 0 0 50px;
}
.cta_two .harmony-cta-main .cta {
  padding: 35px 25px;
  align-items: center;
}

.cta_two .harmony-cta-main .cta a {
  width: 260px;
}
.cta_two .harmony-cta-main .cta .left span.right-span {
  font-size: 18px;
  font-weight: 500;
}
.cta_two .harmony-cta-main .cta a {
  font-size: 14px;
  font-weight: 500;
}

.Cta_new_main .talk-adviser .msg-img img {
  width: 44px;
}
.Cta_new_main .talk-adviser .adviser-head {
  padding: 0;
}
.cta_location {
  margin: 21px 0 0;
}

.empty-legacy {
  text-align: center;
  padding: 30px;
}

.empty-legacy img {
  margin: 0 auto;
}
.empty-legacy p {
  margin: 30px 0 0;
  font-size: 18px;
  font-weight: 600;
  color: grey;
}
.account-main .profile-details-main .legacy-info .profile-info h3 {
  margin: 0 0 20px;
}

/* 12/02/2025 new css  */
.home-btns {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 32px 0 0;
}

.schedule-btn {
  width: 194px;
  height: 45px;
  background-color: #219653;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
}

.home-types-main .family-types .family-types-box .home-third-types a {
  margin: 15px 0 0;
}
.home-types-main .family-types .family-types-box .home-third-types {
  padding: 57px;
}

.home-about-naturals .family-detail .speak-advisor-btn {
  width: 226px;
}
.speak-advisor-btn {
  width: 226px !important;
}

.home-plans-main .burials-process .burials-details a {
  margin: 15px 0 0;
  background-color: #219653;
  color: #fff;
}
.home-plans-main .burials-process .burials-details a img {
  width: 24px;
  height: 25px;
}

.home-types-main .family-types .family-types-box .home-four-types {
  padding: 78px;
}

.home-place-main {
  margin: 80px 0 0 !important;
}
.home-about-naturals {
  margin: 85px 0  !important;
}

.kendal-perfect-main  .family-burial-img img {
  height: 475px;
} 

.kendal-perfect-main {
  margin: 85px 0 0 !important;
}

.custom-banner-text a {
  margin: 20px 0 0;
}

.green-eco-burials .family-detail {
  padding-top: 45px;
}
.green-eco-burials .family-burial-img img {
  height: 400px;
}

.burials-new-main .burials a {
  margin: 15px 0 0;
}
.burials-full-col {
  width: 100%;
}
.burials-new-main .burials-half {
  height: 310px;
}
.burials-new-main .burials-full-col .burials {
  height: 270px;
  margin: 25px 0 0;
}

#natural-key-main a {
  margin: 15px 0 0;
}
.why-green-cols {
  width: 33.33%;
}
.why-green {
  padding: 30px;
  height: 265px;
}
.why-green-head h2 {
  margin: 0;
}
.meadows-green-burials-btn {
  width: 100%;
  text-align: center;
}
.meadows-green-burials-btn a {
  margin: 0 auto;
}

.new-cta .new-cta-details a img {
  width: 24px;
  height: 25px;
}

.green-cta {
  margin: 0 0 100px !important;
}

.family-burials-plot  .family-detail h2{
  margin: 28px 0 34px;
}

.family-burials-main .burials {
  height: 410px;
}

.family-burials-benefits {
  padding: 0 15px;
}

.family-half {
  margin: 0 0 25px;
  padding: 30px;
}

#family-why-choose-main {
  margin:70px 0;
}

.new-family-half {
  height: 200px;
}

/* .about-memorial-garden {
  margin: 68px 0 0 !important;
} */

.about-memorial-garden  .burials-half, .about-memorial-garden  .burials {
  height: 435px;
}
.memorial-garden-btn {
  width: 100%;
  padding: 0 15px;
  margin: 20px 0 0;
  display: inline-block;
}


.new-type-family .family-types .family-types-box .types-details p{
  margin: 0 0 10px;
}

.new-type-family .family-types .family-types-box .types-details.first {
  padding: 68px;
}

.memorial-new-custom .new-cta img {
  height: 330px;
}

.home-banner .home-btns a {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.home-plans-main-col {
  padding: 0 15px;
  width: 100%;
}
.home-plans--schedule-btn {
  width: 100%;
  display: inline-block;
  margin: 20px 0 0;
}
.family-types .family-types-box.third .types-details a {
  margin: 15px 0 0;
}
.family-types .family-types-box.third .types-details {
  padding: 60px;
}
/* .home-plans-main-col a {
  margin: 20px 0 0;
} */

.bannerContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 30%);
  width:100%;
  height:100%;
}
.family-detail p .links {
  color: #219653;
  text-decoration: underline;
}

.why-choose-new p .links{
  width: auto;
  display: inline-block;
  height: auto;
  background-color: transparent;
  font-weight: normal;
}

.kendal-link p {
  font-size: 18px;
  font-weight: 500;
}
.kendal-link p a {
  text-decoration: underline;
  color: #219653;
}

/* new css credit limit check section */
.credit-section {
  width: 100%;
  display: inline-block;
  background-color: #13573008;
  padding: 80px 0;
  margin: 80px 0 0;
}

.credit-img-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}
.credit-img {
  width: 100%;
}
.credit-img img {
  width: 100%;
  height: 490px;
  object-fit: cover;
}

.credit-content-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.credit-content {
  width: 100%;
  height: 490px;
  background-color: #fff;
  text-align: center;
  padding: 90px 40px 0;
}
.credit-content h2 {
  font-size: 40px;
  color: #1D1D1D;
  line-height: 48px;
}
.credit-content p {
  font-size: 16px;
  letter-spacing: -0.011em;
  margin: 15px 0;
  line-height: 150%;
}

.credit-content a{
  padding: 14px 32px;
  color: #fff;
  background-color: #219653;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin-top: 12px;
  display: inline-block;
  transition: all 0.5s;
}

.credit-content a:hover {
  opacity: 0.9;
  transition: all 0.5s;
}


